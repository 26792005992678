import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect } from "react";
import { useAppSelector } from "../../Redux/Hooks";

type ModalProps = {
  show: boolean;
  setShow: Function;
  children?: React.ReactNode;
  bodyClass?: string;
  title?: string;
  buttons?: React.ReactNode;
  customer?: boolean;
  backButton?: boolean;
  onBackButtonClick?: Function;
  noClickAway?: boolean;
  overflow?: boolean;
};
const Modal = (props: ModalProps) => {
  const address = useAppSelector((state) => state.address);
  const user = useAppSelector((state) => state.user);
  useEffect(() => {}, [JSON.stringify(address), JSON.stringify(user)]);
  if (!props.show) return null;
  return ReactDOM.createPortal(
    <div
      className={
        props.customer
          ? `modal__container modal__container--customer ${
              props.overflow && "overflow"
            }`
          : `modal__container ${props.overflow && "overflow"}`
      }
      onClick={(e) => {
        e.stopPropagation();
        if (!props.noClickAway) props.setShow(false);
      }}
    >
      <div
        className={props.customer ? `modal modal__customer` : `modal`}
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
      >
        {props.title && (
          <div className="modal__top">
            <div className="modal__top--left">
              {props.backButton && (
                <div
                  className="modal__back"
                  onClick={() => {
                    if (props.onBackButtonClick) props.onBackButtonClick();
                  }}
                >
                  <FontAwesomeIcon
                    icon={icon({ name: "arrow-left", style: "solid" })}
                  />
                </div>
              )}
              <div className="modal__title">{props.title}</div>
            </div>
            <div
              className="modal__x"
              onClick={() => {
                props.setShow(false);
              }}
            >
              <FontAwesomeIcon icon={icon({ name: "x", style: "solid" })} />
            </div>
          </div>
        )}
        <div
          className={
            props.bodyClass
              ? props.bodyClass + " " + "modal__body"
              : "modal__body"
          }
        >
          {props.children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
