import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  findOffersByLocation,
  findOffersWithFilter,
  getOfferersActiveOffers,
  getOffersByOfferer,
} from "../../API/offersApi";
import FilterContainer from "../../Components/Filters/FilterContainer";
import useElementInView from "../../CustomHooks/useElementInView";
import {
  numberToMonth,
  offerMonthFilteredList,
} from "../../Helpers/dateHelpers";
import {
  offerPosFilter,
  offerPositionFilteredList,
} from "../../Helpers/positionHelpers";
import { OfferInterface } from "../../Interfaces/OfferInterface";
import OfferCompCust from "./OfferCompCust";
import useDebounce from "../../CustomHooks/useDebounce";

const loadNum = 100;

interface OfferListProps {
  search: string;
  latLng?: { lat: number; lng: number };
  distance?: number;
  searchString?: string;
}

const OfferList = (props: OfferListProps) => {
  const [offers, setOffers] = useState<OfferInterface[]>([]);
  const { latLng, distance } = props;
  const lat = latLng?.lat;
  const lng = latLng?.lng;
  const [loadedOffers, setLoadedOffers] = useState<OfferInterface[]>([]);
  const [userName, setUserName] = useState("");
  const [positionFilter, setPositionFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState(-1);
  const [noMoreOffers, setNoMoreOffers] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [firstCall, setFirstCall] = useState(true);
  const [needLocation, setNeedLocation] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const ref = useRef<HTMLDivElement>(null);

  const params = useParams();

  const loadMore = useElementInView(ref);

  const val = useDebounce(props.searchString || "", 1000);

  useEffect(() => {
    setSearchVal(val);
    searchWithString(val, true);
  }, [val]);

  useEffect(() => {
    if (loadMore) {
      if (props.search === "OffersByOffererIdPage") OffersByOffererSlug();
      if (props.search === "searchoffers") searchOffers();
      if (props.search === "myoffers") myOffers();
      if (props.search === "searchoffersstring") searchWithString(val);
    }
  }, [loadMore]);

  useEffect(() => {
    if (props.search === "myoffers") myOffers();
  }, []);

  // Load offers
  useEffect(() => {
    // Don't delay first call
    if (firstCall) {
      setFirstCall(false);
      if (props.search === "OffersByOffererIdPage") OffersByOffererSlug();
      if (props.search === "searchoffers") searchOffers(true);
      if (props.search === "myoffers") myOffers();
      return;
    }
    // Debounce distance
    const delayDebounceFn = setTimeout(() => {
      setOffers([]);
      loadOffers([]);
      if (props.search === "OffersByOffererIdPage") OffersByOffererSlug();
      if (props.search === "searchoffers") searchOffers(true);
      if (props.search === "myoffers") myOffers();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [lat, lng, distance]);

  // Load offers
  useEffect(() => {
    if (props.search === "OffersByOffererIdPage") OffersByOffererSlug();
  }, [params.id]);

  // Create loader for large lists
  // This speeds up initial render when rendering large lists
  // useEffect(() => {
  //   if (scrollY > loadAfter) {
  //     setLoadAfter(Infinity);
  //   }
  // }, [windowBottom]);

  // useEffect(() => {
  //   if (ref.current) {
  //     setLoadAfter(ref.current?.offsetTop / 2);
  //     // + ref.current?.offsetHeight / 4);
  //   }
  // }, [ref.current?.offsetTop]);

  useEffect(() => {
    doFiltering();
  }, [positionFilter, monthFilter]);

  // loads orders as the user scrolls
  const loader = (loadAmount: number) => {
    // For now we are loading all offers.
    // Implementing Pagnation in phase 2.
    // if (loadedOffers.length + loadAmount > offers.length) {
    //   if (loadedOffers.length >= offers.length) return;
    //   else {
    //     let update: OfferInterface[] = [...loadedOffers];
    //     for (let i = loadedOffers.length; i < offers.length; i++) {
    //       update.push(offers[i]);
    //     }
    //     setLoadedOffers([...update]);
    //   }
    // } else {
    //   let update: OfferInterface[] = [...loadedOffers];
    //   for (
    //     let i = loadedOffers.length;
    //     i < loadedOffers.length + loadAmount;
    //     i++
    //   ) {
    //     update.push(offers[i]);
    //   }
    //   setLoadedOffers([...update]);
    // }
  };

  const findPage = (paginationAmount: number) => {
    const currentOffersAmount = offers.length;
    if (currentOffersAmount === 0) return 1;
    if (currentOffersAmount % paginationAmount) {
      return -1;
    }
    const pageNum = currentOffersAmount / paginationAmount;
    return pageNum + 1;
  };

  const OffersByOffererSlug = async () => {
    // If no slug, don't make call
    if (!params.slug) return;
    // Find page for pagination
    const pageNum = findPage(loadNum);
    if (pageNum === -1) {
      setNoMoreOffers(true);
      return;
    }
    // Get list with page
    const list = await getOffersByOfferer({
      slug: params.slug.toLowerCase(),
      pageNo: pageNum,
      loadAmount: loadNum,
      startedOffers: false,
    });
    // list.offersPast should be a secondary query that brings back
    // offers with start dates before today, to be added to the end of the list
    // with displayAfterStartDate === true
    // Maybe a secondary list displays these at the same pagination fequency
    if (list.offers) {
      const updatedList = [...offers, ...list.offers];
      setOffers(updatedList);
      setUserName(list.user);
      loadOffers(updatedList);
      if (list.offers.length < loadNum) setNoMoreOffers(true);
    } else {
      setNoMoreOffers(true);
    }
  };

  const myOffers = async () => {
    setOffers([]);
    const list = await getOfferersActiveOffers();
    if (list?.offers?.length > 0) {
      setOffers(list.offers);
      loadOffers(list.offers);
    } else {
      setOffers([]);
      loadOffers([]);
      setNoMoreOffers(true);
    }
  };

  const searchOffers = async (reset = false) => {
    const searchLoadNum = 50;
    if (!lng || !lat) {
      setNeedLocation(true);
      return;
    }
    // Find page for pagination
    let pageNum = findPage(searchLoadNum);
    if (reset) pageNum = 1;
    if (pageNum === -1) {
      setNoMoreOffers(true);
      return;
    }
    const list = await findOffersByLocation({
      lng: lng.toString(),
      lat: lat.toString(),
      distance: parseInt((props.distance! * 1609.34).toFixed(0)),
      offset: (pageNum - 1) * searchLoadNum,
      limit: searchLoadNum,
    });

    if (list?.offers) {
      let filterList = [...list.offers];
      filterList = filterList.filter((el) => {
        if (el.Distance < props.distance!) return el;
      });
      if (reset) {
        setOffers([...filterList]);
        loadOffers([...filterList]);
      } else {
        setOffers([...offers, ...filterList]);
        loadOffers([...offers, ...filterList]);
      }
      // Check if the filter found offers that are too far away
      if (
        list.offers.length > filterList.length ||
        list.offers.length < searchLoadNum
      ) {
        setNoMoreOffers(true);
      }
    } else setNoMoreOffers(true);
  };

  const searchWithString = async (curVal: string, reset = false) => {
    if (!curVal) return;
    const searchLoadNum = 50;
    // Find page for pagination
    let pageNum = findPage(searchLoadNum);
    if (reset) pageNum = 1;
    if (pageNum === -1) {
      setNoMoreOffers(true);
      return;
    }

    const list = await findOffersWithFilter({
      search: curVal,
      loadAmount: searchLoadNum,
      pageNo: pageNum,
    });

    if (list?.offers) {
      let filterList = [...list.offers];
      filterList = filterList.filter((el) => {
        if (el.Distance < props.distance!) return el;
      });
      if (reset) {
        setOffers([...list.offers]);
        loadOffers([...list.offers]);
      } else {
        setOffers([...offers, ...list.offers]);
        loadOffers([...offers, ...list.offers]);
      }
      // Check if the filter found offers that are too far away
      if (
        list.offers.length > filterList.length ||
        list.offers.length < searchLoadNum
      ) {
        setNoMoreOffers(true);
      }
    } else setNoMoreOffers(true);
  };

  // call for offers
  const loadOffers = async (lOffers: OfferInterface[]) => {
    // Loading all offers for now
    setLoadedOffers(lOffers);
    // set loaded offers
    // if (lOffers?.length) {
    //   if (loadNum > lOffers.length) {
    //     setLoadedOffers(lOffers);
    //   } else {
    //     let update: OfferInterface[] = [];
    //     for (let i = 0; i < loadNum; i++) {
    //       update.push(lOffers[i]);
    //     }
    //     setLoadedOffers([...update]);
    //   }
    // }
  };
  const offerPositionFilter = () => {
    const filteredList = offerPosFilter(offers);
    if (filteredList.length > 0) {
      const empty = (
        <option value={""} key={-1}>
          None selected
        </option>
      );
      return (
        <select
          onChange={(e) => setPositionFilter(e.target.value)}
          value={positionFilter}
        >
          {[empty, ...filteredList]}
        </select>
      );
    } else return [];
  };

  const doFiltering = () => {
    const offs = [...offers];
    let posFiltered = offerPositionListFiltered(offs);
    const offFiltered = offerMonthListFiltered(posFiltered);
    setLoadedOffers(offFiltered);
  };

  const offerPositionListFiltered = (offs: OfferInterface[]) => {
    if (!positionFilter) {
      return offs;
    } else {
      const offerReturn: OfferInterface[] = offerPositionFilteredList(
        offs,
        positionFilter
      );
      return offerReturn;
    }
  };

  const offerMonthFilter = () => {
    const empty = (
      <option value={""} key={-1}>
        None selected
      </option>
    );

    let monthLister = [];
    for (let i = 1; i <= 12; i++) {
      monthLister.push(
        <option value={i} key={i}>
          {numberToMonth(i)}
        </option>
      );
    }

    return (
      <select
        onChange={(e) => setMonthFilter(parseInt(e.target.value))}
        value={monthFilter}
      >
        {[empty, ...monthLister]}
      </select>
    );
  };

  const offerMonthListFiltered = (offs: OfferInterface[]) => {
    if (!monthFilter || monthFilter === -1) {
      return offs;
    } else {
      const offerReturn: OfferInterface[] = offerMonthFilteredList(
        offs,
        monthFilter
      );
      return offerReturn;
    }
  };

  const offerList = loadedOffers.map((el, i) => (
    <div
      className={i !== 0 && i === 1 ? "offer-list" : i !== 0 ? "mt-3" : ""}
      key={el.ID}
    >
      <OfferCompCust
        listObj={el}
        distance={loadedOffers[i].Distance}
        owner={loadedOffers[i].User}
      />
    </div>
  ));
  return (
    <div>
      <div>
        {props.search === "searchoffers" ? null : props.search ===
          "searchoffersstring" ? null : props.search ===
          "OffersByOffererIdPage" ? (
          <>
            <div className="title">{userName}</div>
            <div className="mb-3" />
            <div
              className="link-text text-center"
              onClick={() => setFilterVisible(!filterVisible)}
            >
              {!filterVisible ? "Show filters" : "Hide Filters"}
            </div>
            {filterVisible && (
              <FilterContainer>
                <div className="forms__field forms__flex forms__flex--column">
                  <label>Search By Position</label>
                  {offerPositionFilter()}
                </div>
                <div className="forms__field forms__flex forms__flex--column">
                  <label>Search By Month</label>
                  {offerMonthFilter()}
                </div>
              </FilterContainer>
            )}
          </>
        ) : (
          <div className="title">Your current active offers</div>
        )}
      </div>
      {offerList.length > 0 ? (
        <>
          <div className="offer-list">{offerList}</div>
          <div ref={ref} className="offer-list__arrow">
            {noMoreOffers ? (
              "End of offers"
            ) : (
              <FontAwesomeIcon
                icon={icon({ name: "arrow-down", style: "solid" })}
              />
            )}
          </div>
        </>
      ) : (
        <div className="title color-highlight">No offers found</div>
      )}
    </div>
  );
};

export default OfferList;
