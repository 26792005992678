export const positionsArrayMaker = (arr) => arr.map((el) => {
        return {
          ID: el.ID,
          Position_Name: el.Position_Name,
          Description: el.Description,
          Price_Override: el.Price_Override,
          Amount_Available: parseInt(el.Amount_Available),
        };
      });

export const offerPosFilter = (arr) => {
  if(!arr) return []
  let positionsAvailable = {}
  for(let i = 0; i < arr.length; i++){
    for(let j = 0; j < arr[i].Positions.length; j++){
      if(arr[i].Positions[j].Amount_Available > arr[i].Positions[j].Amount_Filled){
        if(positionsAvailable[arr[i].Positions[j].Position_Name]){
          positionsAvailable[arr[i].Positions[j].Position_Name] = positionsAvailable[arr[i].Positions[j].Position_Name] + 1
        } else {
          positionsAvailable[arr[i].Positions[j].Position_Name] = 1
        }
      } else {
        if((arr[i].Positions[j].Amount_Available - arr[i].Positions[j].Amount_Filled) === 0){
          if(!positionsAvailable[arr[i].Positions[j].Position_Name]){
            positionsAvailable[arr[i].Positions[j].Position_Name] = 0
          }
      }
    }
  }
}
const options = []
let  num = 0
for(let prop in positionsAvailable){
num++
  options.push(<option
  key={num}
  id={num}
  value={prop}
  disabled={!positionsAvailable[prop]}
  >{prop}</option>)
}
return options
}

export const offerPositionFilteredList = (arr, positionName) => {
  let filteredList = [];
  for(let i = 0; i < arr.length; i++){
    for(let j = 0; j < arr[i].Positions.length; j++){
     if(arr[i].Positions[j].Position_Name === positionName){
      if(arr[i].Positions[j].Amount_Available > arr[i].Positions[j].Amount_Filled){
        filteredList.push(arr[i])
      }
     }
  }
}
return filteredList
}