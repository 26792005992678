import Modal from "../Modal/Modal";
import AddressForm from "./AddressForm";
import AddressList from "./AddressList";

interface AddressEditProps {
  newAddress: boolean;
  addressRed: string;
  onBackClick: Function;
  showModal: boolean;
  onCloseModal: Function;
  setNewAddress: React.Dispatch<React.SetStateAction<boolean>>;
  success: string;
}

const AddressEdit = (props: AddressEditProps) => {
  const {
    newAddress,
    addressRed,
    onBackClick,
    showModal,
    onCloseModal,
    setNewAddress,
    success,
  } = props;

  return (
    <Modal
      title={`${
        newAddress
          ? "New Address Creation"
          : addressRed
          ? "Edit Address"
          : "Address Info"
      }`}
      backButton={newAddress || addressRed ? true : false}
      onBackButtonClick={onBackClick}
      show={showModal}
      setShow={onCloseModal}
    >
      {addressRed || newAddress ? (
        <AddressForm newAddress={newAddress} back={onBackClick} />
      ) : (
        <AddressList
          newAddress={newAddress}
          setNewAddress={setNewAddress}
          success={success}
        />
      )}
    </Modal>
  );
};

export default AddressEdit;
