import { useEffect, useState } from "react";
import { createPromoCode } from "../../API/promoCodeApi";
import {
  addMonthAmount,
  getDateInTimeZone,
  getNow,
  timeZones,
} from "../../Helpers/dateHelpers";
import { PromoInterface } from "../../Interfaces/PromoInterfaces";
import FormCheckBox from "../Forms/FormCheckBox";
import FormField from "../Forms/FormField";
import FormSelect from "../Forms/FormSelect";
import FormSubmitButton from "../Forms/FormSubmitButton";

interface PromoCodeProps {
  edit?: PromoInterface;
  id?: number;
  cb: Function;
}

const PromoComp = (props: PromoCodeProps) => {
  let dateString = getNow();
  const yearAway = addMonthAmount(dateString, 12);
  const [name, setName] = useState("");
  const [active, setActive] = useState(true);
  const [startDate, setStartDate] = useState(dateString);
  const [endDate, setEndDate] = useState(yearAway);
  const [percentageDisc, setPercentageDisc] = useState("0");
  const [amountDisc, setAmountDisc] = useState("0");
  const [uses, setUses] = useState(1000000);
  const [allowedUsage, setAllowedUsage] = useState([]);
  const [inDollars, setInDollars] = useState(true);
  const [error, setError] = useState("");
  const [timeZone, setTimeZone] = useState("America/Chicago");

  useEffect(() => {
    if (props.edit?.ID) {
      setName(props.edit.Name);
      setActive(props.edit.Active);
      setStartDate(props.edit.Start_Date.slice(0, -9));
      setEndDate(props.edit.End_Date.slice(0, -9));
      if (props.edit.Percentage_Disc > 0) {
        setInDollars(false);
        setPercentageDisc(props.edit.Percentage_Disc.toString());
        setAmountDisc("0");
      } else {
        setInDollars(true);
        setPercentageDisc("0");
        setAmountDisc(props.edit.Amount_Disc.toString());
      }
    } else {
      resetComponent();
    }
  }, [props.edit?.ID]);

  const resetComponent = () => {
    setName("");
    setActive(true);
    setStartDate(dateString);
    setEndDate(yearAway);
    setPercentageDisc("0");
    setAmountDisc("0");
    setInDollars(true);
  };

  const submitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError("");

    const res = await createPromoCode({
      id: props.edit?.ID ? props.edit.ID : 0,
      name,
      active,
      startDate: getDateInTimeZone(startDate, timeZone),
      endDate: getDateInTimeZone(endDate, timeZone),
      percentageDisc: parseInt(percentageDisc),
      amountDisc: parseInt(amountDisc),
      // uses,
      //allowedUsage
    });
    if (res.error) {
      setError(res.error);
      return;
    }
    props.cb();
  };

  return (
    <div>
      <form className="forms__form p-0" onSubmit={(e) => submitForm(e)}>
        {/* <div className="forms__title">Offer Info:</div> */}
        <div className="forms__group--fit">
          <FormField
            label="Promo Name"
            placeholder="Promo Name"
            value={name}
            func={setName}
            required={true}
            type="text"
            disabled={false}
            maxLength={54}
            // error={formErrors.businessName}
          />
          {/* <FormField
              label="Description"
              placeholder="Describe your offer"
              value={description}
              func={setDescription}
              type="text"
              disabled={false}
              required={true}
              textArea={true}
              // error={formErrors.url}
            /> */}
          {inDollars ? (
            <FormField
              label="Discount (in $)"
              type="number"
              disabled={false}
              required={true}
              min={0}
              name="amountDisc"
              value={amountDisc}
              func={setAmountDisc}
              linkText="Switch to Percentage"
              linkFunc={() => {
                setInDollars(false);
                setAmountDisc("0");
              }}
              //   nameAndValueFunc={priceSetter}
            />
          ) : (
            <FormField
              label="Discount (in %)"
              type="number"
              disabled={false}
              required={false}
              min={0}
              name="percentageDisc"
              value={percentageDisc}
              func={setPercentageDisc}
              linkText="Switch to Dollars"
              linkFunc={() => {
                setInDollars(true);
                setPercentageDisc("0");
              }}
              //   nameAndValueFunc={priceSetter}
            />
          )}
          <div className="forms__field forms__flex forms__flex--column">
            <label className="">Start Date</label>
            <input
              type="datetime-local"
              id={"startDate"}
              name="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="forms__field forms__flex forms__flex--column">
            <label className="">End Date</label>
            <input
              type="datetime-local"
              id={"endDate"}
              name="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <FormSelect
            options={timeZones}
            value={timeZone}
            func={setTimeZone}
            label="Time Zone"
            required={false}
          />
          <FormCheckBox
            label="Active"
            value={active}
            disabled={false}
            func={setActive}
          />
        </div>
        <div className="forms__break--small" />
        <div className="component-margin-bottom">
          <FormSubmitButton
            disabled={false}
            title={props.edit?.ID ? "Edit Promo" : "Create Promo"}
          />
          {error && <div className="signup__error">{error}</div>}
        </div>
      </form>
    </div>
  );
};

export default PromoComp;
