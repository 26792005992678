import { appDelete, appGet, appPost } from "./appFetch"

export const createForumAnnouncement = async (params) => {
    return await appPost("/forum/createforumannouncement", params)
}

export const getForum = async (params) => {
    return await appGet("/forum/getforum", params)
}

export const deleteForumAnnouncement = async (params) => {
    return await appDelete("/forum/deleteforumannouncement", params)
}
