import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkForStripeApi } from "../API/userApi";
import FormSimpleToggle from "../Components/Forms/FormSimpleToggle";
import OfferComp from "../Components/Offer/OfferComp";
import PromoComp from "../Components/Promo/PromoComp";
import PromoContainer from "../Components/Promo/PromoContainer";
import { useAppSelector } from "../Redux/Hooks";

interface NewOfferPageProps {
  edit?: boolean;
}

const NewOfferPage = (props: NewOfferPageProps) => {
  // We are not including stripe in the open demo
  // due to stripes policy
  const { accountType, addresses, leaders } = useAppSelector(
    (state) => state.user
  );
  const allow = true;
  //  const [allow, setAllow] = useState(false);
  const [toggleCreate, setToggleCreate] = useState("Offer");
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (accountType === 2) {
  //     checkForStripe();
  //   }
  // }, []);

  // const checkForStripe = async () => {
  //   const allowAccess = await checkForStripeApi();
  //   if (allowAccess.allow) setAllow(true);
  //   else setAllow(false);
  // };

  useEffect(() => {
    if (accountType !== 2) navigate("/login");
    if (addresses.length === 0) navigate("/account");
  }, [accountType]);
  if (
    !addresses.length ||
    !leaders.length ||
    (addresses.length && !addresses[0]?.Address_Line_One) ||
    (leaders.length && !leaders[0].ID) ||
    !allow
  ) {
    return (
      <div className="page-center subtitle-text p-3">
        {!allow &&
          "Please set up your account with stripe so that you can recieve payments before making an offer. "}
        Follow the link to add your company information.
        <div className="link-text">
          <Link to="../account" relative="path">
            Here
          </Link>
        </div>
        <div className="p-3">
          <em>(Please be sure to add an address and an offer leader)</em>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="title mb-3">Create</div>
      <div className="width center">
        <div className="width mb-3">
          <FormSimpleToggle
            value={toggleCreate}
            func={(val: string) => {
              setToggleCreate(val);
            }}
            left="Offer"
            right="Promotion"
          />
        </div>
      </div>
      {toggleCreate === "Offer" && <OfferComp edit={props.edit} />}
      {toggleCreate === "Promotion" && <PromoContainer />}
    </div>
  );
};

export default NewOfferPage;
