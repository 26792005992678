interface ListContainerProps {
  children?: React.ReactNode;
}

const ListContainer = (props: ListContainerProps) => {
  return (
    <div className="list-container list__margin-bottom">{props.children}</div>
  );
};

export default ListContainer;
