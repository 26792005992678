import { useEffect, useState } from "react";
import { isPrice } from "../../Helpers/regex";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import FormAddressSelector from "../Forms/FormAddressSelector";
import FormCheckBox from "../Forms/FormCheckBox";
import FormDateTime from "../Forms/FormDateTime";
import FormField from "../Forms/FormField";
import FormSubmitButton from "../Forms/FormSubmitButton";
import PositionsComp from "./PositionsComp";
import Accordion from "../Common/Accordion";
import DatesList from "./DatesList";
import OfferCompCust from "../../UserComponents/OfferDisplay/OfferCompCust";
import FormLeaderSelector from "../Forms/FormLeaderSelector";
import React from "react";
import ImageUpload from "../Common/ImageUpload";
import {
  createDatesAndPoistions,
  createOffer,
  editOffer,
  getCtaInfo,
  getCustomFormFields,
  getOfferByIdReview,
} from "../../API/offersApi";
import { datesArrayMaker } from "../../Helpers/dateHelpers";
import { positionsArrayMaker } from "../../Helpers/positionHelpers";
import {
  OfferCompGiven,
  OfferReq,
  OfferReqUpdate,
  PositionsInterface,
  SingleDate,
} from "../../Interfaces/OfferInterface";
import { useNavigate, useParams } from "react-router-dom";
import { clickedOffer, resetOffer } from "../../Redux/OfferReducer";
import { dateInterfaceToSingleDateArr } from "../../Helpers/offerHelpers";
import { PolicyInterface } from "../../Interfaces/PolicyInterface";

interface OfferCompProps {
  edit?: boolean;
}

const OfferComp = (props: OfferCompProps) => {
  const editDateObj = {
    duration: 0,
    startSession: "",
    endSession: "",
  };

  const { addressId, leaders, policies } = useAppSelector(
    (state) => state.user
  );

  const offerInfo = useAppSelector((state) => state.offer);
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(
    ""
  );
  const [imageFile, setImageFile] = useState<File>();
  const [offerId, setOfferId] = useState(0);
  const [name, setName] = useState("");
  const [addyId, setAddyId] = useState(addressId);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("0");
  const [bookAfterStartDate, setBookAfterStartDate] = useState(false);
  const [displayAfterStartDate, setDisplayAfterStartDate] = useState(true);
  const [active, setActive] = useState(true);
  // const [groupPurchase, setGroupPurchase] = useState("1");
  // const [formType, setFormType] = useState(1);
  const [lead, setLead] = useState(leaders.length > 0 ? leaders[0].ID : 0);
  const [policy, setPolicy] = useState(
    policies.length > 0 ? policies[0].ID : 0
  );
  const [showPosition, setShowPosition] = useState(true);
  const [showAvailable, setShowAvailable] = useState(false);
  const [amountAvailable, setAmountAvailable] = useState("1");
  // const [showAvailableIgnore, setShowAvailableIgnore] = useState(false);
  // const [showPositionIgnore, setShowPositionIgnore] = useState(true);
  const [position, setPosition] = useState<PositionsInterface[]>([]);
  const [timeZone, setTimeZone] = useState("America/Chicago");
  const [dates, setDates] = useState<SingleDate[]>([]);
  const [editDate, setEditDate] = useState<SingleDate>({
    ...editDateObj,
  });

  const [error, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const [fieldOne, setFieldOne] = useState("");
  const [fieldOneReq, setFieldOneReq] = useState(false);
  const [fieldOneId, setFieldOneId] = useState(0);
  const [fieldTwo, setFieldTwo] = useState("");
  const [fieldTwoReq, setFieldTwoReq] = useState(false);
  const [fieldTwoId, setFieldTwoId] = useState(0);
  const [fieldThree, setFieldThree] = useState("");
  const [fieldThreeReq, setFieldThreeReq] = useState(false);
  const [fieldThreeId, setFieldThreeId] = useState(0);
  const [fieldFour, setFieldFour] = useState("");
  const [fieldFourReq, setFieldFourReq] = useState(false);
  const [fieldFourId, setFieldFourId] = useState(0);
  const [fieldFive, setFieldFive] = useState("");
  const [fieldFiveReq, setFieldFiveReq] = useState(false);
  const [fieldFiveId, setFieldFiveId] = useState(0);

  const [ctaOneTitle, setCtaOneTitle] = useState("");
  const [ctaOneLink, setCtaOneLink] = useState("");

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const stateObj: OfferCompGiven = {
    name: name,
    addyId: addyId,
    description: description,
    price: price,
    bookAfterStartDate: bookAfterStartDate,
    displayAfterStartDate: displayAfterStartDate,
    active: active,
    lead: lead,
    position: position,
    timeZone: timeZone,
    dates: dates,
    imagePreview: imagePreview,
  };

  const param = useParams();

  const getCustomFields = async (id: number) => {
    const res = await getCustomFormFields({ id: id });
    if (res.fields) {
      for (let i = 0; i < res.fields.length; i++) {
        if (i === 0) {
          setFieldOne(res.fields[i].Title);
          setFieldOneReq(res.fields[i].Required);
          setFieldOneId(res.fields[i].ID);
        }
        if (i === 1) {
          setFieldTwo(res.fields[i].Title);
          setFieldTwoReq(res.fields[i].Required);
          setFieldTwoId(res.fields[i].ID);
        }
        if (i === 2) {
          setFieldThree(res.fields[i].Title);
          setFieldThreeReq(res.fields[i].Required);
          setFieldThreeId(res.fields[i].ID);
        }
        if (i === 3) {
          setFieldFour(res.fields[i].Title);
          setFieldFourReq(res.fields[i].Required);
          setFieldFourId(res.fields[i].ID);
        }
        if (i === 4) {
          setFieldFive(res.fields[i].Title);
          setFieldFiveReq(res.fields[i].Required);
          setFieldFiveId(res.fields[i].ID);
        }
      }
    }
    const response = await getCtaInfo({
      id: offerInfo.ID,
    });
    if (response.ctaText) {
      setCtaOneTitle(response.ctaText);
      setCtaOneLink(response.ctaUrl);
    }
  };

  useEffect(() => {
    if (param.id && props.edit) getOfferToEdit(param.id);
    if (!props.edit) dispatch(resetOffer());
  }, []);

  const getOfferToEdit = async (id: string) => {
    const res = await getOfferByIdReview({ id });
    if (res?.offers?.length > 0) {
      dispatch(clickedOffer(res.offers[0]));
      setOfferId(res.offers[0].ID);
      setName(res.offers[0].Name);
      setAddyId(res.offers[0].Address_ID.toString());
      setDescription(res.offers[0].Description);
      setPrice(res.offers[0].Price.toString());
      setBookAfterStartDate(res.offers[0].Book_After_Start_Date);
      setDisplayAfterStartDate(res.offers[0].Display_After_Start_Date);
      setActive(res.offers[0].Active);
      setLead(res.offers[0].Lead_ID);

      setPosition(res.offers[0].Positions);
      // Timezones aren't being used currently
      // setTimeZone(res.offers[0].TimeZone);
      setDates(dateInterfaceToSingleDateArr(res.offers[0].Dates));
      setImagePreview(res.offers[0].Image_Url);
      setPolicy(
        res.offers[0].Terms_And_Conditions_ID?.ID
          ? res.offers[0].Terms_And_Conditions_ID.ID
          : 1
      );
      getCustomFields(res.offers[0].ID);
      setEdit(true);
    } else setError("Offer to edit not found");
  };

  // Gives us localId for edit and delete purposes
  useEffect(() => {
    if (dates.length > 0) {
      let dateArr = [...dates];
      for (let i = 0; i < dateArr.length; i++) {
        dateArr[i].localId = i;
      }
      setDates(dateArr);
    }
  }, [dates.length]);

  useEffect(() => {
    if (position.length > 0) {
      let posArr = [];
      for (let i = 0; i < position.length; i++) {
        posArr.push({ ...position[i], Local_Id: i });
      }
      setPosition(posArr);
    }
  }, [position.length]);

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  const dateFunc = (val: SingleDate[]) => {
    setDates(val);
  };

  const dateListFunc = (val: number) => {
    let dateArr = [...dates];
    if (editDate.localId === val) {
      setEditDate({
        ...editDateObj,
      });
      return;
    }
    for (let i = 0; i < dateArr.length; i++) {
      if (dateArr[i].localId === val) {
        setEditDate(dateArr[i]);
        break;
      }
    }
  };

  const deleteDate = (e: React.SyntheticEvent, id: number) => {
    e.stopPropagation();
    let dateArr = [...dates];
    dateArr = dateArr.filter((el) => el.localId !== id);
    setDates(dateArr);
    setEditDate({
      ...editDateObj,
    });
  };

  const addDate = (date: SingleDate) => {
    setDates([...dates, date]);
  };

  useEffect(() => {
    if (showPosition) setShowAvailable(false);
    else if (!showPosition) setShowAvailable(true);
  }, [showPosition]);

  useEffect(() => {
    if (showAvailable) setShowPosition(false);
    else if (!showAvailable) setShowPosition(true);
  }, [showAvailable]);

  const priceSetter = (name: string, value: string) => {
    if (!isPrice.test(value) && value !== "" && value !== "-") return;
    setPrice(value);
  };

  // Form Submission
  const submitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError("");
    if (!imageFile && !props.edit) {
      setError("Please choose an image to upload");
      return;
    }
    if (!dates.length) {
      setError("Please create offer dates");
      return;
    }
    if (!position.length) {
      setError("Please create atleast 1 offer position");
      return;
    }
    if (!lead) {
      setError("Please add an offer leader to this offer");
      return;
    }

    // Prepare dates for server
    let dateArr = datesArrayMaker(dates, timeZone);

    // Prepare positions for server
    let positionArr = positionsArrayMaker(position);

    try {
      // Do edit submit
      if (edit) {
        const offerReq: OfferReqUpdate = {
          id: offerId,
          name: name,
          addressId: addyId,
          description: description,
          price: price,
          bookAfterStartDate: bookAfterStartDate,
          displayAfterStartDate: displayAfterStartDate,
          active: active,
          lead: lead,
          file: imageFile ? imageFile : "",
          timeZone: timeZone,
          termsAndConditionsId: policy,
          customOne: fieldOne,
          customOneId: fieldOneId,
          customOneReq: fieldOneReq,
          customTwo: fieldTwo,
          customTwoId: fieldTwoId,
          customTwoReq: fieldTwoReq,
          customThree: fieldThree,
          customThreeId: fieldThreeId,
          customThreeReq: fieldThreeReq,
          customFour: fieldFour,
          customFourId: fieldFourId,
          customFourReq: fieldFourReq,
          customFive: fieldFive,
          customFiveId: fieldFiveId,
          customFiveReq: fieldFiveReq,
          ctaTitle: ctaOneTitle,
          ctaUrl: ctaOneLink,
        };

        // editOffer
        const res = await editOffer(offerReq);
        const id = res?.offer?.ID;
        if (!id) return;
        let posArr = [...positionArr].map((el) => {
          el.Price_Override = parseInt(el.Price_Override);
          return el;
        });
        // Edit Dates And Positions
        await createDatesAndPoistions({
          offerId: id,
          position: posArr,
          dates: dateArr,
        });
      } else {
        // Do New Offer Submit
        const offerReq: OfferReq = {
          name: name,
          addressId: addyId,
          description: description,
          price: price,
          bookAfterStartDate: bookAfterStartDate,
          displayAfterStartDate: displayAfterStartDate,
          active: active,
          lead: lead,
          file: imageFile!,
          timeZone: timeZone,
          termsAndConditionsId: policy,
          customOne: fieldOne,
          customOneId: fieldOneId,
          customOneReq: fieldOneReq,
          customTwo: fieldTwo,
          customTwoId: fieldTwoId,
          customTwoReq: fieldTwoReq,
          customThree: fieldThree,
          customThreeId: fieldThreeId,
          customThreeReq: fieldThreeReq,
          customFour: fieldFour,
          customFourId: fieldFourId,
          customFourReq: fieldFourReq,
          customFive: fieldFive,
          customFiveId: fieldFiveId,
          customFiveReq: fieldFiveReq,
          ctaTitle: ctaOneTitle,
          ctaUrl: ctaOneLink,
        };
        const res = await createOffer(offerReq);
        const id = res?.offer?.ID;
        if (!id) return;
        let posArr = [...positionArr].map((el) => {
          el.Price_Override = parseInt(el.Price_Override);
          return el;
        });
        await createDatesAndPoistions({
          offerId: id,
          position: posArr,
          dates: dateArr,
        });
      }
    } catch (error) {
      setError("Error creating offer");
      return;
    }
    navigate("/manageoffers");
  };

  const noPolicy: PolicyInterface = {
    Active: true,
    Description: "All sales final. No refunds given.",
    Name: "All sales final",
    ID: 4,
  };

  return (
    <>
      <form className="forms__form p-0" onSubmit={(e) => submitForm(e)}>
        {/* <div className="forms__title">Offer Info:</div> */}
        <Accordion title="Offer Info" startOpen>
          <div className="forms__group--fit">
            <FormField
              label="Name"
              placeholder="Offer Name"
              value={name}
              func={setName}
              required={true}
              type="text"
              disabled={false}
              maxLength={34}
              // error={formErrors.businessName}
            />
            <FormField
              label="Description"
              placeholder="Describe your offer"
              value={description}
              func={setDescription}
              type="text"
              disabled={false}
              required={true}
              textArea={true}
              // error={formErrors.url}
            />
            <FormField
              label="Price (In dollars)"
              type="number"
              disabled={false}
              required={false}
              min={0}
              name="Price_Override"
              value={price}
              nameAndValueFunc={priceSetter}
            />
            <FormAddressSelector
              label="Address"
              value={addyId}
              func={setAddyId}
              disabled={false}
              required={true}
              // error={formErrors.state}
            />
            <FormLeaderSelector
              label="Offer Leader"
              value={lead}
              func={setLead}
              disabled={false}
              required={true}
              options={[...leaders]}
              // error={formErrors.phone}
            />
            <FormLeaderSelector
              label="Cancellation / Policies"
              value={policy}
              func={setPolicy}
              disabled={false}
              required={false}
              policy
              options={[noPolicy, ...policies]}
              // error={formErrors.phone}
            />
            <ImageUpload
              setImagePreview={setImagePreview}
              setImageFile={setImageFile}
              imageFileName={imageFile ? imageFile?.name : ""}
              label="Image"
              required
            />
          </div>
        </Accordion>
        <div className="forms__break--small" />
        {/* <div className="forms__title">Offer Dates:</div> */}
        {/* <div className="forms__group--fit"> */}
        <Accordion title="Offer Dates">
          {dates.length > 0 && (
            <DatesList
              dates={dates}
              func={dateListFunc}
              deleteFunc={deleteDate}
              editDate={editDate?.localId}
            />
          )}
          <FormDateTime
            label="Start Date"
            dates={dates}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            addDate={addDate}
            editDate={editDate}
            setEditDate={setEditDate}
            // value={startDate.startSession}
            func={dateFunc}
          />
        </Accordion>
        {/* </div> */}
        <div className="forms__break--small" />
        {/* <div className="forms__title">Offer Settings:</div> */}
        <Accordion title="Offer Settings">
          <div className="forms__group--fit">
            <FormCheckBox
              label="Display After Start Date"
              value={displayAfterStartDate}
              disabled={false}
              func={setDisplayAfterStartDate}
            />
            {displayAfterStartDate && (
              <FormCheckBox
                label="Book After Start Date"
                value={bookAfterStartDate}
                disabled={false}
                func={setBookAfterStartDate}
              />
            )}
            <FormCheckBox
              label="Make Offer Active"
              value={active}
              disabled={false}
              func={setActive}
            />
          </div>
        </Accordion>
        <div className="forms__break--small" />
        {/* <div className="forms__title">Availability:</div> */}
        <Accordion title="Offer Positions">
          {showPosition && (
            <PositionsComp position={position} setPosition={setPosition} />
          )}
          {showAvailable && (
            <div className="forms__group--border">
              <div className="forms__title">Amount Available:</div>
              <div className="forms__group--fit">
                <div className="forms__group--limit-width">
                  <FormField
                    label="Amount Available"
                    type="number"
                    placeholder={"1"}
                    value={amountAvailable}
                    func={setAmountAvailable}
                    disabled={false}
                    required={false}
                    min={0}
                    // error={formErrors.phone}
                  />
                </div>
              </div>
            </div>
          )}
        </Accordion>
        <div className="forms__break--small" />
        <Accordion title="Custom Checkout Fields">
          <div>
            Fields included in checkout form by default: Name, Email, Phone,
            Anything Else We Should Know.
          </div>
          <div>
            The fields below will be added to your checkout form for this offer.
            Blank fields will not be added
          </div>
          <div className="forms__group--fit">
            <div className="forms__custom-field">
              <FormField
                label="Field Title"
                placeholder="Instagram Handle"
                value={fieldOne}
                func={setFieldOne}
                required={false}
                type="text"
                disabled={false}
                maxLength={34}
                // error={formErrors.businessName}
              />
              <FormCheckBox
                label="Require this field"
                value={fieldOneReq}
                disabled={false}
                func={setFieldOneReq}
              />
            </div>

            <div className="forms__custom-field">
              <FormField
                label="Field Title"
                placeholder="Favorite Celebrity"
                value={fieldTwo}
                func={setFieldTwo}
                required={false}
                type="text"
                disabled={false}
                maxLength={34}
                // error={formErrors.businessName}
              />
              <FormCheckBox
                label="Require this field"
                value={fieldTwoReq}
                disabled={false}
                func={setFieldTwoReq}
              />
            </div>

            <div className="forms__custom-field">
              <FormField
                label="Field Title"
                placeholder="What would you like to focus on?"
                value={fieldThree}
                func={setFieldThree}
                required={false}
                type="text"
                disabled={false}
                maxLength={34}
                // error={formErrors.businessName}
              />
              <FormCheckBox
                label="Require this field"
                value={fieldThreeReq}
                disabled={false}
                func={setFieldThreeReq}
              />
            </div>

            <div className="forms__custom-field">
              <FormField
                label="Field Title"
                placeholder="What is your lunch preference?"
                value={fieldFour}
                func={setFieldFour}
                required={false}
                type="text"
                disabled={false}
                maxLength={34}
                // error={formErrors.businessName}
              />
              <FormCheckBox
                label="Require this field"
                value={fieldFourReq}
                disabled={false}
                func={setFieldFourReq}
              />
            </div>

            <div className="forms__custom-field">
              <FormField
                label="Field Title"
                placeholder="Any hidden talents?"
                value={fieldFive}
                func={setFieldFive}
                required={false}
                type="text"
                disabled={false}
                maxLength={34}
                // error={formErrors.businessName}
              />
              <FormCheckBox
                label="Require this field"
                value={fieldFiveReq}
                disabled={false}
                func={setFieldFiveReq}
              />
            </div>
          </div>
        </Accordion>

        <div className="forms__break--small" />
        <Accordion title="Custom Confirmation CTA">
          When a customer books your offer, they are lead to a confirmation
          page. On this page customers can add the offers dates to the their
          google calendar and get to the offers forum. Would you like to add an
          extra button to this page with another call to action? Example:
          Whatsapp group link for the offer
          <div className="forms__group--fit">
            <div className="forms__custom-field forms__custom-field--link">
              <FormField
                label="Button Text"
                placeholder="Join our group chat"
                value={ctaOneTitle}
                func={setCtaOneTitle}
                required={false}
                type="text"
                disabled={false}
                maxLength={34}
                // error={formErrors.businessName}
              />
              <FormField
                label="URL"
                placeholder="https://www.google.com/"
                value={ctaOneLink}
                func={setCtaOneLink}
                required={false}
                type="text"
                disabled={false}
                maxLength={200}
                // error={formErrors.businessName}
              />
            </div>
          </div>
        </Accordion>
        {/* {error && (
        <div className="forms__field--error forms__field--error--center">
          {error}
        </div>
      )}
      {infoUpdated && (
        <div className="forms__field--success">{`Save successful! Updated at ${infoUpdated}`}</div>
      )} */}

        <OfferCompCust stateObj={stateObj} />
        <div className="component-margin-bottom">
          <FormSubmitButton
            disabled={false}
            title={edit ? "Edit Offer" : "Create Offer"}
          />
        </div>
        {error && <div className="signup__error">{error}</div>}
      </form>
    </>
  );
};

export default OfferComp;
