import { useState } from "react";
import FormField from "../Forms/FormField";
import FormSubmitButton from "../Forms/FormSubmitButton";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { updateUserThunk } from "../../Redux/UserFuncs";
import AddressComp from "./AddressComp";
import OfferLeader from "./OfferLeader";
import ImageUpload from "../Common/ImageUpload";
import FormInnerButton from "../Forms/FormInnerButton";
import { updateLogoApi } from "../../API/userApi";
import { updateUser } from "../../Redux/UserReducer";
import Accordion from "../Common/Accordion";
import CancellationPolicy from "./CancellationPolicy";

const OnBoardingForm = () => {
  const userInfo = useAppSelector((state) => state.user);
  const { error, infoUpdated } = userInfo;
  const [businessName, setBusinessName] = useState(userInfo.businessName || "");
  const [contactName, setContactName] = useState(userInfo.contactName || "");
  const [url, setUrl] = useState(userInfo.url || "");
  const [phone, setPhone] = useState(userInfo.phone || "");
  const [extension, setExtension] = useState(userInfo.extension || "");
  const [donationPercentage, setDonationPercentage] = useState("1");
  // Stripe not in demo
  const stripeEnabled = false;
  // const [stripeEnabled, setStripeEnabled] = useState(false);
  //
  const [desc, setDesc] = useState(userInfo.description || "");

  const [logoUploadError, setLogoUploadError] = useState("");

  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(
    ""
  );
  const [imageFile, setImageFile] = useState<File>();

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   stripeComplete();
  // }, []);

  // const stripeComplete = async () => {
  //   const res = await checkForStripeApi();
  //   if (res.allow) setStripeEnabled(true);
  //   else setStripeEnabled(false);
  // };

  const donationChange = (val: string) => {
    if (val) {
      if (parseInt(val) >= 1 && parseInt(val) <= 100) {
        setDonationPercentage(val);
      } else setDonationPercentage("1");
    } else setDonationPercentage("1");
  };

  const submitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(
      updateUserThunk({
        businessName,
        contactName,
        url,
        phone,
        extension,
        donationPercentage,
        description: desc,
      })
    );
  };

  const uploadLogo = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLogoUploadError("");
    try {
      const user = await updateLogoApi({ file: imageFile });
      if (user.error) {
        setLogoUploadError("Error uploading Logo");
        return;
      }
      dispatch(updateUser(user));
    } catch (error) {
      setLogoUploadError("Error uploading Logo");
    }
  };

  return (
    <div className="">
      {stripeEnabled ? (
        <div className="text-center font-size-14 pb-2 pt-1">
          Stripe account approved
        </div>
      ) : (
        <></>
        // <div className="text-center font-size-14 pb-2 pt-1">
        //   <div className="color-danger mr-1 font-size-18">
        //     <strong>ALERT:</strong>
        //   </div>
        //   <div className="forms__button-container">
        //     <div
        //       className="forms__submit--plain p-1"
        //       onClick={() => {
        //         navigate("getpaid");
        //       }}
        //     >
        //       Finish account setup with stripe
        //     </div>
        //   </div>
        // </div>
      )}
      <Accordion title="Company Info" startOpen>
        <form className="forms__form" onSubmit={(e) => submitForm(e)}>
          <div className="forms__group">
            <FormField
              label="Business Name"
              placeholder="ACME Corporation"
              value={businessName}
              func={setBusinessName}
              required={true}
              type="text"
              disabled={false}
              // error={formErrors.businessName}
            />
            <FormField
              label="Contact Name"
              placeholder="Rachel Rae"
              value={contactName}
              func={setContactName}
              type="text"
              disabled={false}
              required={true}
              // error={formErrors.contactName}
            />
            <FormField
              label="Url"
              placeholder="www.AcmeSolutions.com"
              value={url}
              func={setUrl}
              type="text"
              disabled={false}
              required={false}
              // error={formErrors.url}
              minLength={6}
            />
            <FormField
              label="Phone"
              type="tel"
              // pattern={
              //   country === "United States"
              //     ? "[0-9]{3}-[0-9]{3}-[0-9]{4}"
              //     : undefined
              // }
              placeholder="212-333-4444"
              value={phone}
              func={setPhone}
              disabled={false}
              required={true}
              // error={formErrors.phone}
              minLength={10}
              maxLength={20}
              country={"no country"}
            />

            <FormField
              label="Extension"
              type="text"
              placeholder="314#"
              value={extension}
              func={setExtension}
              disabled={false}
              required={false}
              // error={formErrors.phone}
            />

            <FormField
              label="Platform donation percentage"
              modalTitle="Info"
              modalInfo="In order to offset platform costs, Thread requires a minimum of 1% from each offer booking. Please donate 3% or more. This will help us grow and improve the tools we offer. Higher donations will allow us to drive more traffic to the offerings on ThreadBooking.com. Thank you!"
              type="number"
              disabled={false}
              required={false}
              min={1}
              name="donationPercentage"
              value={donationPercentage}
              func={donationChange}
            />

            <FormField
              label="Short Description Of Services"
              type="text"
              placeholder="Play in a real band and get lessons no matter your level"
              value={desc}
              func={setDesc}
              maxLength={100}
              disabled={false}
              required={true}
              // error={formErrors.phone}
            />
          </div>
          <FormSubmitButton />
          {error && (
            <div className="forms__field--error forms__field--error--center">
              {error}
            </div>
          )}
          {infoUpdated && (
            <div className="forms__field--success">{`Save successful! Updated at ${infoUpdated}`}</div>
          )}
        </form>
      </Accordion>

      <div className="mt-3" />
      <Accordion title="Address Info">
        <div className="forms__form">
          <div className="forms__group">
            <AddressComp />
          </div>
        </div>
      </Accordion>

      <div className="mt-3" />
      <Accordion title="Leader Info and Policies">
        <div className="forms__form">
          <div className="forms__group">
            <OfferLeader />
            <CancellationPolicy />
          </div>
        </div>
      </Accordion>

      <div className="mt-3" />
      <Accordion title="Logo">
        <div className="forms__form">
          <div className="forms__group">
            <ImageUpload
              setImageFile={setImageFile}
              imageFileName={imageFile ? imageFile?.name : ""}
              setImagePreview={setImagePreview}
              label="Logo"
              message="Logo images help brand your offers"
            />
            <div className="forms__match-image-upload-height--container">
              {(userInfo.logo || imagePreview) && (
                <img
                  className="forms__match-image-upload-height"
                  src={
                    imagePreview && typeof imagePreview === "string"
                      ? imagePreview
                      : userInfo.logo
                      ? userInfo.logo
                      : ""
                  }
                  alt="Logo"
                />
              )}
            </div>
          </div>
          <FormInnerButton
            title="Upload Logo"
            func={(e: React.SyntheticEvent) => uploadLogo(e)}
            primary
            disabled={imageFile ? false : true}
          />
          {logoUploadError && (
            <div className="forms__field--error forms__field--error--center">
              {logoUploadError}
            </div>
          )}
        </div>
      </Accordion>
      <div className="mt-3" />
      {/* <div className="forms__break" /> */}
    </div>
  );
};

export default OnBoardingForm;
