import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { resetPolicy } from "../../Redux/PolicyReducer";
import CancellationPolicyEdit from "./CancellationPolicyEdit";

interface CancellationPolicyProps {
  inline?: boolean;
}

const CancellationPolicy = (props: CancellationPolicyProps) => {
  const { name } = useAppSelector((state) => state.policy);
  const [showModal, setShowModal] = useState(false);
  const [newPolicy, setNewPolicy] = useState(false);
  const [success, setSuccess] = useState("");
  const dispatch = useAppDispatch();

  const onCloseModal = (showSuccess: boolean = false) => {
    setShowModal(false);
    setNewPolicy(false);
    dispatch(resetPolicy());
    if (showSuccess) setSuccess("Policy Update Successful");
    else setSuccess("");
  };

  const onBackClick = (showSuccess: boolean = false) => {
    setNewPolicy(false);
    dispatch(resetPolicy());
    if (showSuccess) setSuccess("Policy Update Successful");
    else setSuccess("");
  };
  return (
    <>
      {props.inline ? (
        <span
          className="forms__field--link"
          onClick={() => {
            setShowModal(!showModal);
            setSuccess("");
          }}
        >
          View/Edit Policies
        </span>
      ) : (
        <div
          className="address__comp--link"
          onClick={() => {
            setShowModal(!showModal);
            setSuccess("");
          }}
        >
          View/Edit Policies
        </div>
      )}
      <CancellationPolicyEdit
        newPolicy={newPolicy}
        name={name}
        onBackClick={onBackClick}
        showModal={showModal}
        onCloseModal={onCloseModal}
        setNewPolicy={setNewPolicy}
        success={success}
      />
    </>
  );
};

export default CancellationPolicy;
