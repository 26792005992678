import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isEmail } from "../../Helpers/regex";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { loginUserThunk } from "../../Redux/UserFuncs";
import { removeErrors } from "../../Redux/UserReducer";
import AuthContainerComp from "./AuthContainerComp";

const LoginComp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const dispatch = useAppDispatch();
  const { loginError } = useAppSelector((state) => state.user);

  const loginUser = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(removeErrors());
    setErr("");
    if (!password || password.length < 8) {
      setErr("Passwords must be atleast 8 characters");
      return;
    }
    if (!isEmail.test(email) || !email) {
      setErr("Please enter a valid email");
      return;
    }
    dispatch(loginUserThunk({ email: email, password: password }));
  };

  return (
    <AuthContainerComp>
      <form className="signup" onSubmit={(e) => loginUser(e)}>
        <h1 className="signup__title">Log in</h1>
        <div className="signup__input-group">
          <input
            id="login-email"
            className="signup__input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="signup__input-group">
          <input
            id="login-password"
            className="signup__input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="signup__login">
          <p>
            Not A Member?{" "}
            <strong>
              <Link className="signup__link" to={"/signup"}>
                Sign up
              </Link>
            </strong>
          </p>
        </div>
        <input type="submit" className="signup__button" value={"Login"} />
        {err && <p className="signup__error">{err}</p>}
        {loginError && <p className="signup__error">{loginError}</p>}
      </form>
    </AuthContainerComp>
  );
};

export default LoginComp;
