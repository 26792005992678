import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { getAddressesById } from "../../API/addressApi";
import { deleteForumAnnouncement, getForum } from "../../API/forumApi";
import { makeAddressString } from "../../Helpers/addressHelpers";
import { minutesToFormattedTime } from "../../Helpers/dateHelpers";
import {
  dateInterfaceToSingleDateArr,
  durationMaker,
} from "../../Helpers/offerHelpers";
import { AddressInterface } from "../../Interfaces/AddressInterface";
import { ForumInterface } from "../../Interfaces/ForumInterface";
import { useAppSelector } from "../../Redux/Hooks";
import OfferInfo from "../../UserComponents/OfferDisplay/OfferInfo";
import Calendar from "../Calendar/Calendar";
import Modal from "../Modal/Modal";
import ForumOfferForm from "./ForumOwnerForm";

interface ForumInfoProps {
  owner?: boolean;
}

const ForumInfo = (props: ForumInfoProps) => {
  const offer = useAppSelector((state) => state.offer);
  const [address, setAddress] = useState<AddressInterface>();
  const [errorMessage, setErrorMessage] = useState("");
  const [offerView, setOfferView] = useState(false);
  const [posts, setPosts] = useState<ForumInterface[]>();
  const [postClicked, setPostClicked] = useState<ForumInterface>();
  const [showModal, setShowModal] = useState(0);
  const [deleteError, setDeleteError] = useState("");

  useEffect(() => {
    getAddy();
    getForumInfo();
  }, []);

  const getAddy = async () => {
    const addressObj = await getAddressesById({ id: offer.Address_ID });
    setAddress(addressObj?.address);
    if (!addressObj.address) setErrorMessage("Address not found");
  };

  const getForumInfo = async () => {
    const res = await getForum({ id: offer.ID });
    setPosts(res.posts);
  };

  const onCloseModal = () => {
    setDeleteError("");
    setShowModal(0);
  };

  const doDeletePost = async (id: number) => {
    const res = await deleteForumAnnouncement({ id: id });
    if (res.error) {
      setDeleteError("Error deleting");
      return;
    }
    setPosts(res.posts);
    setShowModal(0);
  };

  const mapMe = posts?.length ? posts : [];

  const announcements = mapMe.map((el, i) => {
    return (
      <div className="forum__item" key={i}>
        <div className="forum__item--top">
          <div className="flex justify-space-between width">
            <div className="forum__item--title">{el.Title}</div>
            {props.owner && (
              <div className="forum__item--top--tools">
                <div className="mr-1" onClick={() => setPostClicked(el)}>
                  <FontAwesomeIcon
                    icon={icon({ name: "pencil", style: "solid" })}
                  />
                </div>
                <div onClick={() => setShowModal(el.ID!)}>
                  <FontAwesomeIcon icon={icon({ name: "x", style: "solid" })} />
                </div>
              </div>
            )}
          </div>
          <span className="font-size-14">
            {format(parseISO(el.UpdatedAt), "EEEE, LLLL do yyyy hh:mm a")}
          </span>
        </div>
        <ReactMarkdown className="mark-down font-size-14" linkTarget="_blank">
          {el.Description}
        </ReactMarkdown>
      </div>
    );
  });

  let durationHours = 0,
    durationMinutes = 0,
    dur = { durationHours: 0, durationMinutes: 0 };

  if (offer.Dates?.length > 0) {
    dur = durationMaker(minutesToFormattedTime(offer.Dates[0].Duration));
  }

  if (dur.durationHours || dur.durationMinutes) {
    durationHours = dur.durationHours;
    durationMinutes = dur.durationMinutes;
  }

  const addressString = makeAddressString(address);

  return (
    <div className="forum__container">
      <div className="title mb-1">{`${offer.Name} Forum`}</div>
      {errorMessage && <div className="signup__error">{errorMessage}</div>}
      <Calendar offer={offer} />
      <div className="flex justify-space-between width mt-2">
        <div className="link-text" onClick={() => setOfferView(!offerView)}>
          View offer
        </div>
        {props.owner && (
          <div className="">
            <ForumOfferForm
              offer={offer}
              cb={(vals: ForumInterface[]) => setPosts(vals)}
              post={postClicked}
              unClickPost={() => setPostClicked(undefined)}
            />
          </div>
        )}
      </div>
      {offerView && (
        <div className="mt-3">
          <OfferInfo
            offer={offer}
            offerId={offer.ID}
            lead={offer.Lead_ID}
            startDate={offer.Dates[0].Date_Time_Start}
            endDate={offer.Dates[offer.Dates.length - 1].Date_Time_Start}
            dateCount={offer.Dates.length}
            dates={dateInterfaceToSingleDateArr(offer.Dates)}
            durationHours={durationHours}
            durationMinutes={durationMinutes}
            price={offer.Price.toString()}
            name={offer.Name}
            address={addressString}
            description={offer.Description}
            positions={offer.Positions}
            buttonClick={false}
            removeButton
          />
        </div>
      )}

      <div className="forum__threads--container">
        <h2 className="text-title__small color-primary">Announcements:</h2>

        <div>{announcements}</div>
      </div>
      <Modal
        title="Delete Post"
        show={showModal > 0 ? true : false}
        setShow={onCloseModal}
      >
        <div className="manage-offers__modal">
          <p className="mt-1 mb-2">
            You are about to delete this post. Press the Delete Post button to
            confirm.
          </p>
          <div className="forms__button-container">
            <div
              className="forms__submit--plain"
              onClick={() => {
                doDeletePost(showModal);
              }}
            >
              Delete Post
            </div>
          </div>
          {deleteError && <div className="signup__error">{deleteError}</div>}
        </div>
      </Modal>
    </div>
  );
};

export default ForumInfo;
