import { format, parseISO } from "date-fns";
import { minutesToFormattedTime } from "../../Helpers/dateHelpers";
import { SingleDate } from "../../Interfaces/OfferInterface";

interface OfferDatesProps {
  dates: SingleDate[];
}

const OfferDates = (props: OfferDatesProps) => {
  const datesSorted = props.dates.sort((a, b) => {
    if (a.startSession < b.startSession) return -1;
    else if (a.startSession > b.startSession) return 1;
    else return 0;
  });
  const dateList = datesSorted.map((el, i) => {
    let time = minutesToFormattedTime(el.duration);

    return (
      <div
        className={
          i % 2 === 0
            ? "offer-info__item offer-info__item--dark"
            : "offer-info__item offer-info__item"
        }
        key={i}
      >
        <div className="positions-list--value offer-info__split">
          <span>{format(parseISO(el.startSession), "EEEE, LLLL do yyyy")}</span>
          <span>{format(parseISO(el.startSession), "hh:mm a")}</span>
        </div>

        <div>
          <span className="positions-list--value">{time}</span>
        </div>
      </div>
    );
  });

  const dateHeader = (
    <div className="offer-info__list-header" key={-1}>
      <div>Dates</div>

      <div>Durations</div>
    </div>
  );

  dateList.unshift(dateHeader);

  return <div className="mt-1">{dateList}</div>;
};

export default OfferDates;
