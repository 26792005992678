import { appGet } from "../../API/appFetch";
import FormButton from "../Forms/FormButton";

const StripeOnBoarding = () => {
  const buttonClick = async () => {
    const res = await appGet("/stripe/NewAccountOnboarding");
    window.open(res.link, "_blank", "noreferrer");
  };

  return <FormButton label="Get Paid with Stripe" onClick={buttonClick} />;
};

export default StripeOnBoarding;
