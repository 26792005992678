import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { clickedPolicy, resetPolicy } from "../../Redux/PolicyReducer";
import ListContainer from "../Common/ListContainer";

interface CancellationPolicyListProps {
  newPolicy: boolean;
  setNewPolicy: Function;
  success: string;
}

const CancellationPolicyList = (props: CancellationPolicyListProps) => {
  const { policies } = useAppSelector((state) => state.user);
  const [curId, setCurId] = useState(-1);

  const dispatch = useAppDispatch();

  let pols = policies.map((el, i) => (
    <div
      className={
        i % 2 === 0
          ? `list__item list__item--dark address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
          : `list__item address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
      }
      onClick={() => {
        if (curId === i) {
          setCurId(-1);
          dispatch(resetPolicy());
          props.setNewPolicy(false);
        } else {
          setCurId(i);
          dispatch(clickedPolicy(el));
          props.setNewPolicy(false);
        }
      }}
      key={i}
    >
      {el.Name}
    </div>
  ));

  pols.unshift(
    <div
      key={-1}
      className="address-list__new address-list__item"
      onClick={() => props.setNewPolicy(true)}
    >
      Add New Policy
    </div>
  );

  let noPolicy = false;
  if (!policies[0]?.Name) {
    noPolicy = true;
  }

  if (noPolicy) {
    delete pols[1];
  }

  return (
    <>
      <div className="forms__title--margin" />
      <div className="address__success">{props.success}</div>
      {/* <div className="forms__title--subtitle forms__title--margin">
        Addresses (click an address to edit or delete)
      </div> */}
      <ListContainer>{pols}</ListContainer>
    </>
  );
};

export default CancellationPolicyList;
