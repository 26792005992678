import {appDelete, appGet, appPatch, appPost, appPut} from "./appFetch"

export const createLeader = async (params) => {
    return await appPost("/lead/create", params)
}

export const editLeader = async (params) => {
    return await appPatch("/lead/edit", params)
}

export const getLeaders = async (params) => {
    return await appGet("/lead/addresses", params)
}

export const removeLeader = async (params) => {
    return await appDelete("/lead/remove", params)
}

export const activateLeader = async (params) => {
    return await appPut("/lead/activate", params)
}