import { addLoading, subtractLoading } from "../Redux/LoadingReducer"
import { store } from "../Redux/Store"

const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }

var base = process.env.REACT_APP_BASE_URL
if(process.env.REACT_APP_PRODUCTION === "true") {
    base = process.env.REACT_APP_BASE_URL_PROD
}

export const appPost = async(url, params) => {
    store.dispatch(addLoading())
    try {
        let res = await fetch(`${base}${url}`,
        {
            method: "POST",
            headers: HEADERS,
            withCredentials: true,
            credentials: "include",
            body: JSON.stringify({...params})
        })
        const response = await res.json() 
        store.dispatch(subtractLoading())
        return response
    } catch (error) {
        store.dispatch(subtractLoading())
    }
}

export const appPatch = async(url, params) => {
    store.dispatch(addLoading())
    try {
        let res = await fetch(`${base}${url}`,
        {
            method: "PATCH",
            headers: HEADERS,
            withCredentials: true,
            credentials: "include",
            body: JSON.stringify({...params})
        })
        const response = await res.json()
        store.dispatch(subtractLoading()) 
        return response
   } 
    catch (error) {
        store.dispatch(subtractLoading())
    }
}

export const appPut = async(url, params) => {
    store.dispatch(addLoading())
    try {
        let res = await fetch(`${base}${url}`,
        {
            method: "PUT",
            headers: HEADERS,
            withCredentials: true,
            credentials: "include",
            body: JSON.stringify({...params})
        })
        const response = await res.json() 
        store.dispatch(subtractLoading())
        return response
   } 
    catch (error) {
        store.dispatch(subtractLoading())
    }
}


export const appGet = async(url, params) => {
    store.dispatch(addLoading())
    let searchString = `${base}${url}`
    try {
     //   let keys = []
        if (params){
     //       keys = Object.keys(params)
            let vals = Object.values(params)
            for(let i = 0; i < vals.length; i++){
                searchString=searchString + "/" + vals[i]
            }
        }
        let res = await fetch(searchString,
        {
            method: "GET",
            headers: HEADERS,
            credentials: "include",
            withCredentials: true
        })
            const response = await res.json() 
            store.dispatch(subtractLoading())
            return response  
    } catch (error) {
        store.dispatch(subtractLoading())
    }
}

export const appDelete = async(url, params) => {
    let searchString = `${base}${url}`
    store.dispatch(addLoading())
    try {
      //  let keys = []
        if (params){
      //      keys = Object.keys(params)
            let vals = Object.values(params)
            for(let i = 0; i < vals.length; i++){
                searchString=searchString + "/" + vals[i]
            }
        }
        let res = await fetch(searchString,
        {
            method: "DELETE",
            headers: HEADERS,
            credentials: "include",
            withCredentials: true
        })
            const response = await res.json() 
            store.dispatch(subtractLoading())
            return response  
    } catch (error) {
        store.dispatch(subtractLoading())
    }
}

export const appFormData = async(url, params) => {
    store.dispatch(addLoading())
    try {
        const formData  = new FormData();
    
        for(const prop in params) {
        formData.append(prop, params[prop]);
        }
    
        const res = await fetch(`${base}${url}`, {
        method: "POST",
        headers: {'Accept': 'application/json'},
        withCredentials: true,
        credentials: "include",
        
        body: formData
        });

        const response = await res.json() 
        store.dispatch(subtractLoading())
        return response 
    }catch (error) {
        store.dispatch(subtractLoading()) 
    }
}

