import { useState } from "react";
import Modal from "../Modal/Modal";

interface SelectProps {
  label: string;
  value: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  func: Function;
  modalInfo?: any;
}

const FormCheckBox = (props: SelectProps) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="forms__field forms__flex forms__field--inline">
        <label htmlFor={props.modalInfo ? "" : props.label}>
          {props.modalInfo ? (
            <a
              onClick={() => setShowModal(true)}
              className="forms__field--link"
            >
              {props.label + (props.required ? " *" : "")}
            </a>
          ) : (
            props.label + (props.required ? " *" : "")
          )}
        </label>
        <input
          key={props.label + props.value}
          id={props.label}
          type="checkbox"
          checked={props.value}
          onChange={(e) => props.func(e.target.checked)}
          // onClick={() => {
          //   props.func(!props.value);
          // }}
        />
      </div>
      <Modal
        title="Terms, Conditions and Other Policies"
        show={showModal}
        setShow={setShowModal}
      >
        {props.modalInfo}
      </Modal>
    </>
  );
};

export default FormCheckBox;
