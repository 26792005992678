import FormInnerPlain from "../Forms/FormButtonPlain";
import FormInnerButton from "../Forms/FormInnerButton";

interface FilterContainerProps {
  children?: React.ReactNode;
  buttonFunc?: Function;
  fill?: boolean;
}

const FilterContainer = (props: FilterContainerProps) => {
  return (
    <div className="filter-container__container">
      <div
        className={
          !props.fill
            ? "filter-container"
            : "filter-container filter-container__fill"
        }
      >
        {props.children}{" "}
      </div>
      {props.buttonFunc && (
        <div className="filter-container__button">
          <FormInnerButton func={props.buttonFunc} title="Submit" />
        </div>
      )}
    </div>
  );
};

export default FilterContainer;
