import { useEffect, useState } from "react";
import { changeBookingEmail, getBooking } from "../../API/bookingApi";
import { formatDateForClient } from "../../Helpers/dateHelpers";
import { BookingInterface } from "../../Interfaces/OfferInterface";
import FormField from "../Forms/FormField";
import InfoDisplay from "../Forms/InfoDisplay";

interface BookingDetailsProps {
  bookProp: BookingInterface;
  viewOnly?: boolean;
  cb?: Function;
}

const BookingDetails = (props: BookingDetailsProps) => {
  const [email, setEmail] = useState("");
  const [changeEmailClick, setChangeEmailClick] = useState(false);
  const [changeEmailError, setChangeEmailError] = useState("");
  const [el, setEl] = useState<BookingInterface>(props.bookProp);
  const [searchError, setSearchError] = useState("");

  let { viewOnly } = props;

  useEffect(() => {
    if (!viewOnly) {
      findBooking();
    }
  }, []);

  const findBooking = async () => {
    setSearchError("");
    const res = await getBooking({ Booking_ID: props.bookProp.ID });
    if (res.error) {
      setSearchError("Search Failed");
      // This should never happen
      return;
    }
    setEl(res.booking);
  };

  const updateEmail = async (id: number) => {
    setChangeEmailError("");
    const res = await changeBookingEmail({
      Booking_ID: id,
      Filled_By_Email: email,
    });
    if (res.error) {
      setChangeEmailError("Change Failed");
    } else {
      findBooking();
      setChangeEmailClick(false);
      if (props.cb) props.cb();
    }
  };

  return (
    <div key={el.ID} className={`manage-offers__list`}>
      {searchError && <div className="signup__error">{searchError}</div>}
      <InfoDisplay label={"Booking ID: "} info={el.ID} column />
      <InfoDisplay
        label={"Position: "}
        info={el.Position?.Position_Name}
        column
      />
      <InfoDisplay label={"Name: "} info={el.Filled_By_Name} column />
      <div>
        {changeEmailClick && !viewOnly ? (
          <div>
            <FormField
              label="email"
              placeholder="email"
              value={email}
              func={setEmail}
              required={true}
              type="email"
              disabled={false}
              // error={formErrors.businessName}
            />

            <div className="flex justify-space-between">
              <div
                className="link-text color-danger"
                //"manage-offers__clicker"
                onClick={() => {
                  setChangeEmailClick(!changeEmailClick);
                }}
              >
                cancel
              </div>
              <div
                className="link-text"
                //"manage-offers__clicker"
                onClick={() => {
                  updateEmail(el.ID);
                }}
              >
                submit change
              </div>
            </div>
            {changeEmailError && (
              <div className="text-danger text-center">{changeEmailError}</div>
            )}
          </div>
        ) : (
          <div>
            <InfoDisplay label={"Email: "} info={el.Filled_By_Email} column />
            {el.Paid && !viewOnly && (
              <div
                className="link-text"
                //"manage-offers__clicker"
                onClick={() => {
                  setChangeEmailClick(!changeEmailClick);
                }}
              >
                change
              </div>
            )}
          </div>
        )}
      </div>
      <InfoDisplay label={"Paying Email: "} info={el.Purchase_Email} column />
      <InfoDisplay label={"Phone: "} info={el.Phone} column />
      <InfoDisplay label={"Paid: "} info={el.Paid ? "Yes" : "No"} column />
      <InfoDisplay
        label={"Received: "}
        info={`$${
          el.Amount_Received ? (el.Amount_Received / 100).toFixed(0) : 0
        }`}
        column
      />
      <InfoDisplay label={"Price: "} info={"$" + el.Price_Paid} column />
      <InfoDisplay
        label={"Cancelled: "}
        info={el.Cancelled ? "Yes" : "No"}
        column
      />
      <InfoDisplay label={"Refunded: "} info={"$" + el.Refunded / 100} column />
      <InfoDisplay
        label={"Created: "}
        info={formatDateForClient(el.CreatedAt)}
        column
      />
      {el.CustomFormFieldOne && (
        <InfoDisplay
          label={el.CustomFormFieldOne + ": "}
          info={el.CustomFormFieldOneValue}
          column
        />
      )}
      {el.CustomFormFieldTwo && (
        <InfoDisplay
          label={el.CustomFormFieldTwo + ": "}
          info={el.CustomFormFieldTwoValue}
          column
        />
      )}
      {el.CustomFormFieldThree && (
        <InfoDisplay
          label={el.CustomFormFieldThree + ": "}
          info={el.CustomFormFieldThreeValue}
          column
        />
      )}
      {el.CustomFormFieldFour && (
        <InfoDisplay
          label={el.CustomFormFieldFour + ": "}
          info={el.CustomFormFieldFourValue}
          column
        />
      )}
      {el.CustomFormFieldFive && (
        <InfoDisplay
          label={el.CustomFormFieldFive + ": "}
          info={el.CustomFormFieldFiveValue}
          column
        />
      )}

      <InfoDisplay label={"Info: "} info={el.Info} column />
    </div>
  );
};

export default BookingDetails;
