import { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { createLeader, editLeader, removeLeader } from "../../API/leaderApi";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { updateUserLeaders } from "../../Redux/UserReducer";
import FormField from "../Forms/FormField";
import FormInnerButton from "../Forms/FormInnerButton";

interface OfferLeaderFormProps {
  newLeader: boolean;
  back: Function;
}

interface OfferErrors {
  name: string;
  description: string;
  image: string;
}

const OfferLeaderForm = (props: OfferLeaderFormProps) => {
  const emptyOffer = {
    name: "",
    description: "",
    image: "",
  };

  const leaderInfo = useAppSelector((state) => state.leader);
  const [name, setName] = useState(leaderInfo.name || "");
  const [description, setDescription] = useState(leaderInfo.description || "");
  const image = leaderInfo.image || "";
  //const [active, setActive] = useState(leaderInfo.active || false);

  const [formErrors, setFormErrors] = useState<OfferErrors>({
    ...emptyOffer,
  });

  const [errors, setErrors] = useState("");

  const dispatch = useAppDispatch();

  const submitFunction = async () => {
    let res;
    try {
      if (props.newLeader) {
        let stop = checkLeader();
        if (stop) return;
        res = await createLeader({
          name,
          description,
          image,
        });
      } else {
        res = await editLeader({
          name,
          description,
          image,
          id: leaderInfo.id,
        });
      }
      if (res.error) throw new Error();
      dispatch(updateUserLeaders(res));
      props.back(true);
    } catch (err) {
      setErrors("An error has occured. Please try again later");
    }
  };

  const deleteLeader = async () => {
    try {
      const res = await removeLeader({ id: leaderInfo.id });
      dispatch(updateUserLeaders(res));
      props.back(true);
    } catch (error) {
      setErrors("An error has occured. Please try again later");
    }
  };

  const checkLeader = () => {
    let errorUpdate: OfferErrors = {
      ...emptyOffer,
    };
    if (!name) errorUpdate.name = "Required field";

    setFormErrors(errorUpdate);

    let prop: string;
    for (prop in errorUpdate) {
      if (errorUpdate[prop as keyof OfferErrors]) return true;
    }
    setFormErrors(emptyOffer);
  };

  return (
    <div className="address__comp--form">
      <div className="forms__group">
        <FormField
          label="Name"
          type="text"
          placeholder="Big bird"
          value={name}
          func={setName}
          disabled={false}
          required={true}
          error={formErrors.name}
        />
        <FormField
          label="Description"
          placeholder="Short description if needed"
          name="Description"
          value={description}
          func={setDescription}
          type="text"
          disabled={false}
          required={true}
          textArea={true}
        />
      </div>
      <div className="forms__title mb-1">Description Preview</div>
      <div className="offer-info__lead-modal ">
        <ReactMarkdown className="mark-down" linkTarget="_blank">
          {description}
        </ReactMarkdown>
      </div>
      <div className="forms__button-container">
        <FormInnerButton func={() => deleteLeader()} title="Remove Leader" />
        <FormInnerButton func={() => submitFunction()} title="Submit" primary />
      </div>
      {errors && <div className="signup__error">{errors}</div>}
    </div>
  );
};

export default OfferLeaderForm;
