import { createSlice } from "@reduxjs/toolkit";

const LoadingSlice = createSlice({
  name: "Loading",
  initialState: {
    loading: 0,
  },
  reducers: {
    addLoading(state) {
      state.loading = state.loading + 1;
    },
    subtractLoading(state) {
      state.loading = state.loading - 1;
    },
  },
});

export const { addLoading, subtractLoading } = LoadingSlice.actions;
export default LoadingSlice.reducer;
