import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { resetLeader } from "../../Redux/LeaderReducer";
import OfferLeaderEdit from "./OfferLeaderEdit";

interface OfferLeaderProps {
  inline?: boolean;
}

const OfferLeader = (props: OfferLeaderProps) => {
  const { name } = useAppSelector((state) => state.leader);
  const [showModal, setShowModal] = useState(false);
  const [newLeader, setNewLeader] = useState(false);
  const [success, setSuccess] = useState("");
  const dispatch = useAppDispatch();

  const onCloseModal = (showSuccess: boolean = false) => {
    setShowModal(false);
    setNewLeader(false);
    dispatch(resetLeader());
    if (showSuccess) setSuccess("Leader Update Successful");
    else setSuccess("");
  };

  const onBackClick = (showSuccess: boolean = false) => {
    setNewLeader(false);
    dispatch(resetLeader());
    if (showSuccess) setSuccess("Leader Update Successful");
    else setSuccess("");
  };
  return (
    <>
      {props.inline ? (
        <span
          className="forms__field--link"
          onClick={() => {
            setShowModal(!showModal);
            setSuccess("");
          }}
        >
          View/Edit Leaders
        </span>
      ) : (
        <div
          className="address__comp--link"
          onClick={() => {
            setShowModal(!showModal);
            setSuccess("");
          }}
        >
          View/Edit Leaders
        </div>
      )}
      <OfferLeaderEdit
        newLeader={newLeader}
        name={name}
        onBackClick={onBackClick}
        showModal={showModal}
        onCloseModal={onCloseModal}
        setNewLeader={setNewLeader}
        success={success}
      />
    </>
  );
};

export default OfferLeader;
