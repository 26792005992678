import { format, getDay, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import {
  addDay,
  addMinute,
  addMonth,
  addTwoWeeks,
  addWeek,
  getNow,
  timeZones,
} from "../../Helpers/dateHelpers";
import { SingleDate } from "../../Interfaces/OfferInterface";
import FormCheckBox from "./FormCheckBox";
import FormInnerButton from "./FormInnerButton";
import FormSelect from "./FormSelect";

type dateFunc = (dateObj: SingleDate[]) => void;

interface DateTimeProps {
  label: string;
  dates: SingleDate[];
  timeZone: string;
  addDate: Function;
  setTimeZone: React.Dispatch<React.SetStateAction<string>>;
  func: dateFunc;
  editDate?: SingleDate;
  setEditDate: React.Dispatch<React.SetStateAction<SingleDate>>;
}

const FormDateTime = (props: DateTimeProps) => {
  let dateString = getNow();

  const [localDate, setLocalDate] = useState(dateString);
  const [duration, setDuration] = useState(60);
  const [repeat, setRepeat] = useState("never");

  const [addAnotherDayOne, setAddAnotherDayOne] = useState(false);
  const [addAnotherDayTwo, setAddAnotherDayTwo] = useState(false);

  const [localDateTwo, setLocalDateTwo] = useState(dateString);
  const [durationTwo, setDurationTwo] = useState(60);

  const [localDateThree, setLocalDateThree] = useState(dateString);
  const [durationThree, setDurationThree] = useState(60);

  const [localDateEnd, setLocalDateEnd] = useState(addWeek(dateString));
  const [durationEnd, setDurationEnd] = useState(60);

  const [generateDate, setGenerateDate] = useState(true);
  const [dateError, setDateError] = useState("");

  useEffect(() => {
    if (generateDate) {
      props.setEditDate({
        duration: 0,
        startSession: "",
        endSession: "",
      });
      setLocalDate(dateString);
      setDuration(60);
    }
  }, [generateDate]);

  useEffect(() => {
    if (repeat === "never") {
      setAddAnotherDayOne(false);
      setAddAnotherDayTwo(false);
      setLocalDateTwo(dateString);
      setLocalDateThree(dateString);
      setDurationTwo(60);
      setDurationThree(60);
    } else {
      setLocalDateEnd(addMonth(localDate));
    }
  }, [repeat]);

  useEffect(() => {
    if (props.editDate?.localId || props.editDate?.localId === 0) {
      setLocalDate(
        format(parseISO(props.editDate.startSession), "yyyy-MM-dd'T'HH:mm")
      );
      setDuration(props.editDate.duration);
      setGenerateDate(false);
    } else {
      setLocalDate(dateString);
      setDuration(60);
    }
  }, [props.editDate?.localId]);

  useEffect(() => {
    setDateError("");
  }, [props.dates]);

  useEffect(() => {
    if (!addAnotherDayOne) setLocalDateTwo("");
    if (!addAnotherDayTwo) setLocalDateThree("");
  }, [addAnotherDayOne, addAnotherDayTwo]);

  // max
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
  let yearAway = aYearFromNow.toISOString().slice(0, -8);

  const createDateArr = () => {
    switch (repeat) {
      case "never":
        neverDateCreator();
        break;
      case "weekly":
        weeklyDateCreator();
        break;
      case "bi-weekly":
        biWeeklyDateCreator();
        break;
      case "daily":
        dailyDateCreator();
        break;
      case "daily-no-weekend":
        dailyNoWeekendDateCreator();
        break;
      default:
        break;
    }
  };

  const editFinished = () => {
    const id = props.editDate?.localId;
    const dateEdited = getDateTimeAndDuration(localDate, duration);
    dateEdited.localId = id!;
    props.setEditDate({
      duration: 0,
      startSession: "",
      endSession: "",
    });
    let dateArr = [...props.dates];
    for (let i = 0; i < dateArr.length; i++) {
      if (dateArr[i].localId === id) {
        dateArr[i] = dateEdited;
        break;
      }
    }
    props.func(dateArr);
  };

  const addDate = () => {
    for (let i = 0; i < props.dates.length; i++) {
      if (props.dates[i].startSession === localDate) {
        setDateError("This date already exists");
        return;
      }
    }
    props.addDate(getDateTimeAndDuration(localDate, duration));
  };

  const biWeeklyDateCreator = () => {
    const dateArr = [];
    let local = localDate,
      local2 = localDateTwo,
      local3 = localDateThree;
    if (local === local2) local2 = "";
    if (local === local3) local3 = "";
    if (local2 === local3) local3 = "";

    while (local < localDateEnd) {
      dateArr.push(getDateTimeAndDuration(local, duration));
      local = addTwoWeeks(local);
      if (local2 && local2 < localDateEnd) {
        dateArr.push(getDateTimeAndDuration(local2, duration));
        local2 = addTwoWeeks(local2);
      }
      if (local3 && local3 < localDateEnd) {
        dateArr.push(getDateTimeAndDuration(local3, duration));
        local3 = addTwoWeeks(local3);
      }
    }
    dateArr.push(getDateTimeAndDuration(localDateEnd, durationEnd));
    props.func(dateArr);
  };

  const dailyDateCreator = () => {
    let local = localDate,
      dateArr = [];
    while (local < localDateEnd) {
      dateArr.push(getDateTimeAndDuration(local, duration));
      local = addDay(local);
    }
    dateArr.push(getDateTimeAndDuration(localDateEnd, durationEnd));
    props.func(dateArr);
  };

  const dailyNoWeekendDateCreator = () => {
    let local = localDate,
      dateArr = [];
    while (local < localDateEnd) {
      if (isWeekDay(local)) {
        dateArr.push(getDateTimeAndDuration(local, duration));
        local = addDay(local);
      } else local = addDay(local);
    }
    dateArr.push(getDateTimeAndDuration(localDateEnd, durationEnd));
    props.func(dateArr);
  };

  const isWeekDay = (date: string) => {
    const dayNum = getDay(parseISO(date));
    if (dayNum === 0 || dayNum === 6) return false;
    return true;
  };
  //{getDay(parseISO(data[index].startSession))}

  const weeklyDateCreator = () => {
    const dateArr = [];
    let local = localDate,
      local2 = localDateTwo,
      local3 = localDateThree;
    if (local === local2) local2 = "";
    if (local === local3) local3 = "";
    if (local2 === local3) local3 = "";

    while (local < localDateEnd) {
      dateArr.push(getDateTimeAndDuration(local, duration));
      local = addWeek(local);
      if (local2 && local2 < localDateEnd) {
        dateArr.push(getDateTimeAndDuration(local2, durationTwo));
        local2 = addWeek(local2);
      }
      if (local3 && local3 < localDateEnd) {
        dateArr.push(getDateTimeAndDuration(local3, durationThree));
        local3 = addWeek(local3);
      }
    }
    dateArr.push(getDateTimeAndDuration(localDateEnd, durationEnd));
    props.func(dateArr);
  };

  const neverDateCreator = () => {
    const dateArr = [];
    let curDate = getDateTimeAndDuration(localDate, duration);
    dateArr.push(curDate);
    props.func(dateArr);
  };

  const getDateTimeAndDuration = (date: string, duration: number) => {
    return {
      startSession: date,
      endSession: addMinute(date, duration),
      duration: duration,
      localId: 0,
    };
  };

  const repeatSelect = (func: Function, value: string) => {
    return (
      <select onChange={(e) => func(e.target.value)} value={value}>
        <option key={5} id={"e5"} value={"never"}>
          Never
        </option>
        <option key={1} id={"e1"} value={"weekly"}>
          Weekly
        </option>
        <option key={6} id={"e6"} value={"bi-weekly"}>
          Bi-Weekly
        </option>
        <option key={2} id={"e2"} value={"daily"}>
          Daily
        </option>
        <option key={3} id={"e3"} value={"daily-no-weekend"}>
          Daily only weekdays
        </option>
      </select>
    );
  };

  const durationSelect = (func: Function, value: number) => {
    let op = [];
    for (let i = 1; i < 96; i++) {
      let hours = Math.floor((i * 15) / 60);
      if (!hours) hours = 0;
      let minutes: string | number = (i * 15) % 60;
      if (!minutes) minutes = "00";
      op.push(
        <option key={i} id={`o${i}`} value={i * 15}>
          {`${hours}:${minutes}`}
        </option>
      );
    }
    return (
      <select
        onChange={(e) => {
          func(parseInt(e.target.value));
        }}
        value={value}
      >
        {op}
      </select>
    );
  };

  return (
    <>
      <div className="forms__toggle">
        <div
          onClick={() => setGenerateDate(true)}
          className={`forms__toggle--button ${
            generateDate ? "forms__toggle--button--selected" : ""
          }`}
        >
          Date Generator
        </div>
        <div
          className={`forms__toggle--button ${
            !generateDate ? "forms__toggle--button--selected" : ""
          }`}
          onClick={() => setGenerateDate(false)}
        >
          Custom Dates
        </div>
      </div>
      {generateDate ? (
        <>
          <div className="forms__group--fit">
            <div className="forms__field">
              <FormSelect
                options={timeZones.sort((a, b) => a.localeCompare(b))}
                value={props.timeZone}
                func={props.setTimeZone}
                label="Time Zone (for all offer dates)"
                required={false}
              />
              <div className="forms__field forms__flex forms__flex--column">
                <label className="forms__field--margin">Start Date</label>
                <input
                  type="datetime-local"
                  id={props.label}
                  name={props.label}
                  value={localDate}
                  max={repeat !== "never" ? localDateEnd : yearAway}
                  onChange={(e) => setLocalDate(e.target.value)}
                />
              </div>
              <div className="forms__field forms__flex forms__flex--column">
                <label className="forms__field--margin">Duration</label>
                {durationSelect(setDuration, duration)}
                <div className="forms__field forms__flex forms__flex--column">
                  <label className="forms__field--margin">Repeats</label>
                  {repeatSelect(setRepeat, repeat)}
                </div>
                {(repeat === "weekly" || repeat === "bi-weekly") && (
                  <div className="forms__field forms__flex forms__flex--column forms__field--margin">
                    <FormCheckBox
                      label={"Add Another Day"}
                      value={addAnotherDayOne}
                      func={setAddAnotherDayOne}
                    />
                  </div>
                )}
              </div>
            </div>

            {addAnotherDayOne && (
              // <div className="forms__group--fit">
              <div className="forms__field">
                <label>Second Weekly Date or Time</label>
                <input
                  type="datetime-local"
                  value={localDateTwo}
                  max={localDateEnd}
                  onChange={(e) => setLocalDateTwo(e.target.value)}
                />
                <div className="forms__field forms__flex forms__flex--column">
                  <label className="forms__field--margin">Duration</label>
                  {durationSelect(setDurationTwo, durationTwo)}
                  {(repeat === "weekly" || repeat === "bi-weekly") &&
                    localDateTwo && (
                      <div className="forms__field forms__flex forms__flex--column forms__field--margin">
                        <FormCheckBox
                          label={"Add Another Day"}
                          value={addAnotherDayTwo}
                          func={setAddAnotherDayTwo}
                        />
                      </div>
                    )}
                </div>
              </div>
              // </div>
            )}

            {addAnotherDayTwo && (
              // <div className="forms__group--fit">
              <div className="forms__field">
                <label>Third Weekly Date or Time</label>
                <input
                  type="datetime-local"
                  value={localDateThree}
                  max={localDateEnd}
                  onChange={(e) => setLocalDateThree(e.target.value)}
                />
                <div className="forms__field forms__flex forms__flex--column">
                  <label className="forms__field--margin">Duration</label>
                  {durationSelect(setDurationThree, durationThree)}
                </div>
              </div>
              // </div>
            )}

            {repeat !== "never" && (
              // <div className="forms__group--fit forms__group--color">
              <div className="forms__field forms__group--color">
                <label>End Date</label>
                <input
                  type="datetime-local"
                  id={props.label}
                  name={props.label}
                  value={localDateEnd}
                  max={yearAway}
                  onChange={(e) => setLocalDateEnd(e.target.value)}
                />
                <div className="forms__field forms__flex forms__flex--column">
                  <label className="forms__field--margin">Duration</label>
                  {durationSelect(setDurationEnd, durationEnd)}
                </div>
              </div>
              // </div>
            )}
          </div>
          <FormInnerButton
            title="Generate Dates"
            func={createDateArr}
            disabled={
              repeat !== "never" && localDate === localDateEnd ? true : false
            }
          />
        </>
      ) : (
        <>
          {props.editDate?.startSession && (
            <div className="forms__title">Editing Date</div>
          )}
          <div className="forms__group--fit">
            <div className="forms__field">
              <FormSelect
                options={timeZones}
                value={props.timeZone}
                func={props.setTimeZone}
                label="Time Zone (for all offer dates)"
                required={false}
              />
              <div className="forms__field forms__flex forms__flex--column">
                <label className="forms__field--margin">Start Date</label>
                <input
                  type="datetime-local"
                  id={props.label}
                  name={props.label}
                  value={localDate}
                  max={repeat !== "never" ? localDateEnd : yearAway}
                  onChange={(e) => setLocalDate(e.target.value)}
                />
              </div>
              <div className="forms__field forms__flex forms__flex--column">
                <label className="forms__field--margin">Duration</label>
                {durationSelect(setDuration, duration)}
              </div>
            </div>
          </div>
          <FormInnerButton
            title={
              props.editDate?.localId || props.editDate?.localId === 0
                ? "Commit Edit"
                : "Create Date"
            }
            func={props.editDate?.startSession ? editFinished : addDate}
            disabled={false}
          />
        </>
      )}
      <span className="forms__field--error forms__field--error--center">
        {dateError}
      </span>
    </>
  );
};

export default FormDateTime;
