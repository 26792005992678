import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface CheckProps {
  check: boolean;
  minus?: boolean;
}

const Check = (props: CheckProps) => {
  if (props.minus)
    return (
      <div className="check--minus">
        <FontAwesomeIcon
          icon={icon({ name: "square-minus", style: "solid" })}
        />
      </div>
    );
  else if (props.check)
    return (
      <div className="check">
        <FontAwesomeIcon
          icon={icon({ name: "square-check", style: "solid" })}
        />
      </div>
    );
  else
    return (
      <div className="check--x">
        <FontAwesomeIcon
          icon={icon({ name: "square-xmark", style: "solid" })}
        />
      </div>
    );
};

export default Check;
