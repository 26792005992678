import { useEffect, useState } from "react";

const useDebounce = (val: string, delay: number) => {
  const [updatedVal, setUpdatedVal] = useState("");

  useEffect(() => {
    const doBounce = setTimeout(() => {
      setUpdatedVal(val);
    }, delay);
    return () => {
      clearTimeout(doBounce);
    };
  }, [val, delay]);

  return updatedVal;
};

export default useDebounce;
