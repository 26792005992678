import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCtaInfo,
  getCustomFormFields,
  getOfferById,
} from "../../API/offersApi";
import {
  BookingInterface,
  OfferInterface,
} from "../../Interfaces/OfferInterface";
import Calendar from "../Calendar/Calendar";
import FormInnerPlain from "../Forms/FormButtonPlain";
import FormInnerButton from "../Forms/FormInnerButton";

interface PaymentSuccessProps {
  offerId?: string;
  booking?: BookingInterface;
  invoiceId: string;
}

const PaymentSuccess = (props: PaymentSuccessProps) => {
  const [off, setOff] = useState<OfferInterface>();
  const [errorMessage, setErrorMessage] = useState("");
  const [ctaOneTitle, setCtaOneTitle] = useState("");
  const [ctaOneLink, setCtaOneLink] = useState("");

  useEffect(() => {
    if (props.offerId) {
      getOffer();
    } else {
      setErrorMessage(
        "Payment seems to have been successful but the offer cannot be found. Please contact Help@threadbooking.com"
      );
    }
  }, []);

  const navigate = useNavigate();

  const getOffer = async () => {
    const offer = await getOfferById({ id: props.offerId });
    if (offer.offers?.length > 0) {
      setOff(offer.offers[0]);
      const res = await getCtaInfo({
        id: props.offerId,
        invoideId: props.invoiceId,
      });
      if (res.ctaText) {
        setCtaOneTitle(res.ctaText);
        setCtaOneLink(res.ctaUrl);
      }
    }
  };

  const handleClick = () => {
    if (off) navigate(`../forum/${off.ID}`);
  };

  const handleClickCta = () => {
    window.open(ctaOneLink, "_blank");
  };

  return (
    <>
      {errorMessage && <div className="signup__error">{errorMessage}</div>}
      <h1 className="signup-page__title">Success!</h1>
      <div className="title">Thank you for booking</div>
      <div className="p-2 ">
        <p className="text-center font-size-14">
          You have booked {off?.Name}. You can add the offers dates to your
          google calendar
        </p>
        <div className="mt-3">
          <Calendar offer={off} />
        </div>
        {ctaOneLink && ctaOneTitle && (
          <div className="mt-3">
            <FormInnerPlain func={handleClickCta} title={ctaOneTitle} primary />
          </div>
        )}
      </div>
      <div className="title">Next Step</div>
      <div className="p-2">
        <p className="text-center font-size-14">
          The offers forum will have information about your offer such as
          private links to resources supplied by the offerer and announcements
        </p>
        <p className="text-center font-size-14 mt-2">
          The booking has been linked to your email. If you do not have an
          account, please sign up for an account using your booking email to
          gain access to the offers forum
        </p>
        <div>
          <FormInnerButton
            func={handleClick}
            title={`${off?.Name} Forum`}
            primary
          />
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
