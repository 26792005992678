import ListContainer from "../Common/ListContainer";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PositionsInterface } from "../../Interfaces/OfferInterface";

interface PositionsListProps {
  list: PositionsInterface[];
  func: Function;
  edit: number;
  deleteFunc: Function;
}

const PositionsList = (props: PositionsListProps) => {
  const positionsList = props.list.map((el, i) => {
    return (
      <div
        className={
          i % 2 === 0
            ? `list__item list__item--dark ${
                props.edit !== -1 && props.edit === el.Local_Id
                  ? "list__item--highlight"
                  : ""
              }`
            : `list__item ${
                props.edit !== -1 && props.edit === el.Local_Id
                  ? "list__item--highlight"
                  : ""
              }`
        }
        onClick={() => {
          if (props.func) {
            props.func(el.Local_Id);
            // if (edit === el.Local_Id) {
            //   setEdit(-1);
            // } else setEdit(el.Local_Id);
          }
        }}
        key={i}
      >
        <div>
          Name:{" "}
          <span className="positions-list--value">{el.Position_Name}</span>
        </div>

        <div>
          Price:{" "}
          <span className="positions-list--value">
            {el.Price_Override !== "-1" &&
            el.Price_Override !== "0" &&
            parseInt(el.Price_Override) !== -1
              ? `$${el.Price_Override}`
              : el.Price_Override === "0"
              ? "FREE"
              : "Offer Price"}
          </span>
        </div>
        <div>
          Available:{" "}
          <span className="positions-list--value">
            {parseInt(el.Amount_Available) - parseInt(el.Amount_Filled)}
          </span>
        </div>
        <div className="list__item--tools">
          <span
            className="list__item--tools--x"
            onClick={(e) => props.deleteFunc(e, el.Local_Id)}
          >
            <FontAwesomeIcon
              icon={icon({ name: "circle-xmark", style: "solid" })}
            />
          </span>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="forms__title--subtitle forms__title--margin">
        Positions List
      </div>
      <ListContainer>{positionsList}</ListContainer>
      <div className="forms__title--margin" />
    </>
  );
};

export default PositionsList;
