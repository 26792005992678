import { useEffect, useState } from "react";
import { createPaymentIntent } from "../../API/stripeApi";
import CheckoutStripe from "../../Components/Checkout/CheckoutStripe";
import FormField from "../../Components/Forms/FormField";
import FormSubmitButton from "../../Components/Forms/FormSubmitButton";
import Modal from "../../Components/Modal/Modal";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import OfferInfo from "../OfferDisplay/OfferInfo";
import {
  buildTermsAndConditions,
  dateInterfaceToSingleDateArr,
  durationMaker,
} from "../../Helpers/offerHelpers";
import { minutesToFormattedTime } from "../../Helpers/dateHelpers";
import { getAddressesById } from "../../API/addressApi";
import { AddressInterface } from "../../Interfaces/AddressInterface";
import { getCustomFormFields, getOfferById } from "../../API/offersApi";
import { clickedOffer } from "../../Redux/OfferReducer";
import { emptyPosition } from "../../Interfaces/OfferInterface";
import { useNavigate, useParams } from "react-router-dom";
import FormCheckBox from "../../Components/Forms/FormCheckBox";
import FormPositionSelector from "../../Components/Forms/FormPositionSelector";
import { makeAddressString } from "../../Helpers/addressHelpers";
import FormInnerPlain from "../../Components/Forms/FormButtonPlain";
import { checkPromoCode } from "../../API/promoCodeApi";
import { PromoInterface } from "../../Interfaces/PromoInterfaces";

const CheckoutUserForm = () => {
  const offer = useAppSelector((state) => state.offer);
  const { Positions, ID, Address_ID, TermsAndConditions } = offer;

  let posFix = [...Positions].filter(
    (el) => parseInt(el.Amount_Available) - parseInt(el.Amount_Filled) > 0
  );

  const userInfo = useAppSelector((state) => state.user);
  const userEmail = userInfo.email;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState(emptyPosition);
  const [info, setInfo] = useState("");
  const [showCheckout, setShowCheckout] = useState(false);
  const [payIntent, setPayIntent] = useState("");
  const [connectedAccount, setConnectedAccount] = useState("");
  const [price, setPrice] = useState(-1);
  const [offerName, setOfferName] = useState("");
  const [positionName, setPositionName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState<AddressInterface>();
  const [terms, setTerms] = useState(false);
  const [webhook, setWebhook] = useState("");
  const [full, setFull] = useState(false);

  const [fieldOne, setFieldOne] = useState("");
  const [fieldOneVal, setFieldOneVal] = useState("");
  const [fieldOneReq, setFieldOneReq] = useState(false);
  const [fieldOneId, setFieldOneId] = useState(0);
  const [fieldTwo, setFieldTwo] = useState("");
  const [fieldTwoVal, setFieldTwoVal] = useState("");
  const [fieldTwoReq, setFieldTwoReq] = useState(false);
  const [fieldTwoId, setFieldTwoId] = useState(0);
  const [fieldThree, setFieldThree] = useState("");
  const [fieldThreeVal, setFieldThreeVal] = useState("");
  const [fieldThreeReq, setFieldThreeReq] = useState(false);
  const [fieldThreeId, setFieldThreeId] = useState(0);
  const [fieldFour, setFieldFour] = useState("");
  const [fieldFourVal, setFieldFourVal] = useState("");
  const [fieldFourReq, setFieldFourReq] = useState(false);
  const [fieldFourId, setFieldFourId] = useState(0);
  const [fieldFive, setFieldFive] = useState("");
  const [fieldFiveVal, setFieldFiveVal] = useState("");
  const [fieldFiveReq, setFieldFiveReq] = useState(false);
  const [fieldFiveId, setFieldFiveId] = useState(0);

  const [promoCode, setPromoCode] = useState("");
  const [promoCodeFound, setPromoCodeFound] = useState<PromoInterface>();
  const [showPromo, setShowPromo] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [promoError, setPromoError] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (posFix.length < 1) setFull(true);
    else {
      setPosition(posFix[0]);
      setFull(false);
    }
  }, [posFix]);

  let durationHours = 0,
    durationMinutes = 0,
    dur = { durationHours: 0, durationMinutes: 0 };

  if (offer.Dates?.length > 0) {
    dur = durationMaker(minutesToFormattedTime(offer.Dates[0].Duration));
  }

  if (dur.durationHours || dur.durationMinutes) {
    durationHours = dur.durationHours;
    durationMinutes = dur.durationMinutes;
  }

  const navigate = useNavigate();

  // Get offerid from params
  let param = useParams();

  useEffect(() => {
    if (param.id) getOffer(param.id);
  }, []);

  useEffect(() => {
    checkPromoCodeFunc();
  }, [position.ID]);

  const checkPromoCodeFunc = async () => {
    setPromoError("");
    try {
      const res = await checkPromoCode({
        offerId: ID,
        name: promoCode,
        positionId: position.ID,
      });
      if (res.price) {
        setPrice(res.price);
      }
      if (res.error) {
        setPromoError("Promo not found");
      }
      setPromoCodeFound(res.promoCode);
    } catch (error) {
      setPromoError("An error has occured");
    }
  };

  const getOffer = async (id: string) => {
    if (!id) return;
    const offerObj = await getOfferById({ id: id });
    dispatch(clickedOffer(offerObj.offers[0]));

    const res = await getCustomFormFields({ id: id });
    if (res.fields) {
      for (let i = 0; i < res.fields.length; i++) {
        if (i === 0) {
          setFieldOne(res.fields[i].Title);
          setFieldOneReq(res.fields[i].Required);
          setFieldOneId(res.fields[i].ID);
        }
        if (i === 1) {
          setFieldTwo(res.fields[i].Title);
          setFieldTwoReq(res.fields[i].Required);
          setFieldTwoId(res.fields[i].ID);
        }
        if (i === 2) {
          setFieldThree(res.fields[i].Title);
          setFieldThreeReq(res.fields[i].Required);
          setFieldThreeId(res.fields[i].ID);
        }
        if (i === 3) {
          setFieldFour(res.fields[i].Title);
          setFieldFourReq(res.fields[i].Required);
          setFieldFourId(res.fields[i].ID);
        }
        if (i === 4) {
          setFieldFive(res.fields[i].Title);
          setFieldFiveReq(res.fields[i].Required);
          setFieldFiveId(res.fields[i].ID);
        }
      }
    }
  };

  useEffect(() => {
    if (Address_ID) {
      getAddress();
    }
  }, [Address_ID]);

  const getAddress = async () => {
    const addressObj = await getAddressesById({ id: Address_ID });
    setAddress(addressObj.address);
  };

  const doCheckout = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!terms) {
      setErrorMessage("You must agree to terms and conditions before booking");
      return;
    }
    const res = await createPaymentIntent({
      offerId: ID,
      positionId: position.ID,
      email: userEmail !== "" ? userEmail : email,
      phone: phone,
      info: info,
      name: name,
      promoCode: promoCodeFound?.ID,
      fieldOne,
      fieldOneVal,
      fieldTwo,
      fieldTwoVal,
      fieldThree,
      fieldThreeVal,
      fieldFour,
      fieldFourVal,
      fieldFive,
      fieldFiveVal,
    });

    setWebhook(res.webhook);

    if (!res.freeCourse) {
      setPayIntent(res.ClientSecret);
      setConnectedAccount(res.userAccount);
      setPrice(res.price);
      setShowCheckout(true);
      setOfferName(res.offerName);
      setPositionName(res.positionName);
    } else {
      // Free booking for future implementation
    }
  };

  const selectPosition = (id: number) => {
    for (let i = 0; i < posFix.length; i++) {
      if (posFix[i].ID === id) {
        setPosition(posFix[i]);
        return;
      }
    }
  };

  let addressString = makeAddressString(address);

  if (!posFix || posFix?.length < 1) {
    return <div className="title">No Offer Selected</div>;
  }
  if (full) {
    return (
      <div className="header-text page-center color-danger">
        <div>No more positions available</div>
        <div className="subtitle-text color-dark mt-2">
          The offer is fully booked
        </div>
        <div className="link-text mt-2" onClick={() => navigate(`../`)}>
          Back to offers
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="title">Checkout</div>
      <form className="forms__form" onSubmit={(e) => doCheckout(e)}>
        <div className="forms__group">
          <FormField
            label="Name"
            placeholder="Name"
            value={name}
            func={setName}
            required={true}
            type="text"
            disabled={false}
            maxLength={100}
          />
          <FormField
            label="Email"
            placeholder="Email"
            value={userEmail !== "" ? userEmail : email}
            func={setEmail}
            required={true}
            type="text"
            disabled={userEmail !== "" ? true : false}
            maxLength={100}
          />
          <FormPositionSelector
            options={posFix}
            value={position.ID?.toString() || ""}
            func={selectPosition}
            label="Select Position"
            required={true}
          />
          <FormField
            label="Phone"
            type="tel"
            placeholder="212-333-4444"
            value={phone}
            func={setPhone}
            disabled={false}
            required={true}
          />
          {fieldOneId > 0 && (
            <FormField
              label={fieldOne}
              placeholder={fieldOneVal}
              value={fieldOneVal}
              func={setFieldOneVal}
              required={fieldOneReq}
              type="text"
              disabled={false}
              maxLength={100}
            />
          )}
          {fieldTwoId > 0 && (
            <FormField
              label={fieldTwo}
              placeholder={fieldTwoVal}
              value={fieldTwoVal}
              func={setFieldTwoVal}
              required={fieldTwoReq}
              type="text"
              disabled={false}
              maxLength={100}
            />
          )}
          {fieldThreeId > 0 && (
            <FormField
              label={fieldThree}
              placeholder={fieldThreeVal}
              value={fieldThreeVal}
              func={setFieldThreeVal}
              required={fieldThreeReq}
              type="text"
              disabled={false}
              maxLength={100}
            />
          )}
          {fieldFourId > 0 && (
            <FormField
              label={fieldFour}
              placeholder={fieldFourVal}
              value={fieldFourVal}
              func={setFieldFourVal}
              required={fieldFourReq}
              type="text"
              disabled={false}
              maxLength={100}
            />
          )}

          {fieldFiveId > 0 && (
            <FormField
              label={fieldFive}
              placeholder={fieldFiveVal}
              value={fieldFiveVal}
              func={setFieldFiveVal}
              required={fieldFiveReq}
              type="text"
              disabled={false}
              maxLength={100}
            />
          )}
          <FormField
            label="Anything we should know?"
            placeholder=""
            value={info}
            func={setInfo}
            type="text"
            maxLength={195}
            disabled={false}
            required={false}
            textArea={true}
          />
          {!showPromo && (
            <div
              className="forms__field--link"
              onClick={() => setShowPromo(true)}
            >
              Have a promo code?
            </div>
          )}
          {showPromo && (
            <div className="">
              <FormField
                label="Promo Code"
                placeholder="promocode10"
                value={promoCode}
                func={setPromoCode}
                required={false}
                type="text"
                disabled={false}
                maxLength={100}
              />
              <div className="mt-2 width">
                <button
                  className="width height-30"
                  onClick={(e) => {
                    e.preventDefault();
                    checkPromoCodeFunc();
                  }}
                >
                  Apply Promo Code
                </button>
                {promoError && (
                  <div className="signup__error">{promoError}</div>
                )}
                {promoCodeFound && (
                  <div className="signup__error color-primary">
                    Applied: {promoCodeFound.Name}
                  </div>
                )}
              </div>
            </div>
          )}
          <FormCheckBox
            label="Agree to terms and conditions"
            value={terms}
            func={setTerms}
            modalInfo={buildTermsAndConditions(
              TermsAndConditions?.Description
                ? TermsAndConditions.Description
                : ""
            )}
            required
          />
        </div>
        <OfferInfo
          offer={offer}
          offerId={offer.ID}
          lead={offer.Lead_ID}
          startDate={offer.Dates[0].Date_Time_Start}
          endDate={offer.Dates[offer.Dates.length - 1].Date_Time_Start}
          dateCount={offer.Dates.length}
          dates={dateInterfaceToSingleDateArr(offer.Dates)}
          durationHours={durationHours}
          durationMinutes={durationMinutes}
          price={offer.Price.toString()}
          name={offer.Name}
          address={addressString}
          description={offer.Description}
          positions={offer.Positions}
          buttonClick={false}
          removeButton
        />
        <div className="title font-size-18">Total: ${price}</div>
        <div className="mb-3">
          <FormSubmitButton title="Pay now" disabled={true} />
          <div className="signup__error">
            Sorry, access to Stripe's development servers are not allowed in an
            open demo like this one.
          </div>
          {errorMessage && <div className="signup__error">{errorMessage}</div>}
        </div>
      </form>
      {showCheckout && payIntent && connectedAccount && (
        <Modal
          title={"Checkout"}
          backButton={false}
          onBackButtonClick={() => setShowCheckout(false)}
          show={showCheckout}
          setShow={() => setShowCheckout(false)}
          noClickAway
        >
          <div className="title font-size-18">Stripe not enabled for demo</div>
          {/* <CheckoutStripe
            clientSeceret={payIntent}
            connectedAccount={connectedAccount}
            price={price}
            offerName={offerName}
            positionName={positionName}
            webhook={webhook}
            offerId={param.id ? param.id : ""}
          /> */}
        </Modal>
      )}
    </>
  );
};

export default CheckoutUserForm;
