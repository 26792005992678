import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../Redux/Hooks";
import { logoutUserThunk } from "../Redux/UserFuncs";
import { NavButtonProps } from "./Header";

const NavButton = (props: NavButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const doAction = () => {
    if (props.url === "logout") {
      dispatch(logoutUserThunk());
      navigate("../");
    } else navigate(props.url);
  };
  return (
    <div
      onClick={() => doAction()}
      className={props.invert ? "nav-button--invert" : "nav-button"}
    >
      {props.text}
    </div>
  );
};

export default NavButton;
