import { createSlice } from "@reduxjs/toolkit";

const AddressSlice = createSlice({
  name: "Address",
  initialState: {
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    county: "",
    state: "",
    country: "",
    zip: "",
    addressId: "",
    active: false,
    primary: false,
  },
  reducers: {
    clickedAddress(state, action) {
      state.addressLineOne = action.payload.Address_Line_One;
      state.addressLineTwo = action.payload.Address_Line_Two;
      state.city = action.payload.City;
      state.county = action.payload.County;
      state.state = action.payload.State;
      state.country = action.payload.Country;
      state.zip = action.payload.Zip;
      state.addressId = action.payload.ID;
      state.primary = action.payload.Is_Primary;
      state.active = action.payload.Active;
    },
    resetAddress(state) {
      state.addressLineOne = "";
      state.addressLineTwo = "";
      state.city = "";
      state.county = "";
      state.state = "";
      state.country = "";
      state.zip = "";
      state.addressId = "";
      state.primary = false;
      state.active = false;
    },
  },
});

export const { clickedAddress, resetAddress } = AddressSlice.actions;
export default AddressSlice.reducer;
