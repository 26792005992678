import { UserReducerInterface } from "../Redux/UserReducer";
import { PolicyInterface } from "./PolicyInterface";

export interface OfferInterface {
  ID: number;
  User_ID: number;
  Address_ID: number;
  Lead_ID: number;
  Name: string;
  Description: string;
  Amount_Available: number;
  Price: number;
  Book_After_Start_Date: boolean;
  Display_After_Start_Date: boolean;
  Group_Purchase: number;
  Form_Type: number;
  DiscountRate: number;
  DiscountDateStart: string;
  DiscountDateEnd: string;
  Image_Url: string;
  Image_Key: string;
  Active: boolean;
  Cancelled: boolean;
  TimeZone: string;
  Distance?: string;
  TermsAndConditions?: PolicyInterface;
  Start_Date?: string;
  End_Date?: string;

  Positions: PositionsInterface[];
  Dates: DateInterface[];
  Bookings: BookingInterface[];
  User?: UserForOfferInterface;
}

export interface BookingInterface {
  ID: number;
  Offer_ID: number;
  Position_ID: number;
  Filled_By_Email: string;
  Purchase_Email: string;
  Filled_By_Name: string;
  Phone: string;
  Price_Paid: number;
  Amount_Filled: number;
  Cancelled: boolean;
  Refunded: number;
  Webhook: string;
  Paid: boolean;
  Info: string;
  Invoice_Id: string;
  Amount_Received: number;
  Application_Fee_Amount: number;
  CustomFormFieldOne: string;
  CustomFormFieldOneValue: string;
  CustomFormFieldTwo: string;
  CustomFormFieldTwoValue: string;
  CustomFormFieldThree: string;
  CustomFormFieldThreeValue: string;
  CustomFormFieldFour: string;
  CustomFormFieldFourValue: string;
  CustomFormFieldFive: string;
  CustomFormFieldFiveValue: string;
  CreatedAt: string;
  Position: PositionsInterface;
  Offer: OfferInterface;
}

export interface UserForOfferInterface {
  Slug: string;
  Business_Name: string;
}

export interface OfferReq {
  name: string;
  addressId: string;
  description: string;
  price: string;
  bookAfterStartDate: boolean;
  displayAfterStartDate: boolean;
  active: boolean;
  lead: number;
  file: File;
  timeZone: string;
  termsAndConditionsId?: number;
  customOne?: string;
  customOneId?: number;
  customOneReq?: boolean;
  customTwo?: string;
  customTwoId?: number;
  customTwoReq?: boolean;
  customThree?: string;
  customThreeId?: number;
  customThreeReq?: boolean;
  customFour?: string;
  customFourId?: number;
  customFourReq?: boolean;
  customFive?: string;
  customFiveId?: number;
  customFiveReq?: boolean;
  ctaTitle?: string;
  ctaUrl?: string;
}

export interface OfferReqUpdate {
  id: number;
  name: string;
  addressId: string;
  description: string;
  price: string;
  bookAfterStartDate: boolean;
  displayAfterStartDate: boolean;
  active: boolean;
  lead: number;
  file: File | string;
  timeZone: string;
  termsAndConditionsId?: number;
  customOne?: string;
  customOneId?: number;
  customOneReq?: boolean;
  customTwo?: string;
  customTwoId?: number;
  customTwoReq?: boolean;
  customThree?: string;
  customThreeId?: number;
  customThreeReq?: boolean;
  customFour?: string;
  customFourId?: number;
  customFourReq?: boolean;
  customFive?: string;
  customFiveId?: number;
  customFiveReq?: boolean;
  ctaTitle?: string;
  ctaUrl?: string;
}

export interface OfferCompGiven {
  name: string;
  addyId: string;
  description: string;
  price: string;
  bookAfterStartDate: boolean;
  displayAfterStartDate: boolean;
  active: boolean;
  lead: number;
  position: PositionsInterface[];
  timeZone: string;
  dates: SingleDate[];
  imagePreview: string | ArrayBuffer | null;
}

export interface PositionsInterface {
  ID?: number;
  Offer_ID?: number;
  Local_Id: number;
  Position_Name: string;
  Description: string;
  Amount_Available: string;
  Amount_Filled: string;
  Price_Override: string;
}

export const emptyPosition = (): PositionsInterface => ({
  ID: 0,
  Offer_ID: 0,
  Local_Id: 0,
  Position_Name: "",
  Description: "",
  Amount_Available: "",
  Amount_Filled: "",
  Price_Override: "",
});

export interface DateInterface {
  ID: number;
  Date_Time_Start: string;
  Date_Time_End: string;
  Title: string;
  Description: string;
  Cancelled: boolean;
  Duration: number;
}

export interface SingleDate {
  id?: number;
  cancelled?: boolean;
  startSession: string;
  endSession: string;
  duration: number;
  localId?: number;
  offer?: OfferInterface;
}

export const createPosition = <T extends Partial<PositionsInterface>>(
  initialValues: T
): PositionsInterface & T => {
  return Object.assign(emptyPosition(), initialValues);
};
