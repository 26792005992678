import { useNavigate } from "react-router-dom";
import sampleImg from "../../Assets/sample.jpg";
import { UserForOfferInterface } from "../../Interfaces/OfferInterface";

interface OfferImageProps {
  img: string | ArrayBuffer | null;
  distance?: string;
  owner?: UserForOfferInterface;
}

const OfferImage = (props: OfferImageProps) => {
  const navigate = useNavigate();
  let dis = 0;
  if (props.distance) {
    dis = parseFloat(props.distance);
  }
  let src = "";
  if (typeof props.img === "string") src = props.img;

  return (
    <div className="offer-image">
      {props.distance && dis > 0 ? (
        <div className="offer-image__info">
          {"Distance: " + dis.toFixed(2) + " Miles"}
        </div>
      ) : null}
      {props.owner ? (
        <div
          className="offer-image__info offer-image__info--right"
          onClick={() => navigate(`../offers/${props.owner?.Slug}`)}
        >
          {"By: " + props.owner?.Business_Name}
        </div>
      ) : null}
      <img className="offer-image__info--image" src={src ? src : sampleImg} />
    </div>
  );
};

export default OfferImage;
