import { createSlice } from "@reduxjs/toolkit";

const LeaderSlice = createSlice({
  name: "Leader",
  initialState: {
    name: "",
    description: "",
    image: "",
    active: false,
    id: -1,
  },
  reducers: {
    clickedLeader(state, action) {
      state.name = action.payload.Name;
      state.description = action.payload.Description;
      state.image = action.payload.Image;
      state.active = action.payload.Active;
      state.id = action.payload.ID;
    },
    resetLeader(state) {
      state.name = "";
      state.description = "";
      state.image = "";
      state.active = false;
      state.id = -1;
    },
  },
});

export const { clickedLeader, resetLeader } = LeaderSlice.actions;
export default LeaderSlice.reducer;
