import { appGet, appPatch, appPost } from "./appFetch"

export const changeBookingEmail = async (params) => {
    return await appPatch("/booking/changebookingemail", params)
}
export const getRecentBookings = async (params) => {
    return await appGet("/booking/getrecentbookings", params)
}

export const getBooking = async (params) => {
    return await appGet("/booking/getbooking", params)
}

export const getBookingsByEmail = async (params) => {
    return await appPost("/booking/getbookingsbyemail", params)
}