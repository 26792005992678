import { useEffect, useState } from "react";
import FilterContainer from "../Components/Filters/FilterContainer";
import FormField from "../Components/Forms/FormField";
import FormSimpleToggle from "../Components/Forms/FormSimpleToggle";
import SearchOffers from "../Components/Search/SearchOffers";
import Autocomplete from "react-google-autocomplete";
import useWindowSize from "../CustomHooks/useWindowSize";
import SearchCreator from "../Components/Search/SearchCreator";

const SearchOffersPage = () => {
  const [toggle, setToggle] = useState("Closest");
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [titleName, setTitleName] = useState("Closest");
  const [distance, setDistance] = useState("5000");
  const [googleError, setGoogleError] = useState("");
  const [local, setLocal] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    switch (toggle) {
      case "Closest":
        setTitleName("Closest Offers");
        checkLocation();
        break;
      case "Offers":
        setTitleName("Offer Search");
        break;
      case "Creator":
        setTitleName("Creator Search");
        break;
      default:
        break;
    }
  }, [toggle]);

  const cb = (info: GeolocationPosition) => {
    setLatLng({ lat: info.coords.latitude, lng: info.coords.longitude });
    setLocal(true);
  };

  const checkLocation = () => {
    navigator?.geolocation?.getCurrentPosition(cb);
  };

  const { width } = useWindowSize();

  return (
    <div className={width > 768 ? "pl-3 pr-3" : "pl-1 pr-1"}>
      <div className="title mb-3">{titleName}</div>
      <FormSimpleToggle
        left={"Closest"}
        right={"Offers"}
        center={"Creator"}
        value={toggle}
        func={setToggle}
      />
      {toggle === "Closest" && (
        <div className="mt-3">
          <FilterContainer
          //buttonFunc={() => {}}
          >
            <div className="forms__group m-0">
              <div className="forms__field">
                <label>Custom Location:</label>
                <Autocomplete
                  options={{
                    types: [],
                    componentRestrictions: { country: "us" },
                  }}
                  placeholder="Address Search"
                  apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE}
                  onPlaceSelected={(place) => {
                    if (!place?.geometry) {
                      setGoogleError(
                        "Please choose a location close to you. We use the geometry generated by known addresses in order to let users search offers that are nearby. You can change the address lines in the next step."
                      );
                    } else {
                      setGoogleError("");
                      setLatLng({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                      });
                      setLocal(false);
                    }
                  }}
                />
                <div className="font-size-14">
                  <em>
                    {local
                      ? "Currently using your location"
                      : "Using the custom location"}
                  </em>
                </div>
              </div>

              {googleError && (
                <div className="forms__field--error forms__field--error--center">
                  {googleError}
                </div>
              )}
              <FormField
                label="Distance (in miles):"
                type="number"
                disabled={false}
                required={false}
                min={0}
                name="Distance"
                value={distance}
                func={setDistance}
              />
            </div>
          </FilterContainer>
          <SearchOffers
            latLng={{
              lat: parseFloat(latLng.lat.toFixed(6)),
              lng: parseFloat(latLng.lng.toFixed(6)),
            }}
            //  lat={parseFloat(latLng.lat.toFixed(6))}
            //  lng={parseFloat(latLng.lng.toFixed(6))}
            distance={parseInt(distance)}
          />
        </div>
      )}

      {toggle === "Creator" && (
        <div className="mt-3">
          <FilterContainer fill>
            <FormField
              label="Creator Name:"
              type="text"
              disabled={false}
              required={false}
              min={0}
              name="creator"
              value={creatorName}
              func={setCreatorName}
            />
          </FilterContainer>
          <SearchCreator searchString={creatorName} />
        </div>
      )}
      {toggle === "Offers" && (
        <div className="mt-3">
          <FilterContainer fill>
            <FormField
              label="Search term: "
              type="text"
              disabled={false}
              required={false}
              min={0}
              name="search"
              value={searchTerm}
              func={setSearchTerm}
            />
          </FilterContainer>
          <SearchOffers searchTerm={searchTerm} />
        </div>
      )}
    </div>
  );
};

export default SearchOffersPage;
