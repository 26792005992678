import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import PaymentComplete from "./Components/Checkout/PaymentComplete";
import useWindowSize from "./CustomHooks/useWindowSize";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import LoadingScreen from "./Layout/LoadingScreen";
import AccountPage from "./Pages/AccountPage";
import CheckoutPage from "./Pages/CheckoutPage";
import DashboardPage from "./Pages/DashboardPage";
import ForumPage from "./Pages/ForumPage";
import GetPaidPage from "./Pages/GetPaidPage";
import LoginPage from "./Pages/LoginPage";
import ManageOffersPage from "./Pages/ManageOffersPage";
import MyOffersPage from "./Pages/MyOffersPage";
import NewOfferPage from "./Pages/NewOfferPage";
import OffersByOffererIdPage from "./Pages/OffersByOffererIdPage";
import SearchOffersPage from "./Pages/SearchOffersPage";
import SignUpPage from "./Pages/SignUpPage";
import { useAppDispatch, useAppSelector } from "./Redux/Hooks";
import { validateUserThunk } from "./Redux/UserFuncs";
import UserAccount from "./UserComponents/Account/UserAccount";
import Enrolled from "./UserComponents/Enrolled/Enrolled";
import Modal from "./Components/Modal/Modal";
import ReactMarkdown from "react-markdown";

function App() {
  const { email } = useAppSelector((state) => state.user);
  const { loading } = useAppSelector((state) => state.loading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(validateUserThunk());
  }, [email]);

  const { width } = useWindowSize();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <LoadingScreen loading={loading} />
      <Modal title="Site Info" show={showModal} setShow={setShowModal}>
        <ReactMarkdown className="mark-down center" linkTarget="_blank">
          {`# Please visit Github for a short usage guide and to view the full code`}
        </ReactMarkdown>
        <a
          className="link-text center pt-1"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/Papa-Santo/ThreadBooking"
        >
          Github
        </a>
        <div className="pt-1 font-size-14">
          <strong>Tech Stack: </strong> React, TypeScript, Redux, Scss, Go, Gin,
          Gorm, Postgres, Postgis
        </div>
        <div className="pt-1 font-size-14">
          <strong>Supported With: </strong> Google Cloud Platform, Google
          Maps/Places, Amazon Web Services, AWS S3, Ubuntu, Nginx
        </div>
        <div className="pt-1 font-size-14">
          <strong>Warning: </strong>
          This is a constant work in progress used to practice and explore full
          stack web development and it's technologies. There might be peices
          that break during continous development as this is not supported by
          quality assurance, and the development focus often shifts depending
          upon the technology I want to experience working with. Some components
          have been carefully produced while others were quickly mocked up in
          order to have the tools necessary to implement a technology I was
          interested in.
        </div>
        <div className="pt-1 font-size-14">
          <strong>Code unexposed:</strong> The pgsql written to perform the
          geopositioning is not included as it performs better when written
          directly in the postgres database using postgis. If you need it as an
          example for a project you're working on, email me at
          emailpapasanto@gmail.com. I will get that code off of my ubuntu server
          and send it to you.
        </div>
        <div className="pt-1 font-size-14">
          <strong>Learning Requests:</strong> If there is something you see in
          this project that you would like explained, I will do my best to
          either quickly explain it, or I will produce a youTube video regarding
          the topic. Email me at emailpapasanto@gmail.com
        </div>
        <div className="pt-1 font-size-14">
          <strong>Tips for me:</strong> Have I used the wrong technology or
          misunderstood a usage? Did I code too much or too little? Let me know
          your input so I can learn from you! Email me at
          emailpapasanto@gmail.com
        </div>
        <div className="pt-1 font-size-14">
          <strong>Stripe:</strong> From what I understand, an open project like
          this one is not allowed to use Stripe's dev servers. However, all of
          the code examples you will see in the repository, are working.
        </div>
      </Modal>
      <a
        onClick={() => setShowModal((current) => !current)}
        className="forms__field--link center"
      >
        Click: This is a DEMO site used to explore web dev tech
      </a>
      <Header />
      <div
        className={
          width > 768 ? "page-container" : "page-container page-container__md"
        }
      >
        <Routes>
          <Route path="" element={<SearchOffersPage />} />
          <Route path="offers/:slug" element={<OffersByOffererIdPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="userAccount" element={<UserAccount />} />
          <Route path="enrolled" element={<Enrolled />} />
          <Route path="account/getpaid" element={<GetPaidPage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="myoffers" element={<MyOffersPage />} />
          <Route path="onboardingcheck" element={<div>Onboarding Check</div>} />
          <Route path="reauth" element={<div>Reauthorize Onboarding</div>} />
          <Route path="createoffer" element={<NewOfferPage />} />
          <Route path="editoffer/:id" element={<NewOfferPage edit />} />
          <Route path="manageoffers" element={<ManageOffersPage />} />
          <Route path="checkout/:id" element={<CheckoutPage />} />
          <Route path="forum/:id" element={<ForumPage />} />
          <Route path="forum/:id/:payIntent" element={<ForumPage />} />
          <Route path="paymentcomplete/:id" element={<PaymentComplete />} />
          {/* <Route path="calendar" element={<CalendarPage />} /> */}
          <Route path="*" element={<div>404 | Not Found</div>} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
