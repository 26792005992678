import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import { minutesToFormattedTime } from "../../Helpers/dateHelpers";
import ListContainer from "../Common/ListContainer";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SingleDate } from "../../Interfaces/OfferInterface";

interface DatesListProps {
  dates: SingleDate[];
  func: Function;
  deleteFunc: Function;
  editDate?: number;
}

const DatesList = (props: DatesListProps) => {
  const datesSorted = props.dates.sort((a, b) => {
    if (a.startSession < b.startSession) return -1;
    else if (a.startSession > b.startSession) return 1;
    else return 0;
  });
  const dateList = datesSorted.map((el, i) => {
    let time = minutesToFormattedTime(el.duration);

    return (
      <div
        className={
          i % 2 === 0
            ? `list__item list__item--dark ${
                (props.editDate || props.editDate === 0) &&
                props.editDate === el.localId
                  ? "list__item--highlight"
                  : ""
              }`
            : `list__item ${
                (props.editDate || props.editDate === 0) &&
                props.editDate === el.localId
                  ? "list__item--highlight"
                  : ""
              }`
        }
        onClick={() => {
          if (props.func) props.func(el.localId);
        }}
        key={i}
      >
        <div>
          Date:{" "}
          <span className="positions-list--value">
            {format(parseISO(el.startSession), "MM/dd/yyyy hh:mm a")}
          </span>
        </div>

        <div>
          Duration: <span className="positions-list--value">{time}</span>
        </div>
        <div className="list__item--tools">
          <span
            className="list__item--tools--x"
            onClick={(e) => props.deleteFunc(e, el.localId)}
          >
            <FontAwesomeIcon
              icon={icon({ name: "circle-xmark", style: "solid" })}
            />
          </span>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="forms__title--subtitle forms__title--margin">Dates</div>
      <ListContainer>{dateList}</ListContainer>
      <div className="forms__title--margin" />
    </>
  );
};

export default DatesList;
