interface FormInnerButtonProps {
  disabled?: boolean;
  title?: string;
  func: Function;
  primary?: boolean;
  attached?: boolean;
}

const FormInnerButton = (props: FormInnerButtonProps) => {
  return (
    <div
      className={
        props.attached ? "width-100 cursor-pointer" : "forms__submit-container"
      }
    >
      <div
        className={
          props.attached ? "width-100" : "forms__submit-container--sizer"
        }
      >
        <div
          className={
            props.disabled && props.attached
              ? "forms__submit--inner forms__submit--inner--disabled m-0 forms__submit--inner--hover-cancel"
              : props.disabled
              ? "forms__submit--inner forms__submit--inner--disabled"
              : props.primary && props.attached
              ? "forms__submit--inner--primary m-0 forms__submit--inner--hover-cancel"
              : props.attached
              ? "forms__submit--inner m-0 forms__submit--inner--hover-cancel"
              : props.primary
              ? "forms__submit--inner--primary"
              : "forms__submit--inner"
          }
          onClick={(e: React.SyntheticEvent) => {
            if (!props.disabled) props.func(e);
          }}
        >
          {props.title}
        </div>
      </div>
    </div>
  );
};

export default FormInnerButton;
