import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createNewUserApi,
  loginUserApi,
  logoutUserApi,
  updateUserApi,
  validateUserApi,
} from "../API/userApi";

// THUNKS

interface createNewUserInterface {
  email: string;
  password: string;
  type: number;
}

interface loginUserInterface {
  email: string;
  password: string;
}

interface updateUserInterface {
  businessName: string;
  contactName: string;
  url: string;
  phone: string;
  extension: string;
  donationPercentage: string;
  description: string;
}

export const createNewUserThunk = createAsyncThunk(
  "user/create",
  async (data: createNewUserInterface, thunkAPI) => {
    const { email, password, type } = data;
    try {
      const res = await createNewUserApi({ email, password, type });
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue("An error occured");
    }
  }
);

export const loginUserThunk = createAsyncThunk(
  "user/login",
  async (data: loginUserInterface, thunkAPI) => {
    const { email, password } = data;
    try {
      const res = await loginUserApi({ email, password });
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue("An error occured");
    }
  }
);

export const logoutUserThunk = createAsyncThunk(
  "user/logout",
  // eslint-disable-next-line
  async (empty, thunkAPI) => {
    try {
      const res = await logoutUserApi();
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue("An error occured");
    }
  }
);

export const validateUserThunk = createAsyncThunk(
  "user/validate",
  // eslint-disable-next-line
  async (empty, thunkAPI) => {
    try {
      const res = await validateUserApi();
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue("An error occured");
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  "user/update",
  async (data: updateUserInterface, thunkAPI) => {
    try {
      const {
        businessName,
        contactName,
        url,
        phone,
        extension,
        donationPercentage,
        description,
      } = data;
      const res = await updateUserApi({
        businessName,
        contactName,
        url,
        phone,
        extension,
        donationPercentage: parseInt(donationPercentage),
        description,
      });
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue("An error occured");
    }
  }
);
