interface FormInnerButtonProps {
  disabled?: boolean;
  title?: string;
  func: Function;
  primary?: boolean;
}

const FormInnerPlain = (props: FormInnerButtonProps) => {
  return (
    <div className={"forms__submit-container"}>
      <div className={"forms__submit-container--sizer"}>
        <div
          className={
            props.primary
              ? "forms__submit--plain--primary"
              : "forms__submit--plain"
          }
          onClick={(e: React.SyntheticEvent) => {
            if (!props.disabled) props.func(e);
          }}
        >
          {props.title}
        </div>
      </div>
    </div>
  );
};

export default FormInnerPlain;
