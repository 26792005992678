import { createSlice } from "@reduxjs/toolkit";
import {
  OfferInterface,
  PositionsInterface,
} from "../Interfaces/OfferInterface";

const initialState: OfferInterface = {
  ID: 0,
  User_ID: 0,
  Address_ID: 0,
  Lead_ID: 0,
  Name: "",
  Description: "",
  Amount_Available: 0,
  Price: 0,
  Book_After_Start_Date: false,
  Display_After_Start_Date: false,
  Group_Purchase: 0,
  Form_Type: 0,
  DiscountRate: 0,
  DiscountDateStart: "",
  DiscountDateEnd: "",
  Image_Url: "",
  Image_Key: "",
  Active: false,
  Cancelled: false,
  TimeZone: "",
  Distance: "",
  Positions: [],
  Dates: [],
  Bookings: [],
  TermsAndConditions: { Name: "", Description: "", Active: false, ID: 0 },
};

const OfferSlice = createSlice({
  name: "Offer",
  initialState,
  reducers: {
    clickedOffer(state, action) {
      const positions: PositionsInterface[] = action.payload.Positions.map(
        (el: PositionsInterface) => {
          el.Price_Override = el.Price_Override.toString();
          return el;
        }
      );
      state.ID = action.payload.ID;
      state.User_ID = action.payload.User_ID;
      state.Address_ID = action.payload.Address_ID;
      state.Lead_ID = action.payload.Lead_ID;
      state.Name = action.payload.Name;
      state.Description = action.payload.Description;
      state.Amount_Available = action.payload.Amount_Available;
      state.Price = action.payload.Price.toString();
      state.Book_After_Start_Date = action.payload.Book_After_Start_Date;
      state.Display_After_Start_Date = action.payload.Display_After_Start_Date;
      state.Group_Purchase = action.payload.Group_Purchase;
      state.Form_Type = action.payload.Form_Type;
      state.DiscountRate = action.payload.DiscountRate;
      state.DiscountDateStart = action.payload.DiscountDateStart;
      state.DiscountDateEnd = action.payload.DiscountDateEnd;
      state.Image_Url = action.payload.Image_Url;
      state.Image_Key = action.payload.Image_Key;
      state.Active = action.payload.Active;
      state.Cancelled = action.payload.Cancelled;
      state.TimeZone = action.payload.Time_Zone;
      state.Distance = action.payload.Distance;
      state.Positions = positions;
      state.Dates = action.payload.Dates;
      state.Bookings = action.payload.Bookings;
      state.TermsAndConditions = action.payload.Terms_And_Conditions;
    },
    resetOffer(state) {
      return initialState;
    },
  },
});

export const { clickedOffer, resetOffer } = OfferSlice.actions;
export default OfferSlice.reducer;
