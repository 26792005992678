import { useEffect, useState } from "react";
import { getPromoCodes } from "../../API/promoCodeApi";
import { formatDateForClient } from "../../Helpers/dateHelpers";
import { PromoInterface } from "../../Interfaces/PromoInterfaces";
import { clickedAddress, resetAddress } from "../../Redux/AddressReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import ListContainer from "../Common/ListContainer";

interface PromoListProps {
  form: Function;
  edit: Function;
  promoCodes: PromoInterface[];
  promoCodesOld: PromoInterface[];
}

const PromoList = (props: PromoListProps) => {
  const { addresses } = useAppSelector((state) => state.user);
  const [curId, setCurId] = useState(-1);
  const { promoCodes, promoCodesOld } = props;

  let promos = [...promoCodes, ...promoCodesOld].map((el, i) => (
    <div
      className={
        i % 2 === 0
          ? `list__item list__item--dark address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
          : `list__item address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
      }
      onClick={() => {
        props.edit(el);
      }}
      key={i}
    >
      {/* <div className="address-list__item" onClick={() => dispatch(clickedAddress(el))}> */}
      <div
        className={
          new Date(el.End_Date) < new Date(Date.now())
            ? "address-list__item color-primary"
            : ""
        }
      >
        {`Name: ${el.Name}${
          el.Amount_Disc
            ? " - Discount $" + el.Amount_Disc
            : " - Discount " + el.Percentage_Disc + "%"
        } - Ends: ${formatDateForClient(el.End_Date)}`}
      </div>
    </div>
  ));

  promos.unshift(
    <div
      key={-1}
      className="address-list__new address-list__item"
      onClick={() => props.form()}
    >
      Add New Promo Code
    </div>
  );

  let noAddress = false;
  if (!addresses[0]?.Address_Line_One) {
    noAddress = true;
  }

  if (noAddress) {
    delete promos[1];
  }

  return (
    <>
      <div className="forms__title--margin" />
      {/* <div className="address__success">{props.success}</div> */}
      {/* <div className="forms__title--subtitle forms__title--margin">
        Addresses (click an address to edit or delete)
      </div> */}
      <ListContainer>{promos}</ListContainer>
    </>
  );
};

export default PromoList;
