import { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { createPolicy, editPolicy, removePolicy } from "../../API/policyApi";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { updateUserPolicies } from "../../Redux/UserReducer";
import FormField from "../Forms/FormField";
import FormInnerButton from "../Forms/FormInnerButton";

interface CancellationPolicyFormProps {
  newPolicy: boolean;
  back: Function;
}

const CancellationPolicyForm = (props: CancellationPolicyFormProps) => {
  const policyInfo = useAppSelector((state) => state.policy);
  const [name, setName] = useState(policyInfo.name || "");
  const [description, setDescription] = useState(policyInfo.description || "");
  const [error, setError] = useState("");

  const dispatch = useAppDispatch();

  const submitFunction = async () => {
    let res;
    try {
      if (props.newPolicy) {
        res = await createPolicy({
          name,
          description,
        });
      } else {
        res = await editPolicy({
          name,
          description,
          id: policyInfo.id,
        });
      }
      dispatch(updateUserPolicies(res));
      props.back(true);
    } catch (err) {
      setError("An error has occured. Please try again later");
    }
  };

  const deletePolicy = async () => {
    try {
      const res = await removePolicy({ id: policyInfo.id });
      dispatch(updateUserPolicies(res));
      props.back(true);
    } catch (error) {
      setError("An error has occured. Please try again later");
    }
  };

  return (
    <div className="address__comp--form">
      <div className="forms__group">
        <FormField
          label="Name"
          type="text"
          placeholder="7 Day Cancellation Period"
          value={name}
          func={setName}
          disabled={false}
          required={true}
        />
        <FormField
          label="Description"
          placeholder="Policy information"
          name="Description"
          value={description}
          func={setDescription}
          type="text"
          disabled={false}
          required={false}
          textArea={true}
        />
      </div>
      <div className="forms__title mb-1">Policy Preview</div>
      <div className="offer-info__lead-modal ">
        <ReactMarkdown className="mark-down" linkTarget="_blank">
          {description}
        </ReactMarkdown>
      </div>
      <div className="forms__button-container">
        <FormInnerButton func={() => deletePolicy()} title="Remove Policy" />
        <FormInnerButton func={() => submitFunction()} title="Submit" primary />
      </div>
      {error && <div className="signup__error">{error}</div>}
    </div>
  );
};

export default CancellationPolicyForm;
