import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { primaryAddress } from "../Helpers/addressHelpers";
import { LeaderInterface } from "../Interfaces/LeaderInterface";
import { AddressInterface } from "../Interfaces/AddressInterface";
import {
  createNewUserThunk,
  updateUserThunk,
  loginUserThunk,
  logoutUserThunk,
  validateUserThunk,
} from "./UserFuncs";
import { PolicyInterface } from "../Interfaces/PolicyInterface";

export interface UserReducerInterface {
  email: string;
  status: string;
  error: string;
  signupError: string;
  loginError: string;
  name: string;
  businessName: string;
  contactName: string;
  url: string;
  description: string;
  phone: string;
  extension: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  county: string;
  state: string;
  country: string;
  zip: string;
  logo: string;
  addressId: string;
  lat: number;
  lng: number;
  placesId: string;
  active: boolean;
  infoUpdated: string;
  accountType: number;
  slug: string;
  addresses: AddressInterface[];
  leaders: LeaderInterface[];
  policies: PolicyInterface[];
}

const initialState: UserReducerInterface = {
  email: "",
  status: "succeeded",
  error: "",
  signupError: "",
  loginError: "",
  name: "",
  businessName: "",
  contactName: "",
  url: "",
  description: "",
  phone: "",
  extension: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  county: "",
  state: "",
  country: "",
  zip: "",
  addressId: "",
  lat: 0,
  lng: 0,
  placesId: "",
  logo: "",
  active: false,
  infoUpdated: "",
  accountType: 0,
  slug: "",
  addresses: [
    {
      Address_Line_One: "",
      Address_Line_Two: "",
      City: "",
      Country: "",
      County: "",
      CreatedAt: "",
      DeletedAt: "",
      ID: 0,
      Is_Primary: false,
      State: "",
      UpdatedAt: "",
      User_ID: 0,
      Zip: "",
    },
  ],
  leaders: [{ Name: "", Active: false, Description: "", Image: "", ID: -1 }],
  policies: [{ Name: "", Active: false, Description: "", ID: -1 }],
};

// const initialState:  =

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    removeErrors(state) {
      state.error = "";
      state.signupError = "";
      state.loginError = "";
    },
    updateUserAddresses(state, action) {
      if (action?.payload?.addresses?.length < 1) {
        return initialState;
      } else {
        const { addressObj, addressArr } = primaryAddress(
          action.payload?.addresses
        );
        state.addressLineOne = addressObj.addressLineOne;
        state.addressLineTwo = addressObj.addressLineTwo;
        state.city = addressObj.city;
        state.county = addressObj.county;
        state.state = addressObj.state;
        state.country = addressObj.country;
        state.zip = addressObj.zip;
        state.addressId = addressObj.ID;

        state.lat = addressObj.lat;
        state.lng = addressObj.lng;
        state.placesId = addressObj.placesId;

        state.addresses = addressArr;
        state.error = "";
        state.status = "succeeded";
      }
    },
    updateUserLeaders(state, action) {
      if (!action?.payload?.leaders || action?.payload?.leaders?.length < 1) {
        state.leaders = [];
        state.error = "";
        state.status = "succeeded";
      } else {
        state.leaders = action.payload.leaders;
        state.error = "";
        state.status = "succeeded";
      }
    },
    updateUserPolicies(state, action) {
      if (!action?.payload?.policies || action?.payload?.policies?.length < 1) {
        state.policies = [];
        state.error = "";
        state.status = "succeeded";
      } else {
        state.policies = action.payload.policies;
        state.error = "";
        state.status = "succeeded";
      }
    },
    updateUser(state, action) {
      if (action?.payload?.user) {
        state.email = action.payload?.user?.Email;
        state.name = action.payload?.user?.Name;
        state.businessName = action.payload?.user?.Business_Name;
        state.contactName = action.payload?.user?.Contact_Name;
        state.url = action.payload?.user?.Url;
        state.phone = action.payload?.user?.Phone;
        state.extension = action.payload?.user?.extension;
        state.active = action.payload?.user?.Active;
        state.logo = action.payload?.user?.Logo_Url;
        state.accountType = action.payload?.user?.AccountType;
        state.slug = action.payload?.user?.Slug;
        state.description = action.payload?.user?.Description;
        state.infoUpdated = "";
        state.error = "";
        state.signupError = "";
        state.loginError = "";
        state.status = "succeeded";
      } else {
        return initialState;
      }
    },
  },
  // THUNKS
  extraReducers(builder) {
    builder
      /////////////
      /// New Thunk
      /// createNewUserThunk
      /////////////
      .addCase(createNewUserThunk.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
        state.signupError = "";
        state.loginError = "";
      })
      .addCase(createNewUserThunk.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.signupError = action.payload.error;
        } else {
          state.email = action.payload?.user?.Email;
          state.name = action.payload?.user?.Name;
          state.businessName = action.payload?.user?.Business_Name;
          state.contactName = action.payload?.user?.Contact_Name;
          state.url = action.payload?.user?.Url;
          state.phone = action.payload?.user?.Phone;
          state.extension = action.payload?.user?.extension;

          state.addressLineOne = "";
          state.addressLineTwo = "";
          state.city = "";
          state.county = "";
          state.state = "";
          state.country = "";
          state.zip = "";

          state.lat = 0;
          state.lng = 0;
          state.placesId = "";
          state.addressId = "";
          state.addresses = [];

          state.logo = "";

          state.active = action.payload?.user?.Active;
          state.accountType = action.payload?.user?.AccountType;
          state.infoUpdated = "";
          state.error = "";
          state.signupError = "";
          state.loginError = "";
          state.status = "succeeded";
        }
      })
      .addCase(createNewUserThunk.rejected, (state, action) => {
        state.status = "failed";
        state.signupError = "An error has occurred. Please try again later";
      })
      /////////////
      /// New Thunk
      /// loginUserThunk
      /////////////
      .addCase(loginUserThunk.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
        state.signupError = "";
        state.loginError = "";
      })
      .addCase(loginUserThunk.fulfilled, (state, action) => {
        if (action.payload.error) state.loginError = action.payload.error;
        else {
          const { addressObj, addressArr } = primaryAddress(
            action.payload?.address
          );
          state.email = action.payload?.user?.Email;
          state.name = action.payload?.user?.Name;
          state.businessName = action.payload?.user?.Business_Name;
          state.contactName = action.payload?.user?.Contact_Name;
          state.url = action.payload?.user?.Url;
          state.phone = action.payload?.user?.Phone;
          state.extension = action.payload?.user?.extension;
          state.active = action.payload?.user?.Active;
          state.logo = action.payload?.user?.Logo_Url;
          state.accountType = action.payload?.user?.AccountType;
          state.slug = action.payload?.user?.slug;
          state.description = action.payload?.user?.Description;
          state.infoUpdated = "";
          state.error = "";
          state.signupError = "";
          state.loginError = "";
          state.status = "succeeded";

          state.addressLineOne = addressObj.addressLineOne;
          state.addressLineTwo = addressObj.addressLineTwo;
          state.city = addressObj.city;
          state.county = addressObj.county;
          state.state = addressObj.state;
          state.country = addressObj.country;
          state.zip = addressObj.zip;
          state.addressId = addressObj.ID;

          state.lat = addressObj.lat;
          state.lng = addressObj.lng;
          state.placesId = addressObj.placesId;

          state.leaders = action.payload.leaders;
          state.addresses = addressArr;
          state.policies = action.payload.policies;
        }
      })
      .addCase(loginUserThunk.rejected, (state, action) => {
        state.status = "failed";
        state.signupError = "";
        state.loginError = "An error has occurred. Please try again later";
        state.error = "";
      })
      /////////////
      /// New Thunk
      /// logoutUserThunk
      /////////////
      .addCase(logoutUserThunk.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
        state.signupError = "";
        state.loginError = "";
      })
      .addCase(logoutUserThunk.fulfilled, (state, action) => {
        if (action.payload?.error) {
          state.error = action.payload.error;
          state.infoUpdated = "";
        } else {
          return initialState;
        }
      })
      .addCase(logoutUserThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = "An error has occurred. Please try again later";
      })
      /////////////
      /// New Thunk
      /// updateUserThunk
      /////////////
      .addCase(updateUserThunk.pending, (state, action) => {
        state.status = "loading";
        state.infoUpdated = "";
        state.error = "";
        state.signupError = "";
        state.loginError = "";
      })
      .addCase(updateUserThunk.fulfilled, (state, action) => {
        if (action.payload?.error) {
          state.error =
            action.payload.err === "duplicated key not allowed"
              ? "Business name in use. Try adding your location like Business Name 'Dallas'"
              : action.payload.error;
          state.infoUpdated = "";
          return;
        } else {
          state.email = action.payload?.user?.Email;
          state.name = action.payload?.user?.Name;
          state.businessName = action.payload?.user?.Business_Name;
          state.contactName = action.payload?.user?.Contact_Name;
          state.url = action.payload?.user?.Url;
          state.phone = action.payload?.user?.Phone;
          state.extension = action.payload?.user?.extension;
          state.active = action.payload?.user?.Active;
          state.accountType = action.payload?.user?.AccountType;
          state.slug = action.payload?.user?.Slug;
          state.description = action.payload?.user?.Description;
          state.error = "";
          state.status = "succeeded";
          state.infoUpdated = format(Date.now(), "MM/dd/yyyy h:mm:ss a");
        }
      })
      .addCase(updateUserThunk.rejected, (state, action) => {
        state.status = "failed";
        state.infoUpdated = "";
        state.error = "An error has occurred. Please try again later";
      })
      /////////////
      /// New Thunk
      /// validateUser
      /////////////
      .addCase(validateUserThunk.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
        state.infoUpdated = "";
      })
      .addCase(validateUserThunk.fulfilled, (state, action) => {
        if (action.payload?.user) {
          const { addressObj, addressArr } = primaryAddress(
            action.payload?.address
          );
          state.email = action.payload?.user?.Email;
          state.name = action.payload?.user?.Name;
          state.businessName = action.payload?.user?.Business_Name;
          state.contactName = action.payload?.user?.Contact_Name;
          state.url = action.payload?.user?.Url;
          state.logo = action.payload?.user?.Logo_Url;
          state.phone = action.payload?.user?.Phone;
          state.extension = action.payload?.user?.extension;
          state.description = action.payload?.user?.Description;

          state.addressLineOne = addressObj.addressLineOne;
          state.addressLineTwo = addressObj.addressLineTwo;
          state.city = addressObj.city;
          state.county = addressObj.county;
          state.state = addressObj.state;
          state.country = addressObj.country;
          state.zip = addressObj.zip;
          state.addressId = addressObj.ID;

          state.lat = addressObj.lat;
          state.lng = addressObj.lng;
          state.placesId = addressObj.placesId;

          state.accountType = action.payload?.user?.AccountType;
          state.slug = action.payload?.user?.Slug;

          state.addresses = addressArr;

          state.leaders = action.payload.leaders;
          state.policies = action.payload.policies;

          state.active = action.payload?.user?.Active;
          state.error = "";
          state.status = "succeeded";
          state.infoUpdated = "";
          return;
        } else {
          return initialState;
        }
      })
      .addCase(validateUserThunk.rejected, (state, action) => {
        return initialState;
      });
  },
});

export const {
  removeErrors,
  updateUserAddresses,
  updateUserLeaders,
  updateUser,
  updateUserPolicies,
} = UserSlice.actions;
export default UserSlice.reducer;
