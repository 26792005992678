import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AccountComp from "../Components/Account/AccountComp";
import { useAppSelector } from "../Redux/Hooks";

const AccountPage = () => {
  const { active } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!active) navigate("/");
  }, [active]);
  return (
    <>
      <AccountComp />
    </>
  );
};

export default AccountPage;
