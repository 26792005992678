import React from "react";
import { useAppSelector } from "../../Redux/Hooks";
import AddressComp from "../Account/AddressComp";

interface SelectProps {
  label: string;
  value: string;
  placeholder?: string;
  required: boolean;
  disabled?: boolean;
  error?: string;
  func: React.Dispatch<React.SetStateAction<string>>;
}

const FormAddressSelector = (props: SelectProps) => {
  const { addresses } = useAppSelector((state) => state.user);
  // Fix for country code
  const onPicked = (val: string) => {
    props.func(val);
  };
  // Create the options array
  const makeSelect = () => {
    let optionArr = [];
    for (let i = 0; i < addresses.length; i++) {
      optionArr.push(
        <option key={addresses[i].ID} value={addresses[i].ID}>
          {`${addresses[i].Address_Line_One}${
            addresses[i].Address_Line_Two
              ? " " + addresses[i].Address_Line_Two
              : ""
          }, ${addresses[i].City} ${addresses[i].State} ${addresses[i].Zip}`}
        </option>
      );
    }
    return (
      <select
        id={props.label}
        onChange={(e) => onPicked(e.target.value)}
        value={props.value}
      >
        {optionArr}
      </select>
    );
  };
  return (
    <div className="forms__field forms__flex forms__flex--column">
      <div>
        <label htmlFor={props.label}>
          {props.label + (props.required ? " *" : "")}
        </label>
        <span className="forms__field--link">
          <AddressComp inline />
        </span>
      </div>
      {makeSelect()}
    </div>
  );
};

export default FormAddressSelector;
