import { useState } from "react";
import { Link } from "react-router-dom";
import { buildTermsAndConditions } from "../../Helpers/offerHelpers";
import { isEmail } from "../../Helpers/regex";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { createNewUserThunk } from "../../Redux/UserFuncs";
import { removeErrors } from "../../Redux/UserReducer";
import FormCheckBox from "../Forms/FormCheckBox";
import AuthContainerComp from "./AuthContainerComp";

const SignUpComp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [terms, setTerms] = useState(false);
  const [accountType, setAccountType] = useState("User");
  const dispatch = useAppDispatch();
  const { signupError } = useAppSelector((state) => state.user);

  const createUser = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErr("");
    dispatch(removeErrors());
    if (!password || password.length < 8) {
      setErr("Passwords must be atleast 8 characters");
      return;
    }
    if (!isEmail.test(email) || !email) {
      setErr("Please enter a valid email");
      return;
    }

    if (!terms) {
      setErr(
        "You must agree to the terms and conditions in order to create an account"
      );
      return;
    }
    dispatch(
      createNewUserThunk({
        email: email,
        password: password,
        type: accountType === "User" ? 1 : 2,
      })
    );
  };

  return (
    <AuthContainerComp>
      <form className="signup" onSubmit={(e) => createUser(e)}>
        <h1 className="signup__title">Sign Up</h1>
        <div className="signup__input-group">
          <input
            id="signup-email"
            className="signup__input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="signup__input-group">
          <input
            id="signup-password"
            className="signup__input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="signup__account-type--container">
          <div
            onClick={() => setAccountType("User")}
            className={
              accountType === "User"
                ? "signup__account-type signup__account-type--active"
                : "signup__account-type"
            }
          >
            User
          </div>
          <div
            onClick={() => setAccountType("Business")}
            className={
              accountType === "Business"
                ? "signup__account-type signup__account-type--active"
                : "signup__account-type"
            }
          >
            Business
          </div>
        </div>
        <div className="mt-1-6">
          <FormCheckBox
            label="Agree to terms and conditions"
            value={terms}
            func={setTerms}
            modalInfo={buildTermsAndConditions("")}
            required
          />
        </div>
        <div className="text-center mt-1">
          By signing up, you confirm that you are at least 16 years old or have
          parental permission
        </div>
        <div className="signup__login">
          <p>
            Already A Member?{" "}
            <strong>
              <Link className="signup__link" to={"/login"}>
                Log in
              </Link>
            </strong>
          </p>
        </div>
        <input type="submit" className="signup__button" value={"Sign up"} />
        {err && <p className="signup__error">{err}</p>}
        {signupError && <p className="signup__error">{signupError}</p>}
      </form>
    </AuthContainerComp>
  );
};

export default SignUpComp;
