import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOfferByIdForum } from "../API/offersApi";
import FormInnerPlain from "../Components/Forms/FormButtonPlain";
import ForumInfo from "../Components/Forum/ForumInfo";
import NavButton from "../Layout/NavButton";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks";
import { clickedOffer, resetOffer } from "../Redux/OfferReducer";

const ForumPage = () => {
  const { email } = useAppSelector((state) => state.user);
  const [booked, setBooked] = useState(false);
  const [owner, setOwner] = useState(false);

  // Get offerid from params
  let param = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (param.id) {
      getOffer(param.id);
    }
  }, []);

  const getOffer = async (id: string) => {
    const offerObj = await getOfferByIdForum({ id: id });
    if (offerObj.offers?.length > 0) {
      dispatch(clickedOffer(offerObj.offers[0]));
      if (offerObj.booked) setBooked(true);
      if (offerObj.owner) setOwner(true);
      else if (!offerObj.booked && !offerObj.owner) {
        setBooked(false);
        setOwner(false);
      }
    } else {
      dispatch(resetOffer());
      setBooked(false);
      setOwner(false);
    }
  };

  const under = <div className="title">Under Construction</div>;
  if (!email) {
    return (
      <div>
        {/* {under} */}
        <div className="title">Login or Signup</div>
        <div className="text-center font-size-14 mt-2">
          <p>
            Please create an account or login (using the email you entered
            during purchase) to participate in the forum
          </p>
        </div>
        <div
          className={
            email
              ? "header__button-container--small"
              : "mt-2 color-light center"
          }
        >
          <FormInnerPlain
            func={() => navigate("../signup")}
            title="Sign up for free"
          />
        </div>
      </div>
    );
  } else if (!booked && !owner) {
    return (
      <div className="p-1">
        {" "}
        {under}{" "}
        <div className="text-center font-size-14">
          Email: {email} does not have access to this forum
        </div>
      </div>
    );
  }
  return (
    <div>
      {owner && <div className="title">You own this offer</div>}
      <ForumInfo owner={owner} />
    </div>
  );
};

export default ForumPage;
