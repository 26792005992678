import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormSubmitButton from "../../Components/Forms/FormSubmitButton";
import { useAppSelector } from "../../Redux/Hooks";

const UserAccount = () => {
  const { accountType, email } = useAppSelector((state) => state.user);

  const navigate = useNavigate();
  useEffect(() => {
    if (!accountType) {
      navigate("/");
    }
  });

  const passwordResetFunc = async () => {
    // Not implemented
    console.log("DO PASSWORD RESET");
  };

  return (
    <div className="page-center">
      <div className="font-size-14">Email: {email}</div>
      <form
        onSubmit={() => {
          passwordResetFunc();
        }}
      >
        <FormSubmitButton title="Reset Password" />
      </form>
    </div>
  );
};

export default UserAccount;
