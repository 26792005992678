import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useAppSelector } from "../../Redux/Hooks";
import Modal from "../../Components/Modal/Modal";
import OfferDates from "./OfferDates";
import OfferPositions from "./OfferPositions";
import {
  OfferInterface,
  PositionsInterface,
  SingleDate,
} from "../../Interfaces/OfferInterface";
import { useNavigate } from "react-router-dom";

interface offerInfoProps {
  offer?: OfferInterface;
  offerId?: number;
  subtitle?: string;
  lead?: number;
  startDate?: string;
  endDate?: string;
  dateCount?: number;
  durationHours?: number;
  durationMinutes?: number;
  price?: string;
  name?: string;
  address?: string;
  description?: string;
  dates?: SingleDate[];
  positions?: PositionsInterface[];
  buttonClick?: boolean;
  removeButton?: boolean;
}

const OfferInfo = (props: offerInfoProps) => {
  const { leaders } = useAppSelector((state) => state.user);
  const [highlight, setHighlight] = useState("info");
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (props.positions) {
      let openPosition = false;
      for (let i = 0; i < props.positions.length; i++) {
        if (
          parseInt(props.positions[i].Amount_Available) -
            parseInt(props.positions[i].Amount_Filled) >
          0
        ) {
          openPosition = true;
        }
      }
      if (openPosition) setDisableButton(false);
      else setDisableButton(true);
    }
  }, [props.positions]);

  const navigate = useNavigate();

  const goToCheckout = () => {
    navigate("/checkout/" + props.offerId);
  };

  const onCloseModal = () => setShowModal(false);
  let lead = [...leaders].filter((el) => el.ID === props.lead)[0];

  return (
    <div
      className={`offer-info__container ${
        props.removeButton && "offer-info__container--reset"
      }`}
    >
      <Modal
        title={lead?.Name}
        backButton={false}
        show={showModal}
        setShow={onCloseModal}
      >
        <div className="offer-info__lead-modal">
          <ReactMarkdown className="mark-down" linkTarget={"_blank"}>
            {lead?.Description}
          </ReactMarkdown>
        </div>
      </Modal>
      <div
        className={`offer-info__pad ${
          props.removeButton && "offer-info__pad--reset"
        }`}
      >
        <div className="offer-info__head">
          <div className="offer-info__title">{props.name}</div>

          {lead?.Name && (
            <div
              onClick={() => setShowModal(true)}
              className="offer-info__basics--by"
            >
              By {lead.Name}
            </div>
          )}
          {(highlight === "info" || highlight === "openings") && (
            <div className="offer-info__basics">
              {props.startDate
                ? format(
                    parseISO(props.startDate),
                    "EEEE, LLLL eo, yyyy hh:mm a"
                  ) + "  ("
                : ""}
              {props.dateCount ? props.dateCount : ""}
              {props.dateCount && props.dateCount > 1
                ? " sessions)"
                : props.dateCount
                ? " session)"
                : ""}
            </div>
          )}
          <div className="offer-info__basics">{props.address}</div>
          <div className="offer-info__basics--price">
            {props.price !== "0" ? "$" + props.price : "FREE"}
          </div>
        </div>
        <div className="offer-info">
          <div className="offer-info__header--container">
            <div
              onClick={() => setHighlight("info")}
              className={`offer-info__header ${
                highlight === "info" ? "offer-info__header--highlight" : ""
              }`}
            >
              Info
            </div>
            <div
              onClick={() => setHighlight("openings")}
              className={`offer-info__header ${
                highlight === "openings" ? "offer-info__header--highlight" : ""
              }`}
            >
              Openings
            </div>
            <div
              onClick={() => setHighlight("dates")}
              className={`offer-info__header ${
                highlight === "dates" ? "offer-info__header--highlight" : ""
              }`}
            >
              Dates
            </div>
          </div>
          {highlight === "info" && (
            <div className="offer-info__body offer-info__body--info">
              <ReactMarkdown className="mark-down" linkTarget="_blank">
                {props.description ? props.description : ""}
              </ReactMarkdown>
            </div>
          )}
          {highlight === "dates" && (
            <div className="offer-info__body">
              {props.dates && props.dates.length > 0 && (
                <OfferDates dates={props.dates} />
              )}
            </div>
          )}
          {highlight === "openings" && (
            <div className="offer-info__body">
              {props.positions && props.positions.length > 0 && (
                <OfferPositions positions={props.positions} />
              )}
            </div>
          )}
        </div>
        {!props.removeButton && (
          <button
            className="offer-info__button"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              e.stopPropagation();
              if (props.buttonClick) goToCheckout();
            }}
            disabled={disableButton}
          >
            {disableButton ? "Fully Booked" : "Sign Up"}
          </button>
        )}
      </div>
    </div>
  );
};

export default OfferInfo;
