import { useEffect } from "react";
import { PositionsInterface } from "../../Interfaces/OfferInterface";

interface SelectProps {
  options: PositionsInterface[];
  label: string;
  value: string;
  placeholder?: string;
  required: boolean;
  disabled?: boolean;
  error?: string;
  func: Function;
}

const FormPositionSelector = (props: SelectProps) => {
  const onPicked = (val: string) => {
    props.func(parseInt(val));
  };
  // Create the options array
  const makeSelect = () => {
    let optionArr = [];
    for (let i = 0; i < props.options.length; i++) {
      optionArr.push(
        <option
          key={i.toString() + props.options[i]}
          id={i.toString() + props.options[i]}
          value={props.options[i].ID}
          disabled={
            parseInt(props.options[i].Amount_Available) -
              parseInt(props.options[i].Amount_Filled) ===
            0
          }
        >
          {`${props.options[i].Position_Name}${
            props.options[i].Price_Override !== "-1"
              ? ` - ${props.options[i].Price_Override}`
              : ""
          }`}
        </option>
      );
    }
    return (
      <select
        id={props.label}
        onChange={(e) => onPicked(e.target.value)}
        value={props.value}
      >
        {optionArr}
      </select>
    );
  };

  return (
    <div className="forms__field forms__flex forms__flex--column">
      <div>
        <label htmlFor={props.label}>
          {props.label + (props.required ? " *" : "")}
        </label>
      </div>
      {makeSelect()}
    </div>
  );
};

export default FormPositionSelector;
