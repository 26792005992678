import { useState } from "react";
import Modal from "../Components/Modal/Modal";
import useWindowSize from "../CustomHooks/useWindowSize";
import { privacyPolicy } from "../Helpers/privacyPolicy";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  let year = new Date().getFullYear();

  const { width } = useWindowSize();

  return (
    <>
      <div className={width > 768 ? "footer" : "footer footer__md"}>
        <div>Copyright Thread {year}</div>
        <a onClick={() => setShowModal(true)} className="forms__field--link">
          Privacy Policy
        </a>
      </div>

      <Modal title="Privacy Policy" show={showModal} setShow={setShowModal}>
        {privacyPolicy}
      </Modal>
    </>
  );
};

export default Footer;
