import { useEffect, useState } from "react";
import { getAddressesById } from "../../API/addressApi";
import { AddressInterface } from "../../Interfaces/AddressInterface";
import { OfferInterface } from "../../Interfaces/OfferInterface";
import FormInnerPlain from "../Forms/FormButtonPlain";

declare global {
  interface Window {
    gapi: any;
  }
}

interface CalendarProps {
  offer?: OfferInterface;
}

function Calendar(props: CalendarProps) {
  const gapi = window.gapi!;
  /* 
    Update with your own Client Id and Api key 
  */
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT;
  const API_KEY = process.env.REACT_APP_GOOGLE_AUTOCOMPLETE;
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  const SCOPES = "https://www.googleapis.com/auth/calendar.events";

  const dates = props.offer?.Dates;

  const [address, setAddress] = useState<AddressInterface>();
  // const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (props.offer?.Address_ID) getAddress();
  }, []);

  const getAddress = async () => {
    const addressObj = await getAddressesById({ id: props.offer?.Address_ID });
    setAddress(addressObj.address);
  };

  const handleClick = () => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        plugin_name: "Booking Application",
      });

      gapi.client.load("calendar", "v3");

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const events = dates?.map((el, i) => {
            return {
              summary: props.offer?.Name,
              location: `${address?.Address_Line_One}${
                address?.Address_Line_Two
                  ? ` ${address?.Address_Line_Two} `
                  : " "
              }
              ${address?.City} ${address?.State} ${address?.Zip}`, // Get address
              start: {
                dateTime: dates[i].Date_Time_Start,
                timeZone: props.offer?.TimeZone,
              },
              end: {
                dateTime: dates[i].Date_Time_End,
                timeZone: props.offer?.TimeZone,
              },
            };
          });
          const batch = gapi.client.newBatch();

          // eslint-disable-next-line
          events?.map((r, j) => {
            batch.add(
              gapi.client.calendar.events.insert({
                calendarId: "primary",
                resource: events[j],
              })
            );
          });
          batch.then(function () {
            // Maybe show success message
            // setSuccessMessage("Thank you");
          });
        });
    });
  };

  if (!props.offer) return <div />;

  return (
    <div>
      <FormInnerPlain
        func={handleClick}
        title="Add Dates To Your Google Calendar"
      />
    </div>
  );
}

export default Calendar;
