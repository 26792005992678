interface InfoDisplayProps {
  label: string;
  info: any;
  column?: boolean;
  func?: Function;
}

const InfoDisplay = (props: InfoDisplayProps) => {
  return (
    <div
      className={props.column ? "info-display__column" : "info-display"}
      onClick={(e) => {
        if (props.func) {
          props.func(e);
        }
      }}
    >
      <label className="info-display__label">{props.label}</label>
      <div className={`${props.func && "info-display__link"}`}>
        {props.info}
      </div>
    </div>
  );
};

export default InfoDisplay;
