import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AccountComp from "../Components/Account/AccountComp";
import { useAppSelector } from "../Redux/Hooks";
import OfferList from "../UserComponents/OfferDisplay/OfferList";

const MyOffersPage = () => {
  const { active } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!active) navigate("/");
  }, [active]);
  return (
    <>
      <OfferList search="myoffers" />
    </>
  );
};

export default MyOffersPage;
