interface FormSubmitButtonProps {
  disabled?: boolean;
  title?: string;
}

const FormSubmitButton = (props: FormSubmitButtonProps) => {
  return (
    <div className="forms__submit-container">
      <div className="forms__submit-container--sizer">
        <input
          type="submit"
          className="forms__submit"
          value={props.title ? props.title : "Submit"}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default FormSubmitButton;
