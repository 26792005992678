import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ManageOffers from "../Components/Offer/ManageOffers";
import { useAppSelector } from "../Redux/Hooks";

const ManageOffersPage = () => {
  const { accountType, addresses } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (accountType !== 2) navigate("/login");
    if (addresses.length === 0) navigate("/account");
  }, [accountType]);
  return <ManageOffers />;
};

export default ManageOffersPage;
