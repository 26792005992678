import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changeBookingEmail } from "../../API/bookingApi";
import {
  activateOffer,
  cancelOffer,
  GetOfferersOffers,
} from "../../API/offersApi";
import useElementInView from "../../CustomHooks/useElementInView";
import {
  BookingInterface,
  OfferInterface,
  PositionsInterface,
} from "../../Interfaces/OfferInterface";
import { useAppSelector } from "../../Redux/Hooks";
import BookingDetails from "../Booking/BookingDetails";
import BookingManagement from "../Booking/BookingManagement";
import FormInnerButton from "../Forms/FormInnerButton";
import FormSimpleToggle from "../Forms/FormSimpleToggle";
import InfoDisplay from "../Forms/InfoDisplay";
import Modal from "../Modal/Modal";

const loadNum = 100;

const ManageOffers = () => {
  const { leaders, accountType, slug } = useAppSelector((state) => state.user);
  const [offers, setOffers] = useState<OfferInterface[]>([]);
  const [offersPast, setOffersPast] = useState<OfferInterface[]>([]);
  const [clicked, setClicked] = useState<OfferInterface>();
  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [toggleOfferPast, setToggleOfferPast] = useState("Current");
  const [noMoreOffers, setNoMoreOffers] = useState(false);
  const [noMoreOffersPast, setNoMoreOffersPast] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(0);
  const [showActivateModal, setShowActivateModal] = useState(0);
  const [showTooOldModal, setShowTooOldModal] = useState(false);

  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);
  const loadMore = useElementInView(ref);

  useEffect(() => {
    // Gets all offers
    if (accountType === 2) getAllOffers();
    else navigate("../");
  }, []);

  useEffect(() => {
    // Working example to do pagination, will cause extra api calls for now
    if (loadMore || toggleOfferPast === "Current") {
      if (accountType === 2) getAllOffers();
      else navigate("../");
    }
  }, [loadMore, toggleOfferPast]);

  const findPage = (paginationAmount: number, offs: OfferInterface[]) => {
    const currentOffersAmount = offs.length;
    if (currentOffersAmount === 0) return 1;
    if (currentOffersAmount % paginationAmount) {
      return -1;
    }
    const pageNum = currentOffersAmount / paginationAmount;
    return pageNum + 1;
  };

  // const updateEmail = async (id: number) => {
  //   const res = await changeBookingEmail({
  //     Booking_ID: id,
  //     Filled_By_Email: email,
  //   });
  //   if (res.error) {
  //     setChangeEmailError("Change Failed");
  //   } else {
  //     console.log(offers);
  //     getAllOffers();
  //     setChangeEmailClick(false);
  //     const clicker = clicked;
  //     if (clicker?.Bookings) {
  //       for (let i = 0; i < clicker.Bookings.length; i++) {
  //         if (clicker.Bookings[i].ID === id)
  //           clicker.Bookings[i].Filled_By_Email = email;
  //       }
  //     }
  //     setClicked(clicker);
  //   }
  // };

  const getAllOffers = async (override = false, load = loadNum) => {
    // Find page for pagination
    const pageNum = findPage(load, offers);
    if (pageNum === -1) {
      setNoMoreOffers(true);
      return;
    }
    // Get upcoming
    const list = await GetOfferersOffers({
      pageNo: override ? 1 : pageNum,
      loadAmount: load,
      startedOffers: false,
    });

    if (list?.offers) {
      const updatedList = [...offers, ...list.offers];
      setOffers(updatedList);
      if (list.offers.length < load) {
        setNoMoreOffers(true);
      }
    } else {
      setNoMoreOffers(true);
    }

    // Find page for pagination past offers
    const pageNumPast = findPage(load, offersPast);
    if (pageNumPast === -1) {
      setNoMoreOffersPast(true);
      return;
    }

    // Get past
    const listTwo = await GetOfferersOffers({
      pageNo: override ? 1 : pageNum,
      loadAmount: load,
      startedOffers: true,
    });

    if (listTwo?.offers) {
      const updatedList = [...offersPast, ...listTwo.offers];
      setOffersPast(updatedList);
      if (listTwo.offers.length < load) {
        setNoMoreOffersPast(true);
      }
    } else {
      setNoMoreOffersPast(true);
    }
  };

  const findLeader = (id: number) => {
    const filt = [...leaders].filter((el) => el.ID === id);
    return filt[0].Name;
  };

  const simpleAddStrings = (arr: string[]) => {
    let summer = 0;
    for (let i = 0; i < arr.length; i++) {
      summer += parseInt(arr[i]);
    }
    return summer;
  };

  const addPrices = (
    offerPrice: number,
    posArr: PositionsInterface[],
    checkSold: boolean = false
  ) => {
    let sum = 0;
    for (let i = 0; i < posArr.length; i++) {
      if (
        (posArr[i].Price_Override !== "-1" ||
          parseInt(posArr[i].Price_Override) !== -1) &&
        parseInt(posArr[i].Price_Override) >= 0
      ) {
        if (checkSold) {
          sum +=
            parseInt(posArr[i].Price_Override) *
            parseInt(posArr[i].Amount_Filled);
        } else {
          sum +=
            parseInt(posArr[i].Price_Override) *
            parseInt(posArr[i].Amount_Available);
        }
      } else {
        if (checkSold) {
          sum += offerPrice * parseInt(posArr[i].Amount_Filled);
        } else {
          sum += offerPrice * parseInt(posArr[i].Amount_Available);
        }
      }
    }
    return sum;
  };

  const payNowClicks = (bookArr: BookingInterface[]) => {
    let leads = 0,
      emails: string[] = [];
    for (let i = 0; i < bookArr.length; i++) {
      if (!bookArr[i].Paid && bookArr[i].Filled_By_Email) {
        leads += 1;
        emails.push(bookArr[i].Filled_By_Email);
      }
    }
    return { leads, emails };
  };

  const curOffers = toggleOfferPast === "Current" ? offers : offersPast;

  const list = curOffers.map((el, i) => {
    const amountAvail = [...el.Positions].map((el) => {
      return el.Amount_Available;
    });
    const amountFilled = [...el.Positions].map((el) => {
      return el.Amount_Filled;
    });
    const sumAvail = simpleAddStrings(amountAvail);
    const sumFilled = simpleAddStrings(amountFilled);
    const potentialIncome = addPrices(el.Price, [...el.Positions]);
    const currentSold = addPrices(el.Price, [...el.Positions], true);
    const { leads } = payNowClicks(el.Bookings);
    return (
      <div className="manage-offers__item--container" key={i}>
        <div className="manage-offers__title--container">
          <div className="manage-offers__forum-link--container manage-offers__forum-button">
            <div
              className="nav-button"
              onClick={() => {
                if (
                  parseISO(el.Dates[el.Dates.length - 1].Date_Time_Start) <
                  new Date(Date.now())
                ) {
                  setShowTooOldModal(true);
                  return;
                }
                el.Cancelled
                  ? setShowActivateModal(el.ID)
                  : setShowCancelModal(el.ID);
              }}
            >
              {el.Cancelled ? "Activate Offer" : "Cancel Offer"}
            </div>
          </div>
          <div className="manage-offers__title">{el.Name}</div>
          <div className="manage-offers__forum-link--container manage-offers__forum-button">
            <div
              className="nav-button"
              onClick={() => {
                navigate(`../forum/${el.ID}`);
              }}
            >
              Visit Forum
            </div>
          </div>
        </div>
        <div
          className="manage-offers__item"
          key={i}
          onClick={() => {
            setClicked(el);
            setShowModalTwo(true);
          }}
        >
          <InfoDisplay
            label={"Status: "}
            info={el.Cancelled ? "Cancelled" : "Active"}
          />
          <InfoDisplay label={"Price: "} info={`$${el.Price}`} />
          <InfoDisplay label={"Leader: "} info={findLeader(el.Lead_ID)} />
          <InfoDisplay label={"Available: "} info={sumAvail} />
          <InfoDisplay
            label={"Booked: "}
            info={sumFilled}
            func={(e: React.SyntheticEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setClicked(el);
              setShowModalTwo(true);
            }}
          />
          <InfoDisplay
            label={"Starts: "}
            info={`${format(
              parseISO(el.Dates[0].Date_Time_Start),
              "EEEE, LLLL eo, yyyy hh:mm a"
            )}`}
          />
          <InfoDisplay label={"Current: "} info={`$${currentSold}`} />
          <InfoDisplay label={"Potential: "} info={`$${potentialIncome}`} />
          <InfoDisplay
            func={(e: React.SyntheticEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setClicked(el);
              setShowModal(true);
            }}
            label={"Leads: "}
            info={`${leads}`}
          />
        </div>
        <FormInnerButton
          func={() => {
            navigate(`/editoffer/${el.ID}`);
          }}
          title="Edit Offer"
          attached
        />
      </div>
    );
  });

  const onCloseModal = () => {
    setShowModal(false);
    setShowModalTwo(false);
    setClicked(undefined);
    setToggleDetails(false);
  };

  const doCancel = async () => {
    await cancelOffer({ id: showCancelModal });
    setOffers([]);
    getAllOffers(true);
    setShowCancelModal(0);
  };

  const doActivate = async () => {
    await activateOffer({ id: showActivateModal });
    setOffers([]);
    getAllOffers(true);
    setShowActivateModal(0);
  };

  const findPositionName = (id: number) => {
    let found = [];
    if (clicked) {
      found = [...clicked.Positions].filter((el) => el.ID === id);
      return found[0].Position_Name;
    }
    return "";
  };

  const leads = (paid: boolean = false) => {
    if (clicked) {
      let arr = clicked ? [...clicked.Bookings] : [];
      if (arr.length > 0) {
        return arr
          .filter((el) => el.Paid === paid)
          .map((el, i) => {
            el.Position.Position_Name = findPositionName(el.Position_ID);
            return (
              <div key={el.ID}>
                <BookingDetails bookProp={el} viewOnly />
              </div>
              // <div key={el.ID} className={`manage-offers__list`}>
              //   <InfoDisplay
              //     label={"Position: "}
              //     info={findPositionName(el.Position_ID)}
              //     column
              //   />
              //   <InfoDisplay label={"Name: "} info={el.Filled_By_Name} column />
              //   <div>
              //     {changeEmailClick ? (
              //       <div>
              //         <FormField
              //           label="email"
              //           placeholder="email"
              //           value={email}
              //           func={setEmail}
              //           required={true}
              //           type="email"
              //           disabled={false}
              //           // error={formErrors.businessName}
              //         />
              //         <div className="flex justify-space-between">
              //           <div
              //             className="link-text color-danger"
              //             //"manage-offers__clicker"
              //             onClick={() => {
              //               setChangeEmailClick(!changeEmailClick);
              //             }}
              //           >
              //             cancel
              //           </div>
              //           <div
              //             className="link-text"
              //             //"manage-offers__clicker"
              //             onClick={() => {
              //               updateEmail(el.ID);
              //             }}
              //           >
              //             submit change
              //           </div>
              //         </div>
              //         {changeEmailError && (
              //           <div className="text-danger text-center">
              //             {changeEmailError}
              //           </div>
              //         )}
              //       </div>
              //     ) : (
              //       <div>
              //         <InfoDisplay
              //           label={"Email: "}
              //           info={el.Filled_By_Email}
              //           column
              //         />
              //         {el.Paid && (
              //           <div
              //             className="link-text"
              //             //"manage-offers__clicker"
              //             onClick={() => {
              //               setChangeEmailClick(!changeEmailClick);
              //             }}
              //           >
              //             change
              //           </div>
              //         )}
              //       </div>
              //     )}
              //   </div>
              //   <InfoDisplay label={"Phone: "} info={el.Phone} column />
              //   <InfoDisplay
              //     label={"Paid: "}
              //     info={el.Paid ? "Yes" : "No"}
              //     column
              //   />
              //   <InfoDisplay
              //     label={"Received: "}
              //     info={`$${
              //       el.Amount_Received
              //         ? (el.Amount_Received / 100).toFixed(0)
              //         : 0
              //     }`}
              //     column
              //   />
              //   <InfoDisplay
              //     label={"Price: "}
              //     info={"$" + el.Price_Paid}
              //     column
              //   />
              //   <InfoDisplay
              //     label={"Cancelled: "}
              //     info={el.Cancelled ? "Yes" : "No"}
              //     column
              //   />
              //   <InfoDisplay
              //     label={"Refunded: "}
              //     info={"$" + el.Refunded}
              //     column
              //   />
              //   <InfoDisplay
              //     label={"Created: "}
              //     info={formatDateForClient(el.CreatedAt)}
              //     column
              //   />
              //   <InfoDisplay label={"Info: "} info={el.Info} column />
              // </div>
            );
          });
      } else return <h2 className="mt-3 mb-2 center">No Bookings Found</h2>;
    } else return [];
  };

  interface PositionCustInterface {
    id: number;
    posName: string;
    bookingName: string;
    bookingEmail: string;
    bookingPhone: string;
  }

  const positionCust = () => {
    let posArr = [],
      rep = 0,
      id = 0,
      posObj: PositionCustInterface = {
        id: 0,
        posName: "",
        bookingName: "No Customer",
        bookingEmail: "N/A",
        bookingPhone: "N/A",
      };
    if (clicked) {
      let booked = [...clicked.Bookings],
        filtBook = [];
      for (let i = 0; i < clicked.Positions.length; i++) {
        // Set booked with all clicked bookings
        booked = [...clicked.Bookings];
        // Find amount available for looping
        rep = parseInt(clicked.Positions[i].Amount_Available);
        // Get the position ID
        // eslint-disable-next-line
        id = clicked.Positions[i].ID!;
        // Use the position ID to find paid bookings
        filtBook = booked.filter(
          // eslint-disable-next-line
          (el) => el.Position_ID === id && el.Paid === true
        );
        // Loop through rep and apply bookings
        for (let j = 0; j < rep; j++) {
          // Reset posObj
          posObj = {
            id: 0,
            posName: "",
            bookingName: "No Customer",
            bookingEmail: "N/A",
            bookingPhone: "N/A",
          };
          posObj.id = clicked.Positions[i].ID!;
          posObj.posName = clicked.Positions[i].Position_Name;
          posObj.bookingName = filtBook[j]?.Filled_By_Name
            ? filtBook[j].Filled_By_Name
            : "No Customer";
          posObj.bookingEmail = filtBook[j]?.Filled_By_Email
            ? filtBook[j].Filled_By_Email
            : "N/A";
          posObj.bookingPhone = filtBook[j]?.Phone ? filtBook[j].Phone : "N/A";
          // Add to posArr
          posArr.push(posObj);
        }
      }
    }
    return posArr.map((el, i) => {
      return (
        <div
          key={el.id + "-" + i}
          className={`manage-offers__list ${
            el.bookingEmail !== "N/A"
              ? "background__light-blue"
              : "background__light-gray"
          }`}
        >
          <InfoDisplay label={"Position: "} info={el.posName} column />
          <InfoDisplay label={"Cust Name: "} info={el.bookingName} column />
          <InfoDisplay label={"Cust Email: "} info={el.bookingEmail} column />
          <InfoDisplay label={"Cust Phone: "} info={el.bookingPhone} column />
        </div>
      );
    });
  };

  const endOffers =
    toggleOfferPast === "Current" ? noMoreOffers : noMoreOffersPast;

  return (
    <div>
      <div className="title">Manage Offers</div>
      <div className="link-text m-3 center">
        <Link className="signup__link" to={`../offers/${slug}`} relative="path">
          View active offers as a user
        </Link>
      </div>
      <div className="width center">
        <div className="width">
          <FormSimpleToggle
            value={toggleOfferPast}
            func={(val: string) => {
              setToggleOfferPast(val);
            }}
            left="Current"
            center="Past"
            right="Bookings"
          />
        </div>
      </div>
      {(toggleOfferPast === "Current" || toggleOfferPast === "Past") && (
        <div>{list}</div>
      )}
      {(toggleOfferPast === "Current" || toggleOfferPast === "Past") && (
        <div ref={ref} className="offer-list__arrow">
          {endOffers ? (
            "End of offers"
          ) : (
            <FontAwesomeIcon
              icon={icon({ name: "arrow-down", style: "solid" })}
            />
          )}
        </div>
      )}
      {toggleOfferPast === "Bookings" && <BookingManagement />}
      <Modal
        title={
          `${clicked?.Name} ${showModalTwo ? "- Signups" : "- Leads"}` || ""
        }
        show={showModal || showModalTwo}
        setShow={onCloseModal}
        overflow
      >
        <div className="manage-offers__modal">
          {showModal && (
            <p className="mt-1 mb-2">
              <em>
                Leads are generated when an app user clicks pay now but does not
                complete the purchase
              </em>
            </p>
          )}
          {showModalTwo && (
            <div className="manage-offers__toggle">
              <div
                onClick={() => setToggleDetails(false)}
                className={
                  toggleDetails
                    ? "manage-offers__toggle--button"
                    : "manage-offers__toggle--button manage-offers__toggle--button--on"
                }
              >
                Overview
              </div>
              <div
                onClick={() => setToggleDetails(true)}
                className={
                  toggleDetails
                    ? "manage-offers__toggle--button manage-offers__toggle--button--on"
                    : "manage-offers__toggle--button"
                }
              >
                Detailed Bookings
              </div>
            </div>
          )}
          {toggleDetails
            ? leads(showModalTwo)
            : !showModalTwo
            ? leads()
            : positionCust()}
        </div>
      </Modal>
      <Modal
        title={showActivateModal ? "Activate Offer" : "Cancel Offer"}
        show={showCancelModal > 0 ? true : showActivateModal ? true : false}
        setShow={() => {
          if (showActivateModal) setShowActivateModal(0);
          else setShowCancelModal(0);
        }}
        overflow
      >
        <div className="manage-offers__modal">
          <p className="mt-1 mb-2">
            {showActivateModal
              ? "You are about to activate this offer."
              : "You are about to cancel this offer. If you have any bookings, they must be refunded."}
          </p>
          <div className="forms__button-container">
            <div
              className="forms__submit--plain"
              onClick={() => {
                if (showActivateModal) doActivate();
                else doCancel();
              }}
            >
              {showActivateModal ? "Activate Offer" : "Cancel Offer"}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={"Offer already ended"}
        show={showTooOldModal}
        setShow={() => {
          setShowTooOldModal(false);
        }}
        overflow
      >
        <div className="manage-offers__modal">
          <p className="mt-1 mb-2">This offer has already ended.</p>
        </div>
      </Modal>
    </div>
  );
};

export default ManageOffers;
