import { useState } from "react";
import { resetAddress } from "../../Redux/AddressReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import AddressEdit from "./AddressEdit";

interface AddressCompProps {
  inline?: boolean;
}

const AddressComp = (props: AddressCompProps) => {
  const { addressLineOne, addressLineTwo, city, state, country, zip } =
    useAppSelector((state) => state.user);
  const addressRed = useAppSelector((state) => state.address);
  const [showModal, setShowModal] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [success, setSuccess] = useState("");
  const dispatch = useAppDispatch();

  const onCloseModal = (showSuccess: boolean = false) => {
    setShowModal(false);
    setNewAddress(false);
    dispatch(resetAddress());
    if (showSuccess) setSuccess("Address List Update Successful");
    else setSuccess("");
  };

  const onBackClick = (showSuccess: boolean = false) => {
    setNewAddress(false);
    dispatch(resetAddress());
    if (showSuccess) setSuccess("Address List Update Successful");
    else setSuccess("");
  };
  return (
    <>
      {props.inline ? (
        <span
          className="forms__field--link"
          onClick={() => {
            setShowModal(!showModal);
            setSuccess("");
          }}
        >
          View/Edit Addresses
        </span>
      ) : (
        <>
          <div className="address__comp">
            <span className="mr-1">
              <strong>Primary Address: </strong>
            </span>
            {addressLineOne ? (
              `${" "} ${addressLineOne}${
                addressLineTwo ? " " + addressLineTwo : ""
              }, ${city} ${state} ${zip}, ${country}`
            ) : (
              <div className="text-danger text-margin">
                <strong>
                  No primary address. Accounts without a default address are
                  limited. Please add an address by clicking "view/edit
                  addresses"
                </strong>
              </div>
            )}
          </div>
          <div
            className="address__comp--link"
            onClick={() => {
              setShowModal(!showModal);
              setSuccess("");
            }}
          >
            View/Edit Addresses
          </div>
        </>
      )}
      <AddressEdit
        newAddress={newAddress}
        addressRed={addressRed.addressLineOne}
        onBackClick={onBackClick}
        showModal={showModal}
        onCloseModal={onCloseModal}
        setNewAddress={setNewAddress}
        success={success}
      />
    </>
  );
};

export default AddressComp;
