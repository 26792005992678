import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { createForumAnnouncement } from "../../API/forumApi";
import { ForumInterface } from "../../Interfaces/ForumInterface";
import { OfferInterface } from "../../Interfaces/OfferInterface";
import FormField from "../Forms/FormField";
import FormSubmitButton from "../Forms/FormSubmitButton";
import Modal from "../Modal/Modal";

interface ForumOfferFormProps {
  offer: OfferInterface;
  cb: Function;
  post?: ForumInterface;
  unClickPost: Function;
}

const ForumOfferForm = (props: ForumOfferFormProps) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.post?.ID) {
      setName(props.post?.Title);
      setDescription(props.post?.Description);
      setShowModal(true);
    } else {
      setName("");
      setDescription("");
    }
  }, [props.post?.ID]);

  const doSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const annos = await createForumAnnouncement({
      id: props.post?.ID ? props.post.ID : 0,
      offerId: props.offer.ID,
      title: name,
      description: description,
    });
    props.cb(annos.posts);
    setShowModal(false);
  };

  const onCloseModal = () => {
    setName("");
    setDescription("");
    props.unClickPost();
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <Modal title="Forum Post" show={showModal} setShow={onCloseModal}>
          <div className="forum__post-form--container">
            <form onSubmit={(e) => doSubmit(e)} className="forum__post-form">
              <div className="mt-2 mb-2 forum__post-form--heading">
                <h2 className="text-title__small color-primary">
                  {props.post?.ID ? (
                    <div className="flex">
                      <div
                        className="mr-1 cursor-pointer"
                        onClick={() => props.unClickPost()}
                      >
                        <FontAwesomeIcon
                          icon={icon({ name: "arrow-left", style: "solid" })}
                        />
                      </div>
                      Edit Post
                    </div>
                  ) : (
                    "Make a Post"
                  )}
                </h2>
                <a
                  className="link-text font-size-14"
                  href="https://www.markdownguide.org/basic-syntax"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Markdown Info
                </a>
              </div>

              <div className="">
                <div className="mb-2">
                  <FormField
                    label="Post Title"
                    type="text"
                    placeholder="Group Whatsapp Link"
                    value={name}
                    func={setName}
                    disabled={false}
                    required={true}
                  />
                </div>
                <FormField
                  label="Post Description"
                  placeholder="Use markdown to add any text or links you would like"
                  name="Description"
                  value={description}
                  func={setDescription}
                  type="text"
                  disabled={false}
                  required={true}
                  textArea={true}
                />
              </div>
              <div className="offer-info__lead-modal ">
                <div className="forms__title mt-2 mb-1">Post Preview</div>

                <div className="forum__item">
                  <div className="forum__item--top">
                    <div className="forum__item--title">{name}</div>
                  </div>
                  <ReactMarkdown
                    className="mark-down font-size-14"
                    linkTarget="_blank"
                  >
                    {description}
                  </ReactMarkdown>
                </div>
              </div>
              <div className="mb-2">
                <FormSubmitButton />
              </div>
            </form>
          </div>
        </Modal>
      ) : (
        <div
          className="link-text font-size-16"
          onClick={() => setShowModal(true)}
        >
          Create New Post
        </div>
      )}
    </>
  );
};

export default ForumOfferForm;
