import { useEffect, useState } from "react";

const useElementInView = (ref: React.RefObject<HTMLDivElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current) observer.observe(ref.current!);

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return isIntersecting;
};

export default useElementInView;
