import { SyntheticEvent, useEffect, useState } from "react";
import Modal from "../Modal/Modal";

type NameAndValueFunc = (name: string, value: string) => void;

interface FormFieldProps {
  label: string;
  name?: string;
  value: string;
  placeholder?: string;
  required: boolean;
  type?: string;
  disabled?: boolean;
  error?: string;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  country?: string;
  textArea?: boolean;
  min?: number;
  modalTitle?: string;
  modalInfo?: string;
  nameAndValueFunc?: NameAndValueFunc;
  func?: Function;
  linkText?: string;
  linkFunc?: Function;
}

const defaultProps = { type: "text", disabled: false, minLength: 0 };

const FormField = (props: FormFieldProps) => {
  const errorStyle = {
    border: "2px solid #dc3545",
  };

  const [curVal, setCurVal] = useState({ value: props.value, check: false });

  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (props.country !== "United States") return;
    let val = props.value,
      valFixed: string = "";
    // Format as pattern requires
    if (props.type === "tel" && curVal.value !== props.value) {
      for (let i = 0; i < val.length; i++) {
        if (i === 3 || i === 7) {
          if (val[i] !== "-") valFixed = valFixed + "-";
        }
        valFixed = valFixed + val[i];
      }

      // Account for google autofill
      if (valFixed[7] !== "-" && valFixed[8] === "-") {
        let valFlip: string[] = valFixed.split("");

        [valFlip[7], valFlip[8]] = [valFlip[8], valFlip[7]];
        valFixed = valFlip.join("");
      }

      // Disallow extra numbers
      while (valFixed.length > 12) {
        valFixed = valFixed.slice(0, -1);
      }
      setCurVal({ value: valFixed, check: true });
    }
  }, [props.value]);

  useEffect(() => {
    if (curVal.value !== props.value || curVal.check) {
      setCurVal({ value: curVal.value, check: false });
      if (props.func) props.func(curVal.value);
    }
  }, [curVal.check]);

  if (props.textArea) {
    return (
      <div className="forms__field">
        <label htmlFor={props.label}>
          {props.label + (props.required ? " *" : "")}
        </label>
        {props.modalTitle && (
          <span
            className="forms__field--link"
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            Info
          </span>
        )}
        <textarea
          id={props.label}
          disabled={props.disabled}
          name={props.name}
          required={props.required}
          minLength={props.minLength}
          maxLength={props.maxLength || 2000}
          style={props.error ? errorStyle : {}}
          placeholder={props.placeholder || ""}
          value={props.value}
          onChange={(e) => {
            // Check for nameAndValueFunc for objects
            if (props.nameAndValueFunc)
              props.nameAndValueFunc(e.target.name, e.target.value);
            else if (props.func) props.func(e.target.value);
          }}
        />
        {props.error && (
          <span className="forms__field--error">{props.error}</span>
        )}
        <Modal title={props.modalTitle} show={showModal} setShow={onCloseModal}>
          <div className="modal__text">{props.modalInfo}</div>
        </Modal>
      </div>
    );
  }

  return (
    <div className="forms__field">
      <label htmlFor={props.label}>
        {props.label + (props.required ? " *" : "")}
      </label>
      {props.linkText && (
        <span
          className="forms__field--link"
          onClick={() => (props.linkFunc ? props.linkFunc() : null)}
        >
          {props.linkText}
        </span>
      )}
      {props.modalTitle && (
        <span
          className="forms__field--link"
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          Info
        </span>
      )}
      <input
        id={props.label}
        name={props.name}
        type={props.type}
        disabled={props.disabled}
        required={props.required}
        minLength={props.minLength}
        maxLength={props.maxLength || 200}
        style={props.error ? errorStyle : {}}
        placeholder={props.placeholder || ""}
        value={props.value}
        min={props.min || props.min === 0 ? props.min : -Infinity}
        onChange={(e) => {
          // Check for nameAndValueFunc for objects
          if (props.nameAndValueFunc)
            props.nameAndValueFunc(e.target.name, e.target.value);
          else if (props.func) props.func(e.target.value);
        }}
        pattern={props.pattern}
      />
      {props.error && (
        <span className="forms__field--error">{props.error}</span>
      )}

      <Modal title={props.modalTitle} show={showModal} setShow={onCloseModal}>
        <div className="modal__text">{props.modalInfo}</div>
      </Modal>
    </div>
  );
};

FormField.defaultProps = defaultProps;

export default FormField;
