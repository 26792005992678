import { useEffect, useState } from "react";
import {
  getBooking,
  getBookingsByEmail,
  getRecentBookings,
} from "../../API/bookingApi";
import { refundUser } from "../../API/stripeApi";
import { BookingInterface } from "../../Interfaces/OfferInterface";
import FilterContainer from "../Filters/FilterContainer";
import FormInnerPlain from "../Forms/FormButtonPlain";
import FormField from "../Forms/FormField";
import InfoDisplay from "../Forms/InfoDisplay";
import Modal from "../Modal/Modal";
import BookingDetails from "./BookingDetails";

const BookingManagement = () => {
  const [bookings, setBookings] = useState<BookingInterface[]>([]);
  const [selected, setSelected] = useState<BookingInterface>();
  const [bookingId, setBookingId] = useState("");
  const [bookingEmail, setBookingEmail] = useState("");
  const [bookingSearchList, setBookingSeearchList] = useState<
    BookingInterface[]
  >([]);
  const [searchError, setSearchError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [refundError, setRefundError] = useState("");
  const [refundSuccess, setRefundSuccess] = useState("");

  useEffect(() => {
    getBookings();
  }, []);

  useEffect(() => {
    if (bookingId) setBookingEmail("");
  }, [bookingId]);

  useEffect(() => {
    if (bookingEmail) setBookingId("");
  }, [bookingEmail]);

  const doSearch = async () => {
    setSearchError("");
    if (bookingId) {
      const res = await getBooking({ Booking_ID: parseInt(bookingId) });
      if (res.error) {
        setSearchError("Search Failed");
        return;
      }
      setBookingSeearchList([res.booking]);
    } else if (bookingEmail) {
      const res = await getBookingsByEmail({ bookingEmail: bookingEmail });
      if (res.error) {
        setSearchError("Search Failed");
        return;
      }
      setBookingSeearchList(res.booking);
    }
  };

  const getBookings = async (select: boolean = false) => {
    const res = await getRecentBookings();
    if (!res.error) {
      setBookings(res.bookings);
      if (select) {
        const sele = res.bookings.filter(
          (el: BookingInterface) => el.ID === selected?.ID
        );
        setSelected(sele);
      }
    }
  };

  const onCloseModal = () => {
    setSelected(undefined);
  };

  const searchList = bookingSearchList.map((el, i) => {
    return (
      <div
        className="enrolled__list-item"
        key={i}
        onClick={() => setSelected(el)}
      >
        {(el.Refunded > 0 || el.Cancelled) && (
          <div className="title">
            {el.Refunded > 0 && el.Cancelled
              ? "Cancelled & Refunded"
              : el.Refunded > 0
              ? "Refunded"
              : "Cancelled"}
          </div>
        )}
        <div className="manage-offers__item cursor-pointer">
          <InfoDisplay label={"Offer Name: "} info={el.Offer?.Name} column />
          <InfoDisplay
            label={"Position: "}
            info={el.Position?.Position_Name}
            column
          />
          <InfoDisplay label={"Filled By: "} info={el.Filled_By_Name} column />
          <InfoDisplay label={"Email: "} info={el.Filled_By_Email} column />
          <InfoDisplay label={"Paid: "} info={el.Price_Paid} column />
          <InfoDisplay label={"Booking ID: "} info={el.ID} column />
        </div>
      </div>
    );
  });

  const list = bookings.map((el, i) => {
    return (
      <div
        className="enrolled__list-item"
        key={i}
        onClick={() => setSelected(el)}
      >
        {(el.Refunded > 0 || el.Cancelled) && (
          <div className="title">
            {el.Refunded > 0 && el.Cancelled
              ? "Cancelled & Refunded"
              : el.Refunded > 0
              ? "Refunded"
              : "Cancelled"}
          </div>
        )}
        <div className="manage-offers__item cursor-pointer">
          <InfoDisplay label={"Offer Name: "} info={el.Offer?.Name} column />
          <InfoDisplay
            label={"Position: "}
            info={el.Position?.Position_Name}
            column
          />
          <InfoDisplay label={"Filled By: "} info={el.Filled_By_Name} column />
          <InfoDisplay label={"Email: "} info={el.Filled_By_Email} column />
          <InfoDisplay label={"Paid: "} info={el.Price_Paid} column />
          <InfoDisplay label={"Booking ID: "} info={el.ID} column />
        </div>
      </div>
    );
  });

  const doRefund = async () => {
    setRefundError("");
    setRefundSuccess("");
    const res = await refundUser({ Booking_ID: selected?.ID });
    if (res.error) {
      setRefundError(res.error);
      return;
    } else {
      setRefundSuccess(
        `Status: ${res.refund?.status} Amount Refunded: $${
          res.refund?.amount ? res.refund.amount / 100 : ""
        }`
      );
      getBookings(true);
    }
  };

  const closeRefundModal = () => {
    setShowModal(false);
    setRefundError("");
    setRefundSuccess("");
    setSelected(undefined);
  };

  return (
    <div>
      <div className="mt-4">
        <FilterContainer buttonFunc={() => doSearch()}>
          <FormField
            label="Booking Id:"
            type="number"
            disabled={false}
            required={false}
            min={0}
            name="booking id"
            value={bookingId}
            func={setBookingId}
          />
          <FormField
            label="Booking Email:"
            type="text"
            disabled={false}
            required={false}
            min={0}
            name="booking email"
            value={bookingEmail}
            func={setBookingEmail}
          />
        </FilterContainer>
        {searchError && <div className="signup__error">{searchError}</div>}
      </div>
      {bookingSearchList.length > 0 && (
        <>
          <div className="title">Search Results</div>
          <div className="">{searchList}</div>
        </>
      )}
      <div className="title">Recent Bookings</div>
      {list}
      {selected && (
        <Modal
          title="Edit Booking"
          //   backButton={newPolicy || name ? true : false}
          //   onBackButtonClick={onBackClick}
          show={selected ? true : false}
          setShow={onCloseModal}
        >
          {/* <FormSimpleToggle
            left={"Edit"}
            right={"Refund"}
            // center={"Creator"}
            value={toggle}
            func={setToggle}
          /> */}
          <BookingDetails bookProp={selected} cb={getBookings} />
          {selected.Refunded === 0 && selected.Amount_Received > 0 && (
            <FormInnerPlain
              func={() => {
                setShowModal(true);
              }}
              title="Refund Payment"
            />
          )}
        </Modal>
      )}
      {showModal && (
        <Modal
          title="Confirm Refund"
          show={showModal}
          setShow={() => closeRefundModal()}
        >
          {!refundSuccess && (
            <div className="font-size-16 center mb-2 mt-1">{`You are about to refund $${
              selected?.Amount_Received
                ? selected?.Amount_Received / 100
                : "ERROR REFUND CANNOT BE PROCESSED"
            } to ${selected?.Purchase_Email}`}</div>
          )}
          {!refundSuccess && selected?.Refunded === 0 && (
            <FormInnerPlain
              func={() => {
                doRefund();
              }}
              title="Refund Payment"
            />
          )}
          {refundError && <div className="signup__error">{refundError}</div>}
          {refundSuccess && (
            <div className="font-size-20 center mt-2 mb-1 color-primary">
              {refundSuccess}
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default BookingManagement;
