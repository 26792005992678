import OnBoardingForm from "./OnBoardingForm";

const AccountComp = () => {
  return (
    <div>
      <div className="title">Account</div>
      <OnBoardingForm />
    </div>
  );
};

export default AccountComp;
