import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkPayment } from "../../API/stripeApi";
import { BookingInterface } from "../../Interfaces/OfferInterface";
import PaymentSuccess from "./PaymentSuccess";

const PaymentComplete = () => {
  const [success, setSuccess] = useState("");
  const [booking, setBooking] = useState<BookingInterface>();

  // Get offerid from params
  let param = useParams();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const payIntentParam = urlParams.get("payment_intent");

  useEffect(() => {
    if (param.id && payIntentParam) {
      checkForPayment();
    }
  }, [param.id]);

  const checkForPayment = async () => {
    const res = await checkPayment({
      // eslint-disable-next-line
      offerId: parseInt(param.id!),
      invoiceId: payIntentParam,
    });
    if (res.found && !res.cancelled && !res.refunded) {
      setSuccess("Payment Successful");
      setBooking(res.booking);
    } else if (res.refunded) {
      setSuccess("Payment Refunded");
    } else setSuccess("Payment Failed");
  };

  if (!success) return <div></div>;
  else if (success === "Payment Failed") {
    return (
      <div className="header-text page-center color-danger">
        <div>Payment Failed</div>
        <div className="subtitle-text color-dark mt-2">
          The payment could not be processed. Please follow the link below to
          try again.
        </div>
        <div
          className="link-text mt-2"
          onClick={() => navigate(`../checkout/${param.id}`)}
        >
          Back to offer
        </div>
      </div>
    );
  } else if (success === "Payment Refunded") {
    return (
      <div className="header-text page-center color-danger">
        <div>Payment Refunded</div>
        <div className="subtitle-text color-dark mt-2">
          The position you were attempting to book, was booked before your
          payment was complete. Sorry for the inconvenience. YOU HAVE BEEN
          ISSUED A FULL REFUND.
        </div>
        <div
          className="link-text mt-2"
          onClick={() => navigate(`../checkout/${param.id}`)}
        >
          Back to offer
        </div>
      </div>
    );
  } else if (success === "Payment Successful")
    return (
      <PaymentSuccess
        offerId={param.id}
        booking={booking}
        invoiceId={payIntentParam ? payIntentParam : ""}
      />
    );
  else
    return (
      <div>
        Booking cannot be found. Please contact service at
        Help@ThreadBooking.com
      </div>
    );
};

export default PaymentComplete;
