import { useState } from "react";
import { clickedAddress, resetAddress } from "../../Redux/AddressReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import ListContainer from "../Common/ListContainer";

interface AddressListProps {
  newAddress: boolean;
  setNewAddress: Function;
  success: string;
}

const AddressList = (props: AddressListProps) => {
  const { addresses } = useAppSelector((state) => state.user);
  const [curId, setCurId] = useState(-1);

  const dispatch = useAppDispatch();

  let addys = addresses.map((el, i) => (
    <div
      className={
        i % 2 === 0
          ? `list__item list__item--dark address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
          : `list__item address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
      }
      onClick={() => {
        if (curId === i) {
          setCurId(-1);
          dispatch(resetAddress());
          props.setNewAddress(false);
        } else {
          setCurId(i);
          dispatch(clickedAddress(el));
          props.setNewAddress(false);
        }
      }}
      key={i}
    >
      {/* <div className="address-list__item" onClick={() => dispatch(clickedAddress(el))}> */}
      {`${el.Is_Primary ? "***PRIMARY ADDRESS: " : ""} ${el.Address_Line_One}${
        el.Address_Line_Two ? " " + el.Address_Line_Two : ""
      }, ${el.City} ${el.State} ${el.Zip}`}
    </div>
  ));

  addys.unshift(
    <div
      key={-1}
      className="address-list__new address-list__item"
      onClick={() => props.setNewAddress(true)}
    >
      Add New Address
    </div>
  );

  let noAddress = false;
  if (!addresses[0]?.Address_Line_One) {
    noAddress = true;
  }

  if (noAddress) {
    delete addys[1];
  }

  return (
    <>
      <div className="forms__title--margin" />
      <div className="address__success">{props.success}</div>
      {/* <div className="forms__title--subtitle forms__title--margin">
        Addresses (click an address to edit or delete)
      </div> */}
      <ListContainer>{addys}</ListContainer>
    </>
  );
};

export default AddressList;
