import React from "react";
import { countriesArr } from "../../Helpers/addressHelpers";

interface SelectProps {
  label: string;
  value: string;
  options: string[];
  placeholder?: string;
  required: boolean;
  disabled?: boolean;
  error?: string;
  func: React.Dispatch<React.SetStateAction<string>> | Function;
}

const FormSelect = (props: SelectProps) => {
  // Fix for country code
  const onPicked = (val: string) => {
    let updatedVal = val;
    if (props.label === "Country") {
      for (let i = 0; i < countriesArr.length; i++) {
        if (countriesArr[i].country === val) {
          updatedVal = countriesArr[i].code;
          break;
        }
      }
    }
    props.func(updatedVal);
  };
  // Create the options array
  const makeSelect = () => {
    let optionArr = [];
    for (let i = 0; i < props.options.length; i++) {
      optionArr.push(
        <option
          key={i.toString() + props.options[i]}
          id={i.toString() + props.options[i]}
          value={props.options[i]}
        >
          {props.options[i]}
        </option>
      );
    }
    return (
      <select
        id={props.label}
        onChange={(e) => onPicked(e.target.value)}
        value={props.value}
      >
        {optionArr}
      </select>
    );
  };
  return (
    <div className="forms__field forms__flex forms__flex--column">
      <label htmlFor={props.label}>
        {props.label + (props.required ? " *" : "")}
      </label>
      {makeSelect()}
    </div>
  );
};

export default FormSelect;
