interface FormSimpleToggleProps {
  left: string;
  right: string;
  center?: string;
  value: string;
  func: Function;
}

const FormSimpleToggle = (props: FormSimpleToggleProps) => {
  return (
    <div className="forms__simple-toggle--container center">
      <div
        onClick={() => {
          props.func(props.left);
        }}
        className={
          props.value === props.left
            ? "forms__simple-toggle forms__simple-toggle--active"
            : "forms__simple-toggle"
        }
      >
        {props.left}
      </div>

      {props.center && (
        <div
          onClick={() => {
            props.func(props.center);
          }}
          className={
            props.value === props.center
              ? "forms__simple-toggle forms__simple-toggle--active"
              : "forms__simple-toggle"
          }
        >
          {props.center}
        </div>
      )}

      <div
        onClick={() => {
          props.func(props.right);
        }}
        className={
          props.value === props.right
            ? "forms__simple-toggle forms__simple-toggle--active"
            : "forms__simple-toggle"
        }
      >
        {props.right}
      </div>
    </div>
  );
};

export default FormSimpleToggle;
