import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStripeAccount } from "../API/stripeApi";
import StripeOnBoarding from "../Components/Account/StripeOnBoarding";
import Check from "../Components/Common/Check";
import FormButton from "../Components/Forms/FormButton";
import { useAppSelector } from "../Redux/Hooks";

const GetPaidPage = () => {
  const { email, businessName, active } = useAppSelector((state) => state.user);
  const [chargesEnabled, setChargesEnabled] = useState(false);
  const [payoutsEnabled, setPayoutsEnabled] = useState(false);
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!active) navigate("/");
  }, [active]);

  useEffect(() => {
    if (businessName) {
      getAccount();
    }
  }, [businessName]);

  const getAccount = async () => {
    const res = await getStripeAccount();
    setChargesEnabled(res?.account?.charges_enabled || false);
    setPayoutsEnabled(res?.account?.payouts_enabled || false);
    setDetailsSubmitted(res?.account?.details_submitted || false);
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToAccount = () => {
    navigate("/account");
  };

  const highlight = (val: string) => {
    if (val === "email") {
      if (!email) {
        return (
          <li className="get-paid__checklist--list-item--highlight">
            <div className="get-paid__checklist--list-item--inner-container">
              <div>Signup for an account: </div>
              <Check check={false} />
            </div>
            <FormButton label="Create Account" onClick={goToSignup} />
          </li>
        );
      } else {
        return (
          <li className="get-paid__checklist--list-item">
            <div>Signup for an account: </div>
            <Check check={true} />
          </li>
        );
      }
    }

    if (val === "businessName") {
      if (!businessName && email) {
        return (
          <li className="get-paid__checklist--list-item--highlight">
            <div className="get-paid__checklist--list-item--inner-container">
              <div>Complete company information: </div>
              <Check check={businessName ? true : false} />
            </div>
            <FormButton label="Complete Account" onClick={goToAccount} />
          </li>
        );
      } else {
        return (
          <li className="get-paid__checklist--list-item">
            <div>Complete company information: </div>
            <Check check={businessName ? true : false} />
          </li>
        );
      }
    }
    ////////
    // Fix after developing way to check account status
    ///////
    if (val === "stripe") {
      if (businessName && email && !chargesEnabled && !payoutsEnabled) {
        if (detailsSubmitted) {
          return (
            <li className="get-paid__checklist--list-item--highlight">
              <div className="get-paid__checklist--list-item--inner-container">
                <div>Create Payout Account: </div>
                <Check
                  minus={detailsSubmitted ? true : false}
                  check={chargesEnabled ? true : false}
                />
              </div>
              <div className="get-paid__checklist--extra-text">
                Details submitted! Stripe is reviewing your information (est:
                1-3 days)
              </div>
            </li>
          );
        } else
          return (
            <li className="get-paid__checklist--list-item--highlight">
              <div className="get-paid__checklist--list-item--inner-container">
                <div>Create Payout Account: </div>
                <Check check={chargesEnabled ? true : false} />
              </div>
              <StripeOnBoarding />
            </li>
          );
      } else {
        return (
          <li className="get-paid__checklist--list-item">
            <div>Create Payout Account: </div>
            <Check check={chargesEnabled ? true : false} />
          </li>
        );
      }
    }
  };
  return (
    <>
      <div className="title">Get Paid</div>
      <div className="get-paid__checklist-container">
        <ul className="get-paid__checklist">
          {highlight("email")}
          {highlight("businessName")}
          {highlight("stripe")}
        </ul>
      </div>
      <div></div>
    </>
  );
};

export default GetPaidPage;
