import { Link } from "react-router-dom";
import { LinkArr } from "./Header";

const LinkContainer = (props: LinkArr) => {
  return (
    <Link
      to={props.linkArr[0].url || ""}
      className="header__link-container--item"
    >
      <div className="header__link">{props.title}</div>
    </Link>
  );
};

export default LinkContainer;
