import { ClipLoader } from "react-spinners";

interface LoadingScreenProps {
  loading: number;
}

const LoadingScreen = (props: LoadingScreenProps) => {
  if (props.loading > 0) {
    return (
      <div className="loading-screen">
        <ClipLoader
          color={"#ffffff"}
          loading={props.loading > 0 ? true : false}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingScreen;
