import {appDelete, appGet, appPost, appPut} from "./appFetch"

export const createPromoCode = async (params) => {
    return await appPost("/promos/create", params)
}

export const checkPromoCode = async (params) => {
    return await appPost("/promos/checkpromocode", params)
}


export const getPromoCodes = async (params) => {
    return await appGet("/promos/promos", params)
}

export const removePromoCode = async (params) => {
    return await appDelete("/promos/remove", params)
}

export const activatePromoCode = async (params) => {
    return await appPut("/promos/activate", params)
}