import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UserReducer from "./UserReducer";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import AddressReducer from "./AddressReducer";
import LeaderReducer from "./LeaderReducer";
import LocationReducer from "./LocationReducer";
import OfferReducer from "./OfferReducer";
import PolicyReducer from "./PolicyReducer";
import LoadingReducer from "./LoadingReducer";

const rootReducer = combineReducers({
  user: UserReducer,
  address: AddressReducer,
  leader: LeaderReducer,
  location: LocationReducer,
  offer: OfferReducer,
  policy: PolicyReducer,
  loading: LoadingReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  //  blacklist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
