import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginComp from "../Components/Authentication/LoginComp";
import { useAppSelector } from "../Redux/Hooks";

const LoginPage = () => {
  const navigate = useNavigate();
  const { email, accountType } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (email && accountType === 2) {
      navigate("../manageoffers");
    } else if (email && accountType === 1) {
      navigate("../");
    }
  }, [accountType]);
  return (
    <div className="signup-page">
      <h1 className="signup-page__title">Thread</h1>
      <LoginComp />
    </div>
  );
};

export default LoginPage;
