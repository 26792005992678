import { appFormData, appGet, appPost } from "./appFetch"

export const createOffer = async (params) => {
    return await appFormData("/offers/createoffer", params)
}

export const editOffer = async (params) => {
    return await appFormData("/offers/editoffer", params)
}

export const createDatesAndPoistions = async (params) => {
    return await appPost("/offers/createdatesandpoistions", params)
}

export const getOfferersActiveOffers = async (params) => {
    return await appGet("/offers/getofferersactiveoffers")
}

export const getOffersByOfferer = async (params) => {
    return await appGet("/offers/getoffersbyofferer", params)
}

export const GetOfferersOffers = async (params) => {
    return await appGet("/offers/getofferersoffers", params)
}

export const findOffersByLocation = async (params) => {
    return await appPost("/offers/findoffersbylocation", params)
}

export const getOfferById = async (params) => {
    return await appGet("/offers/getofferbyid", params)
}

export const getOfferByIdForum = async (params) => {
    return await appGet("/offers/getofferbyidforum", params)
}

export const getOfferByIdReview = async (params) => {
    return await appGet("/offers/getofferbyidreview", params)
}

export const cancelOffer = async (params) => {
    return await appPost("/offers/canceloffer", params)
}

export const activateOffer = async (params) => {
    return await appPost("/offers/activateoffer", params)
}

export const getEnrolled = async (params) => {
    return await appGet("/offers/getenrolled", params)
}

export const creatorSearch = async (params) => {
    return await appPost("/offers/creatorsearch", params)
}

export const findOffersWithFilter = async (params) => {
    return await appPost("/offers/findofferswithfilter", params)
}

export const getCustomFormFields = async (params) => {
    return await appGet("/offers/getcustomformfields", params)
}

export const getCtaInfo = async (params) => {
    return await appGet("/offers/getctainfo", params)
}