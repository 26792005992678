interface FormButtonProps {
  label: string;
  onClick: Function;
}

const FormButton = (props: FormButtonProps) => {
  return (
    <div className="forms__submit-container">
      <div className="forms__submit-container--sizer">
        <div className="forms__submit" onClick={() => props.onClick()}>
          {props.label}
        </div>
      </div>
    </div>
  );
};

export default FormButton;
