import useWindowSize from "../../CustomHooks/useWindowSize";
import { minutesToFormattedTime } from "../../Helpers/dateHelpers";
import {
  dateInterfaceToSingleDateArr,
  durationMaker,
} from "../../Helpers/offerHelpers";
import {
  OfferCompGiven,
  OfferInterface,
  SingleDate,
  UserForOfferInterface,
} from "../../Interfaces/OfferInterface";
import { useAppSelector } from "../../Redux/Hooks";
import OfferImage from "./OfferImage";
import OfferInfo from "./OfferInfo";

interface OfferCompCustProps {
  stateObj?: OfferCompGiven;
  listObj?: OfferInterface;
  distance?: string;
  owner?: UserForOfferInterface;
}

const OfferCompCust = (props: OfferCompCustProps) => {
  const { addresses } = useAppSelector((state) => state.user);
  const sampleComp = props.stateObj ? true : false;

  let dateArr: SingleDate[] | undefined;

  if (!sampleComp && props.listObj)
    dateArr = dateInterfaceToSingleDateArr(props.listObj?.Dates);

  let name = sampleComp ? props.stateObj?.name : props.listObj?.Name,
    addyId =
      sampleComp && props.stateObj?.addyId
        ? parseInt(props.stateObj?.addyId)
        : props.listObj?.Address_ID,
    description = sampleComp
      ? props.stateObj?.description
      : props.listObj?.Description,
    price = sampleComp
      ? props.stateObj?.price
      : props.listObj?.Price
      ? props.listObj?.Price.toString()
      : "0",
    // bookAfterStartDate = sampleComp
    //   ? props.stateObj?.bookAfterStartDate
    //   : props.listObj?.Book_After_Start_Date,
    // displayAfterStartDate = sampleComp
    //   ? props.stateObj?.displayAfterStartDate
    //   : props.listObj?.Display_After_Start_Date,
    // active = sampleComp ? props.stateObj?.active : props.listObj?.Active,
    lead = sampleComp ? props.stateObj?.lead : props.listObj?.Lead_ID,
    position = sampleComp ? props.stateObj?.position : props.listObj?.Positions,
    // timeZone = sampleComp ? props.stateObj?.timeZone : props.listObj?.TimeZone,
    dates = sampleComp ? props.stateObj?.dates : dateArr,
    imagePreview =
      sampleComp && props.stateObj?.imagePreview
        ? props.stateObj?.imagePreview
        : props.listObj?.Image_Url
        ? props.listObj?.Image_Url
        : "";
  // imageKey = props.listObj?.Image_Key;
  const addressObj = [...addresses].filter((el) => el.ID === addyId)[0];

  let addressString = "";

  if (addressObj?.Address_Line_One) {
    addressString = `${addressObj.Address_Line_One}${
      addressObj.Address_Line_Two ? " " + addressObj.Address_Line_Two : ""
    }, ${addressObj.City} ${addressObj.State} ${addressObj.Zip}`;
  }

  const { width } = useWindowSize();

  const datesSorted = dates?.sort((a, b) => {
    if (a > b) return 1;
    else if (b > a) return -1;
    else return 0;
  });

  const startDate =
    datesSorted && datesSorted?.length > 0 ? datesSorted![0].startSession : "";
  const duration =
    datesSorted && datesSorted?.length > 0
      ? minutesToFormattedTime(datesSorted![0].duration)
      : "00:00";
  const endDate =
    datesSorted && datesSorted?.length > 0
      ? datesSorted![datesSorted?.length! - 1].startSession
      : "";
  const dateCount = datesSorted?.length;
  const { durationHours, durationMinutes } = durationMaker(duration);
  // const durationArr = duration.split(":");
  // const durationHours = parseInt(durationArr[0]);
  // const durationMinutes = parseInt(durationArr[1]);

  return (
    <>
      {sampleComp && (
        <div className="offer-cust__top-margin offer-cust__title offer-cust__title--highlight">
          {width > 992
            ? "Full Screen Preview"
            : width > 596
            ? "Tablet Screen Preview"
            : "Phone Screen Preview"}
        </div>
      )}
      <div className="offer-cust">
        {/* {width < 993 && <div className="offer-cust__title">{name}</div>} */}
        {/* <div className="offer-cust__title">{name}</div> */}
        <div className="offer-cust__side-by-side">
          <OfferImage
            img={imagePreview}
            distance={props.distance}
            owner={props.owner}
          />
          <OfferInfo
            offer={props.listObj}
            offerId={props.listObj?.ID}
            lead={lead}
            startDate={startDate}
            endDate={endDate}
            dateCount={dateCount}
            dates={dates}
            durationHours={durationHours}
            durationMinutes={durationMinutes}
            price={price}
            name={name}
            address={addressString}
            description={description}
            positions={position}
            buttonClick={props.listObj?.Name ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default OfferCompCust;
