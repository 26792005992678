import React from "react";
import { LeaderInterface } from "../../Interfaces/LeaderInterface";
import { PolicyInterface } from "../../Interfaces/PolicyInterface";
import CancellationPolicy from "../Account/CancellationPolicy";
import OfferLeader from "../Account/OfferLeader";

interface SelectProps {
  label: string;
  value: number;
  options: LeaderInterface[] | PolicyInterface[];
  required: boolean;
  disabled?: boolean;
  error?: string;
  func: React.Dispatch<React.SetStateAction<number>>;
  policy?: boolean;
}

const FormLeaderSelector = (props: SelectProps) => {
  // Fix for country code

  // Create the options array
  const makeSelect = () => {
    let optionArr = [];
    for (let i = 0; i < props.options.length; i++) {
      optionArr.push(
        <option
          key={i.toString() + props.options[i]}
          id={i.toString() + props.options[i]}
          value={props.options[i].ID}
        >
          {props.options[i].Name}
        </option>
      );
    }
    return (
      <select
        id={props.label}
        onChange={(e) => {
          props.func(parseInt(e.target.value));
        }}
        value={props.value}
      >
        {optionArr}
      </select>
    );
  };
  return (
    <div className="forms__field forms__flex forms__flex--column">
      <div>
        <label htmlFor={props.label}>
          {props.label + (props.required ? " * " : "")}
        </label>
        <span className="forms__field--link">
          {props.policy ? (
            <CancellationPolicy inline />
          ) : (
            <OfferLeader inline />
          )}
        </span>
      </div>
      {makeSelect()}
    </div>
  );
};

export default FormLeaderSelector;
