import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

type AccordionProps = {
  children?: React.ReactNode;
  contentClass?: string;
  topClass?: string;
  title: string;
  startOpen?: boolean;
};

const Accordion = (props: AccordionProps) => {
  const [show, setShow] = useState(props.startOpen ? true : false);

  return (
    <div>
      <div
        onClick={() => setShow(!show)}
        className={props.topClass ? props.topClass : "accordion__top"}
      >
        <div className="accordion__title">{props.title}</div>
        <div className="accordion__icon">
          {show && (
            <FontAwesomeIcon
              icon={icon({ name: "arrow-left", style: "solid" })}
            />
          )}
          {!show && (
            <FontAwesomeIcon
              icon={icon({ name: "arrow-down", style: "solid" })}
            />
          )}
        </div>
      </div>
      {show && (
        <div
          className={
            props.contentClass ? props.contentClass : "accordion__content"
          }
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
