import Modal from "../Modal/Modal";
import CancellationPolicyList from "./CancellationPolicyList";
import CancellationPolicyForm from "./CancellationPolicyForm";

interface CancellationPolicyEditProps {
  newPolicy: boolean;
  name: string;
  onBackClick: Function;
  showModal: boolean;
  onCloseModal: Function;
  setNewPolicy: React.Dispatch<React.SetStateAction<boolean>>;
  success: string;
}

const CancellationPolicyEdit = (props: CancellationPolicyEditProps) => {
  const {
    newPolicy,
    name,
    onBackClick,
    showModal,
    onCloseModal,
    setNewPolicy,
    success,
  } = props;
  return (
    <Modal
      title={`${
        newPolicy ? "New Policy Creation" : name ? "Edit Policy" : "Policy Info"
      }`}
      backButton={newPolicy || name ? true : false}
      onBackButtonClick={onBackClick}
      show={showModal}
      setShow={onCloseModal}
    >
      {name || newPolicy ? (
        <CancellationPolicyForm newPolicy={newPolicy} back={onBackClick} />
      ) : (
        <CancellationPolicyList
          newPolicy={newPolicy}
          setNewPolicy={setNewPolicy}
          success={success}
        />
      )}
    </Modal>
  );
};

export default CancellationPolicyEdit;
