import { useEffect, useState } from "react";
import { isPrice } from "../../Helpers/regex";
import { PositionsInterface } from "../../Interfaces/OfferInterface";
import FormCheckBox from "../Forms/FormCheckBox";
import FormField from "../Forms/FormField";
import FormInnerButton from "../Forms/FormInnerButton";
import PositionsList from "./PositionsList";

interface PositionProps {
  fillPosition?: boolean;
  position: PositionsInterface[];
  setPosition: React.Dispatch<React.SetStateAction<PositionsInterface[]>>;
}

const PositionsComp = (props: PositionProps) => {
  const positionObj = {
    Local_Id: -1,
    Position_Name: "",
    Description: "",
    Filled_By_Email: "",
    Filled_By_Name: "",
    Amount_Available: "1",
    Amount_Filled: "0",
    Price_Override: "-1",
  };

  const [currentPosition, setCurrentPosition] = useState<PositionsInterface>({
    ...positionObj,
  });

  const { position, setPosition } = props;

  const [showFillPosition, setShowFillPosition] = useState(false);
  const [showAmountAvailable, setShowAmountAvailable] = useState(false);
  const [showPositionPrice, setShowPositionPrice] = useState(false);
  const [edit, setEdit] = useState(-1);

  /* const [showFillPosition, setShowFillPosition] = useState(false)
  const [showAmountAvailable, setShowAmountAvailable] = useState(false)
    const [showPositionPrice, setShowPositionPrice] = useState(false)
  */

  useEffect(() => {
    if (showFillPosition) {
      setShowAmountAvailable(false);
      updatePosition("Amount_Available", "1");
    }
  }, [showFillPosition]);

  useEffect(() => {
    if (showAmountAvailable) {
      setShowFillPosition(false);
      updatePosition("Filled_By_Email", "");
      updatePosition("Filled_By_Name", "");
    } else {
      updatePosition("Amount_Available", "1");
    }
  }, [showAmountAvailable]);

  useEffect(() => {
    if (showPositionPrice) {
      setShowFillPosition(false);
      updatePosition(
        "Price_Override",
        `${
          parseInt(currentPosition.Price_Override) > 0
            ? currentPosition.Price_Override
            : 0
        }`
      );
    } else {
      updatePosition("Price_Override", "-1");
    }
  }, [showPositionPrice]);

  const updatePosition = (name: string, value: string) => {
    if (name === "Price_Override") {
      if (!isPrice.test(value) && value !== "" && value !== "-") return;
    }
    setCurrentPosition({ ...currentPosition, [name]: value });
  };

  const savePosition = (curPos: PositionsInterface) => {
    let posUpdate: PositionsInterface[] = [...position];
    if (posUpdate.length === 0) {
      posUpdate = [curPos];
      posUpdate[0].Local_Id = 0;
    } else {
      for (let i = 0; i < position.length; i++) {
        // Edit of position
        if (position[i].Local_Id === curPos.Local_Id) {
          posUpdate[i] = curPos;
          break;
        }
        // New Position
        else if (i === position.length - 1) {
          posUpdate.push(curPos);
          posUpdate[i + 1].Local_Id = i + 1;
          break;
        }
      }
    }
    setPosition(posUpdate);
    setCurrentPosition({ ...positionObj });
    setShowFillPosition(false);
    setShowAmountAvailable(false);
    setShowPositionPrice(false);
    setEdit(-1);
  };

  const deletePosition = (e: React.SyntheticEvent, posNum: number) => {
    e.stopPropagation();
    let posUpdate: PositionsInterface[] = [...position];
    // Locate in the array and remove
    for (let i = 0; i < position.length; i++) {
      if (posNum === posUpdate[i].Local_Id) {
        posUpdate.splice(i, 1);
        break;
      }
    }

    // for (let i = 0; i < posUpdate.length; i++) {
    //   posUpdate[i].Local_Id = i + 1;
    // }
    // After removal, calculate local ids
    posUpdate = updatePositionId(posUpdate);
    setPosition(posUpdate);
    setCurrentPosition({ ...positionObj });
    setShowFillPosition(false);
    setShowAmountAvailable(false);
    setShowPositionPrice(false);
    setEdit(-1);
  };

  const getPositionToEdit = (num: number) => {
    if ((num || num === 0) && num === currentPosition.Local_Id) {
      setCurrentPosition({ ...positionObj });
      setShowFillPosition(false);
      setShowAmountAvailable(false);
      setShowPositionPrice(false);
      setEdit(positionObj.Local_Id);
      return;
    }
    let posUpdate: PositionsInterface[] = [...position];
    for (let i = 0; i < posUpdate.length; i++) {
      if (posUpdate[i].Local_Id === num) {
        setEdit(posUpdate[i].Local_Id);
        if (posUpdate[i].Price_Override === "-1") {
          setCurrentPosition({ ...posUpdate[i], Price_Override: "0" });
          setShowPositionPrice(false);
        } else {
          setCurrentPosition({ ...posUpdate[i] });
          setShowPositionPrice(true);
        }
        if (posUpdate[i].Amount_Available !== "1") {
          setShowAmountAvailable(true);
        } else setShowAmountAvailable(false);
        break;
      }
    }
  };

  const updatePositionId = (posArr: PositionsInterface[]) => {
    for (let i = 0; i < posArr.length; i++) {
      posArr[i] = { ...posArr[i], Local_Id: i + 2 };
    }
    return posArr;
  };

  const submitFunction = () => {
    let submission = { ...currentPosition };
    if (!showPositionPrice) {
      submission.Price_Override = "-1";
    }
    if (!showAmountAvailable) {
      submission.Amount_Available = "1";
    }
    savePosition(submission);
  };

  return (
    <div className="forms__group--border">
      {/* <div className="forms__title forms__title--margin">Position:</div> */}
      {position.length > 0 && (
        <PositionsList
          list={position}
          func={getPositionToEdit}
          edit={edit}
          deleteFunc={deletePosition}
        />
      )}

      {/* <div className="forms__group--fit">
        {props.fillPosition && (
          <div className="forms__explanation ">
            <FormCheckBox
              label="Fill Position"
              value={showFillPosition}
              disabled={false}
              func={setShowFillPosition}
            />
            <div className="forms__explanation--text">
              Fill Position allows you to manually add a person's name and email
              to a position
            </div>
          </div>
        )}
      </div> */}
      {edit !== -1 && <div className="forms__title">Editing Position</div>}
      <div className="forms__group">
        <FormField
          label="Position Name"
          placeholder="Ex: Presenter, Drummer, VIP, General Admission, Gold Tier Supporter"
          name="Position_Name"
          value={currentPosition.Position_Name}
          nameAndValueFunc={updatePosition}
          type="text"
          disabled={false}
          required={false}
        />

        <FormField
          label="Description"
          placeholder="Short description if needed"
          name="Description"
          value={currentPosition.Description}
          nameAndValueFunc={updatePosition}
          type="text"
          disabled={false}
          required={false}
          textArea={true}
        />
        {/* {showFillPosition && (
        <div className="forms__group">
          <FormField
            label="Filled By Email"
            placeholder="Only add an email if this position is booked"
            name="Filled_By_Email"
            value={currentPosition.Filled_By_Email}
            nameAndValueFunc={updatePosition}
            type="text"
            disabled={false}
            required={false}
          />

          <FormField
            label="Filled By Name"
            placeholder="Only add a name if this position is booked"
            name="Filled_By_Name"
            value={currentPosition.Filled_By_Name}
            nameAndValueFunc={updatePosition}
            type="text"
            disabled={false}
            required={false}
          />
        </div>
      )} */}
      </div>
      <div className="forms__group">
        <div className="forms__explanation ">
          <FormCheckBox
            label="Many Available"
            value={showAmountAvailable}
            disabled={false}
            func={setShowAmountAvailable}
          />
          <div className="forms__explanation--text">
            Many Available allows you to enter a number of position
            availabilities
          </div>
          {showAmountAvailable && (
            // <div className="forms__group--limit-width">
            <FormField
              label="Number available at this position"
              type="number"
              placeholder={"1"}
              disabled={false}
              required={false}
              min={0}
              name="Amount_Available"
              value={currentPosition.Amount_Available}
              nameAndValueFunc={updatePosition}
            />
            // </div>
          )}
        </div>

        <div className="forms__explanation">
          <FormCheckBox
            label="Position Price Override"
            value={showPositionPrice}
            disabled={false}
            func={setShowPositionPrice}
          />
          <div className="forms__explanation--text">
            Price override allows you to charge a different price (other than
            the offer's price) for this position
          </div>
          {showPositionPrice && (
            <FormField
              label="Price Override (In dollars)"
              type="number"
              disabled={false}
              required={false}
              min={0}
              name="Price_Override"
              value={currentPosition.Price_Override}
              nameAndValueFunc={updatePosition}
            />
          )}
        </div>
      </div>
      <FormInnerButton
        title={edit !== -1 ? "Commit Edit" : "Add Position"}
        func={submitFunction}
        disabled={currentPosition.Position_Name ? false : true}
      />
    </div>
  );
};

export default PositionsComp;
