import { createSlice } from "@reduxjs/toolkit";

const LocationSlice = createSlice({
  name: "location",
  initialState: {
    lat: 0,
    lng: 0,
  },
  reducers: {
    locationAssign(state, action) {
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    },
    resetLocation(state) {
      state.lat = 0;
      state.lng = 0;
    },
  },
});

export const { locationAssign, resetLocation } = LocationSlice.actions;
export default LocationSlice.reducer;
