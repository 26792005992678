import { useCallback, useState } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import Compressor from "compressorjs";

interface ImageUploadProps {
  imageFileName: string;
  setImageFile: Function;
  setImagePreview: Function;
  label: string;
  required?: boolean;
  message?: string;
}

const ImageUpload = (props: ImageUploadProps) => {
  const [error, setError] = useState("");
  const onDrop = useCallback(
    (files: File[], rejection: FileRejection[], event: DropEvent) => {
      setError("");
      if (rejection.length) {
        setError(rejection[0]?.errors[0]?.message);
        return;
      }
      files.forEach((file) => {
        // Check file type
        if (!file.type.startsWith("image/")) {
          setError("File type must be .jpg, .jpeg or .png");
          return;
        }
        new Compressor(file, {
          quality: 0.8,
          width: 600,
          height: 750,
          resize: "cover",
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            props.setImageFile(result);
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onabort = () => setError("File read error");
            reader.onerror = () => setError("File read error");
            reader.onload = () => {
              const preview = reader.result;
              props.setImagePreview(preview);
            };
          },
        });
      });
    },
    []
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    onDrop,
    maxSize: 5000000,
    maxFiles: 1,
  });

  return (
    <div className="forms__field">
      <label htmlFor={props.label}>
        {props.label + (props.required ? " *" : "")}
      </label>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="image-upload">
          {props.imageFileName ? (
            props.imageFileName
          ) : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag an image here, or click to select an image</p>
          )}
        </div>
        <div className="image-upload__subtext">
          {props.message
            ? props.message
            : "Images will be resized/cropped to a 4:5 ratio. ex: 600:750"}
        </div>
      </div>
      {error && (
        <div className="forms__field--error forms__field--error--center">
          {error}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
