import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { creatorSearch } from "../../API/offersApi";
import useDebounce from "../../CustomHooks/useDebounce";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import useElementInView from "../../CustomHooks/useElementInView";
import useWindowSize from "../../CustomHooks/useWindowSize";
import logoplaceholder from "../../Assets/logoplaceholder.jpeg";

interface SearchCreatorInterface {
  searchString: string;
}

interface UserSearchInterface {
  Active: boolean;
  Business_Name: string;
  Logo_Url: string;
  Slug: string;
  Url: string;
  Description: string;
}

const loadNum = 100;

const SearchCreator = (props: SearchCreatorInterface) => {
  const val = useDebounce(props.searchString, 1000);
  const [userList, setUserList] = useState<UserSearchInterface[]>([]);
  const [noMoreUsers, setNoMoreUsers] = useState(false);

  const navigate = useNavigate();
  const { width } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);

  const loadMore = useElementInView(ref);

  useEffect(() => {
    doCreatorSearch(val, true);
  }, [val]);

  useEffect(() => {
    if (loadMore) {
      doCreatorSearch(val);
    }
  }, [loadMore]);

  const findPage = (paginationAmount: number) => {
    const currentOffersAmount = userList ? userList.length : 0;
    if (currentOffersAmount === 0) return 1;
    if (currentOffersAmount % paginationAmount) {
      return -1;
    }
    const pageNum = currentOffersAmount / paginationAmount;
    return pageNum + 1;
  };

  const doCreatorSearch = async (val: string, override = false) => {
    // Find page for pagination
    let pageNum = findPage(loadNum);
    if (pageNum === -1 && !override) {
      setNoMoreUsers(true);
      return;
    }
    if (override) {
      setNoMoreUsers(false);
      pageNum = 1;
    }
    const res = await creatorSearch({
      search: val,
      pageNo: pageNum,
      loadAmount: loadNum,
    });
    if (res?.users?.length > 0) {
      if (res.users.length < loadNum) setNoMoreUsers(true);
      if (override) setUserList(res.users);
      else setUserList([...userList, ...res.users]);
    } else {
      setUserList([]);
      setNoMoreUsers(true);
    }
  };

  let list;
  if (userList) {
    if (userList?.length > 0) {
      list = userList.map((el, i) => {
        return (
          <div
            className={`search-creator__item--container ${
              i === userList.length - 1 && width > 768 ? "mb-3" : ""
            }`}
            key={i}
            onClick={() => {
              navigate(`../offers/${el.Slug}`);
            }}
          >
            <div className="title m-0">{el.Business_Name}</div>
            <div className="search-creator__item">
              <div className="search-creator__logo--container">
                <img
                  className="search-creator__logo"
                  src={el.Logo_Url ? el.Logo_Url : logoplaceholder}
                  alt="Logo"
                />
              </div>
              <div className="font-size-16">{el.Description}</div>
            </div>
          </div>
        );
      });
    }
  }

  return (
    <div className="search-creator__container">
      <div className="title mb-3 mt-0">Results</div>
      {userList && userList?.length > 0 ? (
        <>
          <div>{list}</div>
          <div ref={ref} className="offer-list__arrow">
            {noMoreUsers ? (
              "End of offers"
            ) : (
              <FontAwesomeIcon
                icon={icon({ name: "arrow-down", style: "solid" })}
              />
            )}
          </div>
        </>
      ) : (
        <div className="title color-highlight">No active offers found</div>
      )}
    </div>
  );
};

export default SearchCreator;
