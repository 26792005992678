import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../Redux/Hooks";

const DashboardPage = () => {
  const { active, accountType } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!active || accountType === 1) navigate("/");
  }, [active]);
  return (
    <div className="">
      <div>Dashboard</div>
    </div>
  );
};

export default DashboardPage;
