import {appDelete, appGet, appPatch, appPost, appPut} from "./appFetch"

export const createPolicy = async (params) => {
    return await appPost("/policy/create", params)
}

export const editPolicy = async (params) => {
    return await appPatch("/policy/edit", params)
}

export const getPolicies = async (params) => {
    return await appGet("/policy/policies", params)
}

export const removePolicy = async (params) => {
    return await appDelete("/policy/remove", params)
}

export const activatePolicy = async (params) => {
    return await appPut("/policy/activate", params)
}