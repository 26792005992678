import { useEffect, useState } from "react";
import Modal from "../../Components/Modal/Modal";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { PositionsInterface } from "../../Interfaces/OfferInterface";

interface OfferPositionsProps {
  positions: PositionsInterface[];
}

const OfferPositions = (props: OfferPositionsProps) => {
  const [openModal, setOpenModal] = useState<PositionsInterface | null>(null);
  const [listHasPrice, setListHasPrice] = useState(false);
  const onCloseModal = () => setOpenModal(null);

  useEffect(() => {
    // This is coming as a number from the server
    let filt = [...props.positions].filter((el) => el.Price_Override !== "-1");
    if (filt.length) setListHasPrice(true);
    else setListHasPrice(false);
  }, [JSON.stringify(props.positions)]);

  let classNormal = "",
    classSmall = "";
  if (listHasPrice) {
    classNormal = "offer-info__list-header--set-width";
    classSmall = "offer-info__list-header--set-width--small";
  }

  const positionsList = props.positions.map((el, i) => {
    return (
      <div
        className={
          i % 2 === 0
            ? "offer-info__item--click offer-info__item offer-info__item--dark"
            : "offer-info__item--click offer-info__item offer-info__item"
        }
        key={i}
        onClick={() => setOpenModal(el)}
      >
        <div className={classNormal}>
          <span className="positions-list--value ">{el.Position_Name}</span>
        </div>
        <div className={classSmall}>
          <span className="positions-list--value">
            {parseInt(el.Amount_Available) - parseInt(el.Amount_Filled)}
          </span>
        </div>
        {listHasPrice && (
          <div className={classSmall}>
            <span className="positions-list--value">
              {el.Price_Override && el.Price_Override === "0"
                ? "FREE"
                : el.Price_Override &&
                  el.Price_Override !== "-1" &&
                  parseInt(el.Price_Override) !== -1
                ? `$${el.Price_Override}`
                : "Offer Price"}
            </span>
          </div>
        )}
      </div>
    );
  });

  const dateHeader = (
    <div className="offer-info__list-header--two-rows" key={-1}>
      <div className="offer-info__list-header">
        <div className={classNormal}>Position</div>
        <div className={classSmall}>Available</div>
        {listHasPrice && <div className={classSmall}>Price</div>}
      </div>
      <div className="offer-info__list-header--subtitle">
        <em>select below to view details</em>
      </div>
    </div>
  );

  positionsList.unshift(dateHeader);

  return (
    <>
      <Modal
        show={openModal === null ? false : true}
        setShow={onCloseModal}
        title={
          openModal && openModal.Position_Name ? openModal.Position_Name : ""
        }
      >
        <div className="offer-info__body">
          <ReactMarkdown className="mark-down" linkTarget="_blank">
            {openModal && openModal.Description
              ? openModal.Description
              : "No description provided"}
          </ReactMarkdown>
        </div>
      </Modal>

      <div className="mt-1">{positionsList}</div>
    </>
  );
};

export default OfferPositions;
