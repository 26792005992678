import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignUpComp from "../Components/Authentication/SignUpComp";
import { useAppSelector } from "../Redux/Hooks";

const SignUpPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.user.email);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("../");
    }
  }, [isLoggedIn]);
  return (
    <div className="signup-page">
      <h1 className="signup-page__title">Thread</h1>
      <SignUpComp />
    </div>
  );
};

export default SignUpPage;
