import { useState } from "react";
import {
  createAddress,
  editAddress,
  removeAddress,
} from "../../API/addressApi";
import {
  countriesArr,
  findCountry,
  parseAutoComplete,
  statesArr,
} from "../../Helpers/addressHelpers";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { updateUserAddresses } from "../../Redux/UserReducer";
import FormCheckBox from "../Forms/FormCheckBox";
import FormField from "../Forms/FormField";
import FormInnerButton from "../Forms/FormInnerButton";
import FormSelect from "../Forms/FormSelect";
import Autocomplete from "react-google-autocomplete";

interface AddressFormProps {
  newAddress: boolean;
  back: Function;
}

interface AddressErrors {
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  county: string;
  state: string;
  country: string;
  zip: string;
  primary: string;
}

const AddressForm = (props: AddressFormProps) => {
  const emptyAddress = {
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    county: "",
    state: "",
    country: "",
    zip: "",
    primary: "",
  };

  const addressInfo = useAppSelector((state) => state.address);
  const [addressLineOne, setAddressLineOne] = useState(
    addressInfo.addressLineOne || ""
  );
  const [addressLineTwo, setAddressLineTwo] = useState(
    addressInfo.addressLineTwo || ""
  );
  const [city, setCity] = useState(addressInfo.city || "");
  const [county, setCounty] = useState(addressInfo.county || "");
  const [state, setState] = useState(addressInfo.state || "AK");
  const [country, setCountry] = useState(
    addressInfo.country ? findCountry(addressInfo.country) : "United States"
  );
  const [zip, setZip] = useState(addressInfo.zip || "");

  const [primary, setPrimary] = useState(addressInfo.primary);
  const [formErrors, setFormErrors] = useState<AddressErrors>({
    ...emptyAddress,
  });
  const [autoComplete, setAutoComplete] = useState(true);

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const [placesId, setPlacesId] = useState("");

  const [googleError, setGoogleError] = useState("");

  const [errors, setErrors] = useState("");

  const dispatch = useAppDispatch();

  const submitFunction = async () => {
    let res;
    try {
      if (props.newAddress) {
        let stop = checkAddress(true);
        if (stop) return;
        res = await createAddress({
          addressLineOne,
          addressLineTwo,
          city,
          county,
          state,
          country,
          zip,
          primary,
          lat,
          lng,
          placesId,
        });
      } else {
        res = await editAddress({
          addressLineOne,
          addressLineTwo,
          city,
          county,
          state,
          country,
          zip,
          primary,
          lat,
          lng,
          placesId,
          id: addressInfo.addressId,
        });
      }
      dispatch(updateUserAddresses(res));
      if (props.newAddress) {
        props.back(true);
      } else {
        props.back(true);
      }
    } catch (err) {
      setErrors("An error has occured. Please try again later");
    }
  };

  const deleteAddress = async () => {
    try {
      const res = await removeAddress({ id: addressInfo.addressId });
      dispatch(updateUserAddresses(res));
      props.back(true);
    } catch (error) {
      setErrors("An error has occured. Please try again later");
    }
  };

  const checkAddress = (newAddress: boolean) => {
    let errorUpdate: AddressErrors = {
      ...emptyAddress,
    };
    if (!addressLineOne) errorUpdate.addressLineOne = "Required field";
    if (!city) errorUpdate.city = "Required field";
    if (!state) errorUpdate.state = "Required field";
    if (!country) errorUpdate.country = "Required field";
    if (!zip) errorUpdate.zip = "Required field";

    setFormErrors(errorUpdate);

    let prop: string;
    for (prop in errorUpdate) {
      if (errorUpdate[prop as keyof AddressErrors]) return true;
    }
    setFormErrors(emptyAddress);
  };

  if (autoComplete) {
    return (
      <div className="address__form">
        {/* <div className="forms__title">Address Info:</div> */}
        {addressInfo.addressLineOne && (
          <div className="address__success mt-1">
            Replacing:{" "}
            {`${addressLineOne}${
              addressLineTwo ? " " + addressLineTwo : ""
            }, ${city} ${state} ${zip}`}
          </div>
        )}
        <div className="forms__group">
          <div className="forms__field">
            <Autocomplete
              options={{
                types: [],

                // componentRestrictions: { country: "us" },
              }}
              placeholder="Address Search"
              apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE}
              onPlaceSelected={(place) => {
                if (!place?.geometry) {
                  setGoogleError(
                    "Please choose a location close to you. We use the geometry generated by known addresses in order to let users search offers that are nearby. You can change the address lines in the next step."
                  );
                } else {
                  setGoogleError("");

                  const addressObj = parseAutoComplete(
                    place.address_components
                  );
                  setAddressLineOne(addressObj.addressLineOne);
                  setCity(addressObj.city);
                  setCounty(addressObj.country);
                  setState(addressObj.state ? addressObj.state : "TX");
                  setCounty(addressObj.county);
                  setZip(addressObj.postal_code);
                  setLat(place.geometry.location.lat());
                  setLng(place.geometry.location.lng());
                  setAutoComplete(false);
                  setPlacesId(place.place_id);
                }
              }}
            />
          </div>
        </div>
        {googleError && (
          <div className="forms__field--error forms__field--error--center">
            {googleError}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <>
        <div
          className="address__comp--link"
          onClick={() => setAutoComplete(true)}
        >
          Back to autocomplete
        </div>
        <div className="address__comp--form">
          <div className="forms__group">
            <FormField
              label="Address Line One"
              type="text"
              placeholder="123 Sesame Street"
              value={addressLineOne}
              func={setAddressLineOne}
              disabled={false}
              required={true}
              error={formErrors.addressLineOne}
            />
            <FormField
              label="Address Line Two"
              type="text"
              placeholder="Appt: 3B"
              value={addressLineTwo}
              func={setAddressLineTwo}
              disabled={false}
              required={false}
              // error={formErrors.addressLineTwo}
            />
            <FormField
              label="City"
              type="text"
              placeholder="Las Vegas"
              value={city}
              func={setCity}
              disabled={false}
              required={true}
              error={formErrors.city}
            />
            <FormField
              label="County"
              type="text"
              placeholder="Travis"
              value={county}
              func={setCounty}
              disabled={false}
              required={false}
              // error={formErrors.county}
            />
            <FormSelect
              label="State"
              value={state}
              options={statesArr}
              func={setState}
              disabled={false}
              required={true}
              error={formErrors.state}
            />

            <FormSelect
              label="Country"
              value={country}
              options={countriesArr.map((el) => el.country)}
              func={setCountry}
              disabled={false}
              required={true}
              error={formErrors.country}
            />

            <FormField
              label="Zip"
              type="text"
              placeholder="90210"
              value={zip}
              func={setZip}
              disabled={false}
              required={true}
              error={formErrors.zip}
            />
            <FormCheckBox
              label="Primary Address"
              value={primary}
              func={setPrimary}
            />
          </div>
          <div className="forms__button-container">
            <FormInnerButton
              func={() => deleteAddress()}
              title="Remove Address"
            />
            <FormInnerButton
              func={() => submitFunction()}
              title="Submit"
              primary
            />
          </div>
          {errors && <div className="signup__error">{errors}</div>}
        </div>
      </>
    );
  }
};

export default AddressForm;
