import {appDelete, appGet, appPatch, appPost, appPut} from "./appFetch"

export const createAddress = async (params) => {
    return await appPost("/address/create", params)
}

export const editAddress = async (params) => {
    return await appPatch("/address/edit", params)
}

export const getAddresses = async (params) => {
    return await appGet("/address/addresses", params)
}

export const removeAddress = async (params) => {
    return await appDelete("/address/remove", params)
}

export const activateAddress = async (params) => {
    return await appPut("/address/activate", params)
}

export const getAddressesById = async (params) => {
    return await appGet("/address/getaddressesbyid", params)
}