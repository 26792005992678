import Modal from "../Modal/Modal";
import OfferLeaderForm from "./OfferLeaderForm";
import OfferLeaderList from "./OfferLeaderList";

interface OfferLeaderEditProps {
  newLeader: boolean;
  name: string;
  onBackClick: Function;
  showModal: boolean;
  onCloseModal: Function;
  setNewLeader: React.Dispatch<React.SetStateAction<boolean>>;
  success: string;
}

const OfferLeaderEdit = (props: OfferLeaderEditProps) => {
  const {
    newLeader,
    name,
    onBackClick,
    showModal,
    onCloseModal,
    setNewLeader,
    success,
  } = props;
  return (
    <Modal
      title={`${
        newLeader ? "New Leader Creation" : name ? "Edit Leader" : "Leader Info"
      }`}
      backButton={newLeader || name ? true : false}
      onBackButtonClick={onBackClick}
      show={showModal}
      setShow={onCloseModal}
    >
      {name || newLeader ? (
        <>
          <OfferLeaderForm newLeader={newLeader} back={onBackClick} />
          <div className="text-center font-size-14 pt-1">
            Example: If the offer is a class, the offer leader would be the
            teacher
          </div>
        </>
      ) : (
        <OfferLeaderList
          newLeader={newLeader}
          setNewLeader={setNewLeader}
          success={success}
        />
      )}
    </Modal>
  );
};

export default OfferLeaderEdit;
