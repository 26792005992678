import OfferList from "../../UserComponents/OfferDisplay/OfferList";

interface SearchOffersInterface {
  latLng?: { lat: number; lng: number };
  distance?: number;
  searchTerm?: string;
}

const SearchOffers = (props: SearchOffersInterface) => {
  if (
    props.distance &&
    props.distance > -1 &&
    !props.latLng?.lat &&
    !props.latLng?.lng
  ) {
    return (
      <div>
        <div className="title text-center">Waiting for location</div>
        <div className="font-size-14 text-center">
          We are awaiting your location from your browser. If you would prefer,
          you can enter a custom location above
        </div>
      </div>
    );
  } else if (!props.distance && props.distance !== 0) {
    return (
      <div>
        <OfferList
          search="searchoffersstring"
          searchString={props.searchTerm}
        />
      </div>
    );
  } else
    return (
      <div>
        <OfferList
          search="searchoffers"
          latLng={props.latLng}
          distance={props.distance}
        />
      </div>
    );
};

export default SearchOffers;
