import { appGet, appPost } from "./appFetch"

// Payments are not active in the demo app

export const onboardStripe = async (params) => {
    return await appGet("/stripe/NewAccountOnboarding", params)
}

export const getStripeAccount = async (params) => {
    return await appGet("/stripe/account", params)
}

export const createPaymentIntent = async (params) => {
    return await appPost("/stripe/createpaymentintent", params)
}

// export const confirmPayment = async (params) => {
//     return await appPost("/stripe/confirmpayment", params)
// }

export const checkPayment = async (params) => {
    return await appPost("/stripe/checkpayment", params)
}

export const refundUser = async (params) => {
    return await appPost("/stripe/refunduser", params)
}
