import OfferList from "../UserComponents/OfferDisplay/OfferList";

const OffersByOffererIdPage = () => {
  return (
    <div>
      <OfferList search="OffersByOffererIdPage" />
    </div>
  );
};

export default OffersByOffererIdPage;
