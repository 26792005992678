import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { clickedLeader, resetLeader } from "../../Redux/LeaderReducer";
import ListContainer from "../Common/ListContainer";

interface OfferLeaderListProps {
  newLeader: boolean;
  setNewLeader: Function;
  success: string;
}

const OfferLeaderList = (props: OfferLeaderListProps) => {
  const { leaders } = useAppSelector((state) => state.user);
  const [curId, setCurId] = useState(-1);

  const dispatch = useAppDispatch();

  let leads = leaders.map((el, i) => (
    <div
      className={
        i % 2 === 0
          ? `list__item list__item--dark address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
          : `list__item address-list__item ${
              curId === i ? "list__item--highlight" : ""
            }`
      }
      onClick={() => {
        if (curId === i) {
          setCurId(-1);
          dispatch(resetLeader());
          props.setNewLeader(false);
        } else {
          setCurId(i);
          dispatch(clickedLeader(el));
          props.setNewLeader(false);
        }
      }}
      key={i}
    >
      {el.Name}
    </div>
  ));

  leads.unshift(
    <div
      key={-1}
      className="address-list__new address-list__item"
      onClick={() => props.setNewLeader(true)}
    >
      Add New Leader
    </div>
  );

  let noLeader = false;
  if (!leaders[0]?.Name) {
    noLeader = true;
  }

  if (noLeader) {
    delete leads[1];
  }

  return (
    <>
      <div className="forms__title--margin" />
      <div className="address__success">{props.success}</div>
      <ListContainer>{leads}</ListContainer>
    </>
  );
};

export default OfferLeaderList;
