// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../CustomHooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks";
import LinkContainer from "./LinkContainer";
import NavButton from "./NavButton";
import { logoutUserThunk } from "../Redux/UserFuncs";

const Header = () => {
  const isLoggedIn = useAppSelector((state) => state.user.email);
  // For larger menu integration when needed
  // const [currentList, setCurrentList] = useState<LinkInfo[]>([{}]);
  const { email, accountType } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  // AccountType
  // 1 User
  // 2 Offerer

  return (
    <>
      <div className={width > 768 ? "header" : "header header__center"}>
        <div
          className={"header__logo"}
          onClick={() => navigate("/")}
        >{`Thread`}</div>
        {width > 768 && (
          <nav className={`header__link-container`}>
            {isLoggedIn && accountType === 2 && (
              <LinkContainer title={"Create"} linkArr={Create} />
            )}
            {isLoggedIn && accountType === 2 && (
              <LinkContainer title={"Manage"} linkArr={Manage} />
            )}
            {isLoggedIn && accountType === 2 && (
              <LinkContainer title={"Account"} linkArr={account} />
            )}
            {accountType !== 2 && (
              <LinkContainer title={"Search"} linkArr={search} />
            )}
            {isLoggedIn && accountType === 1 && (
              <LinkContainer title={"Enrolled"} linkArr={enrolled} />
            )}
            {isLoggedIn && accountType === 1 && (
              <LinkContainer title={"Account"} linkArr={userAccount} />
            )}
            {!isLoggedIn && (
              <div className="mr-2">
                <NavButton url="/signup" text="Sign up for free" />
              </div>
            )}
            {!isLoggedIn && <NavButton invert url="/login" text="login" />}
            {isLoggedIn && <NavButton url="logout" text="logout" />}
          </nav>
        )}
      </div>

      {width <= 768 && (
        // <div className="header__link-button">
        //   <FontAwesomeIcon icon={icon({ name: "bars", style: "solid" })} />
        // </div>
        <div className="header__sticky">
          {/* {User Accounts} */}

          {accountType !== 2 && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("/");
              }}
            >
              Search
            </div>
          )}
          {email && accountType === 1 && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("enrolled");
              }}
            >
              Enrolled
            </div>
          )}
          {email && accountType === 1 && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("userAccount");
              }}
            >
              Account
            </div>
          )}

          {/* {Offerer Accounts} */}

          {email && accountType === 2 && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("createoffer");
              }}
            >
              Create
            </div>
          )}

          {email && accountType === 2 && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("manageoffers");
              }}
            >
              Manage
            </div>
          )}

          {email && accountType === 2 && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("account");
              }}
            >
              Account
            </div>
          )}

          {/* {All Accounts} */}

          {email && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                dispatch(logoutUserThunk());
                navigate("../");
              }}
            >
              Logout
            </div>
          )}
          {!email && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("login");
              }}
            >
              Login
            </div>
          )}
          {!email && (
            <div
              className="header__sticky--item"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("signup");
              }}
            >
              Signup
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Header;

export interface NavButtonProps {
  text: string;
  url: string;
  invert?: boolean;
}

interface LinkInfo {
  name?: string;
  info?: string;
  url?: string;
}

export interface LinkArr {
  linkArr: LinkInfo[];
  setList?: React.Dispatch<React.SetStateAction<LinkInfo[]>>;
  title: string;
}

const ShowMeMore = [
  {
    name: "Show Me More",
    url: "/showmemore",
    info: "Learn what we can do for you",
  },
];

const Create = [
  {
    name: "Create Offer",
    url: "/createoffer",
    info: "Create a new product offering for your customers",
  },
];

const Manage = [
  {
    name: "Manage Offers",
    url: "/manageoffers",
    info: "View your current product offerings",
  },
];

const features = [
  {
    name: "Features",
    url: "/features",
    info: "Enable and disable customer relations features like purchase tracking by email",
  },
];

const account = [
  {
    name: "Account",
    url: "/account",
    info: "Add or update your account information",
  },
];

const userAccount = [
  {
    name: "Account",
    url: "/useraccount",
    info: "Add or update your account information",
  },
];

const enrolled = [
  {
    name: "enrolled",
    url: "/enrolled",
    info: "View the offers you enrolled in",
  },
];

const search = [
  {
    name: "search",
    url: "/",
    info: "See offers near you",
  },
];

// // Larger menu integration can be done with these objects:

// const products = [
//   {
//     name: "Create Offer",
//     url: "/createoffer",
//     info: "Create a new product offering for your customers",
//   },
//   {
//     name: "My Offers",
//     url: "/myoffers",
//     info: "View your current product offerings",
//   },
// ];

// const account = [
//   {
//     name: "Features",
//     url: "/features",
//     info: "Enable and disable customer relations features like purchase tracking by email",
//   },
//   {
//     name: "Business Info",
//     url: "/businessinfo",
//     info: "Add or update your business information",
//   },
//   {
//     name: "Contact Info",
//     url: "/contactinfo",
//     info: "Add or update your contact information",
//   },
// ];
