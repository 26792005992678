import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEnrolled } from "../../API/offersApi";
import FormInnerButton from "../../Components/Forms/FormInnerButton";
import FormSimpleToggle from "../../Components/Forms/FormSimpleToggle";
import InfoDisplay from "../../Components/Forms/InfoDisplay";
import useElementInView from "../../CustomHooks/useElementInView";
import { BookingInterface } from "../../Interfaces/OfferInterface";

const loadNum = 100;

const Enrolled = () => {
  const [bookings, setBookings] = useState<BookingInterface[]>([]);
  const [noMoreBookings, setNoMoreBookings] = useState(false);

  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const loadMore = useElementInView(ref);

  useEffect(() => {
    getAllEnrolled();
  }, [loadMore]);

  const findPage = (paginationAmount: number, book: BookingInterface[]) => {
    const currentBookingAmount = book.length;
    if (currentBookingAmount === 0) return 1;
    if (currentBookingAmount % paginationAmount) {
      return -1;
    }
    const pageNum = currentBookingAmount / paginationAmount;
    return pageNum + 1;
  };

  const getAllEnrolled = async (override = false) => {
    // Find page for pagination
    const pageNum = findPage(loadNum, bookings);
    if (pageNum === -1) {
      setNoMoreBookings(true);
      return;
    }
    // Get upcoming
    const list = await getEnrolled({
      pageNo: override ? 0 : pageNum,
      loadAmount: loadNum,
    });
    if (list.bookings) {
      const updatedList = [...bookings, ...list.bookings];
      setBookings(updatedList);
      if (list.bookings.length < loadNum) {
        setNoMoreBookings(true);
      }
    } else {
      setNoMoreBookings(true);
    }
  };

  //
  const makeList = () => {
    if (bookings?.length) {
      return bookings.map((el, i) => {
        return (
          <div className="enrolled__list-item" key={i}>
            {(el.Refunded > 0 || el.Cancelled) && (
              <div className="title">
                {el.Refunded > 0 && el.Cancelled
                  ? "Cancelled & Refunded"
                  : el.Refunded > 0
                  ? "Refunded"
                  : "Cancelled"}
              </div>
            )}
            <div className="enrolled__list-top">
              <div className="manage-offers__title--container">
                <div className="manage-offers__forum-link--container manage-offers__forum-button"></div>
                <div className="manage-offers__title">{el.Offer.Name}</div>
                <div className="manage-offers__forum-link--container manage-offers__forum-button"></div>
              </div>
            </div>
            <div
              className="manage-offers__item"
              onClick={() => {
                if (el.Refunded > 0) return;
                navigate(`../forum/${el.Offer.ID}`);
              }}
            >
              <InfoDisplay
                label={"Position: "}
                info={`${el.Position.Position_Name}`}
              />

              <InfoDisplay
                label={"Start Date: "}
                info={format(
                  parseISO(el.Offer.Start_Date!),
                  "EEEE, LLLL do yyyy hh:mm a"
                )}
              />
              <InfoDisplay
                label={"End Date: "}
                info={format(
                  parseISO(el.Offer.End_Date!),
                  "EEEE, LLLL do yyyy hh:mm a"
                )}
              />
              <InfoDisplay
                label={"Booking Date: "}
                info={format(
                  parseISO(el.CreatedAt),
                  "EEEE, LLLL do yyyy hh:mm a"
                )}
              />
              <InfoDisplay label={"Booking ID: "} info={el.ID} />
              <InfoDisplay label={"Price: "} info={`$${el.Price_Paid}`} />
            </div>
          </div>
        );
      });
    } else return [];
  };

  return (
    <div>
      <div className="title">Booked</div>
      {makeList()}
      <div ref={ref} className="offer-list__arrow">
        {noMoreBookings ? (
          "End of bookings"
        ) : (
          <FontAwesomeIcon
            icon={icon({ name: "arrow-down", style: "solid" })}
          />
        )}
      </div>
    </div>
  );
};

export default Enrolled;

// ref
