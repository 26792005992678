import { createSlice } from "@reduxjs/toolkit";

const PolicySlice = createSlice({
  name: "Policy",
  initialState: {
    name: "",
    description: "",
    image: "",
    active: false,
    id: -1,
  },
  reducers: {
    clickedPolicy(state, action) {
      state.name = action.payload.Name;
      state.description = action.payload.Description;
      state.active = action.payload.Active;
      state.id = action.payload.ID;
    },
    resetPolicy(state) {
      state.name = "";
      state.description = "";
      state.active = false;
      state.id = -1;
    },
  },
});

export const { clickedPolicy, resetPolicy } = PolicySlice.actions;
export default PolicySlice.reducer;
