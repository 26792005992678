import {appGet, appPatch, appPost, appFormData} from "./appFetch"

export const createNewUserApi = async (params) => {
    return await appPost("/users/signup", params)
}

export const loginUserApi = async (params) => {
    return await appPost("/users/login", params)
}

export const validateUserApi = async (params) => {
    return await appGet("/users/validate", params)
}

export const logoutUserApi = async (params) => {
    return await appGet("/users/logout", params)
}

export const updateUserApi = async (params) => {
    return await appPatch("/users/update", params)
}

export const updateLogoApi = async (params) => {
    return await appFormData("/users/updatelogo", params)
}

export const checkForStripeApi = async () => {
    return await appGet("/users/checkforstripe")
}

