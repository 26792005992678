import { useEffect, useState } from "react";
import { getPromoCodes } from "../../API/promoCodeApi";
import { PromoInterface } from "../../Interfaces/PromoInterfaces";
import PromoComp from "./PromoComp";
import PromoList from "./PromoList";

const PromoContainer = () => {
  const [form, setForm] = useState(false);
  const [edit, setEdit] = useState<PromoInterface>();
  const [promoCodes, setPromoCodes] = useState<PromoInterface[]>([]);
  const [promoCodesOld, setPromoCodesOld] = useState<PromoInterface[]>([]);

  useEffect(() => {
    getMyPromoCodes();
  }, []);

  const getMyPromoCodes = async () => {
    const res = await getPromoCodes();
    setPromoCodes(res.promoCodes);
    setPromoCodesOld(res.promoCodesOld);
  };

  const getEdit = (el: PromoInterface) => {
    setEdit(el);
    setForm(true);
  };

  const newPromo = () => {
    setEdit(undefined);
    setForm(true);
  };

  return (
    <div>
      <PromoList
        form={() => newPromo()}
        edit={(el: PromoInterface) => getEdit(el)}
        promoCodes={promoCodes}
        promoCodesOld={promoCodesOld}
      />
      {form && (
        <PromoComp
          edit={edit}
          cb={() => {
            getMyPromoCodes();
            setEdit(undefined);
          }}
        />
      )}
    </div>
  );
};

export default PromoContainer;
